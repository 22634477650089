/*
 * This files needs to be loaded at first because it generates the angular.js module
 * which is required for all the other components in this module
 */
angular.module('mx.auth', ['angular-jwt']);

angular.module('mx.auth').provider('mxAuthLocation', ['$injector', function ($injector) {

    var isNgRoute = $injector.has('$route'),
        isUiRouter = $injector.has('$state');

    if (isUiRouter) {
        var $urlRouterProvider = $injector.get('$urlRouterProvider');
        $urlRouterProvider.deferIntercept();
    }

    this.$get = ['$location', '$rootScope', '$injector', function ($location, $rootScope, $injector) {
        var skipNextReload = false;
        if (isUiRouter) {
            // Setup UI router, so we can use skipReload method.
            var $urlRouter = $injector.get('$urlRouter');
            $rootScope.$on('$locationChangeSuccess', function (e) {
                if (skipNextReload) {
                    skipNextReload = false;
                    e.preventDefault();
                }
            });

            $urlRouter.listen();
        }

        $location.skipReload = function () {
            if (isNgRoute) {
                var $route = $injector.get('$route'),
                    lastRoute = $route.current;

                var unRoute = $rootScope.$on('$locationChangeSuccess', function () {
                    // This should prevent to load the same controller twice. If however the controller has been changed
                    // we need to load the new controller. So we only revert the route if the controller is the same.
                    if (lastRoute.controller === $route.current.controller) {
                        $route.current = lastRoute;
                    }

                    unRoute();
                });
            } else if (isUiRouter) {
                // When this is true, we cancel the location change event, the UI router will not be notified and
                // does not reload its current state.
                skipNextReload = true;
            } else {
                throw new Error('Only Angular-UI-Router and Angular-Default-Router supported.');
            }

            return $location;
        };

        return $location;
    }];

}]);

angular.module('mx.auth').service('mxAuthLocationTracker', ['$injector', '$rootScope', '$window', function ($injector, $rootScope, $window) {
    var self = this,
        trackedLocation = $window.location.href;

    self.getTargetLocation = function () {
        if ($injector.has('$route')) {
            return $window.location.href;
        } else {
            return trackedLocation;
        }
    };

    if ($injector.has('$transitions')) {
        // UI-Router 1.X
        var $transitions = $injector.get('$transitions');
        var $state = $injector.get('$state');
        $transitions.onStart({}, function (trans) {
            trackedLocation = $state.href(trans.to(), trans.params(), {absolute: true});
        });
    } else if ($injector.has('$state')) {
        // UI-Router 0.2.X
        var $state = $injector.get('$state');
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
            trackedLocation = $state.href(toState, toParams, {absolute: true});
        });
    }
}]);
