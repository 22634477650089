'use strict';

angular.module('m42AccessControlServiceFrontendApp').service('M42Helpers', ['$window', '$location', 'Matrix42.Utils.Environment', '$m42AcsHost', function($window, $location, $m42Environment, $m42AcsHost) {
    var self = this;

    self.getLocationObject = function() {
        return $window.location;
    };

    self.parseQueryString = function(queryString, decode) {
        var params = {};

        var index = queryString.indexOf('?');

        if (index >= 0) {
            var queries = queryString.substring(index + 1).split('&');

            for (var i = 0; i < queries.length; i++) {
                var temp = queries[i].split('=');
                if (decode) {
                    params[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
                } else {
                    params[temp[0]] = temp[1];
                }
            }
        }

        return params;
    };

    self.getOrigin = function() {
        return $m42Environment.getOrigin();
    };

    self.getReturnToUrl = function() {

      var returnToLocation = $location.search().ReturnUrl;
      if (returnToLocation === null || returnToLocation === undefined ) {
        returnToLocation = self.parseQueryString($window.location.search).ReturnUrl;
      }

      if (returnToLocation && returnToLocation !== undefined) {

        // we need to decode the returnTo url
        returnToLocation = decodeURIComponent(returnToLocation);

        // if the returnToLocation not an absolute uri we need to
        // add the origin at the start of the uri
        if (!returnToLocation.startsWith('http://') && !returnToLocation.startsWith('https://')) {

          // It's very special because when it is a relative URI we assume that this is an URI
          // we got from our API host.
          returnToLocation = $m42AcsHost + '/' + returnToLocation;
        }
      }

      return returnToLocation;
    };

}]);
