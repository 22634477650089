'use strict';

/**
 * @ngdoc service
 * @name m42AccessControlServiceFrontendApp.$m42TemplateCachingStrategy
 * @description
 * # $m42TemplateCachingStrategy
 * Service in the m42AccessControlServiceFrontendApp.
 */
angular.module('m42AccessControlServiceFrontendApp')
  .service('$m42TemplateCachingStrategy', ['$globalConfiguration', function($globalConfiguration) {
    var self = this;

    self.processRequest = function(requestConfig) {
      // Generate the version
      var version = $globalConfiguration.deploymentUUID;

      // Patch the uri
      if (requestConfig.url.indexOf('?') === -1) {
        requestConfig.url += '?v=' + version;
      } else {
        requestConfig.url += '&v=' + version;
      }

      return requestConfig;
    };
  }]);
