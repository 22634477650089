'use strict';

angular.module('m42DevMessage', []);

angular.module('m42DevMessage').service('$m42DevMessageService', ['$window', function ($window) {
    var self = this;

    self.writeIntegrationMessage = function () {
        console.log('\n\n█───█─████─███─████─███─██─██─█────████   Want to build Workspace Management solutions like this one?\n██─██─█──█──█──█──█──█───███──█──█─█──█   Join us: http://join.matrix42.com\n█─█─█─████──█──████──█────█───████───██\n█───█─█──█──█──█─█───█───███─────█─██     Smarter Workspace, Better Life\n█───█─█──█──█──█─█──███─██─██────█─████\n\n\n');
    };
}]);
