'use strict';

/**
 * @ngdoc overview
 * @name m42AccessControlServiceFrontendApp
 * @description
 * # m42AccessControlServiceFrontendApp
 *
 * Main module of the application.
 */
angular
    .module('m42AccessControlServiceFrontendApp', [
        'config',
        'ngAnimate',
        'ngCookies',
        'ngResource',
        'ui.router',
        'ngSanitize',
        'ngTouch',
        'ngResource',
        'ngHelperBusy',
        'ngHelperCropResize',
        'ngHelperDynamicTemplateLoader',
        'pascalprecht.translate',
        'ui.bootstrap',
        'Matrix42.Utils',
        'm42DevMessage',
        'angular-jwt',
        'ng.shims.placeholder',
        'm42Filters',
        'ngHelperAirbrake',
        'mx.auth'

    ])
    .config(['$locationProvider', '$urlRouterProvider', '$stateProvider', '$translateProvider', '$dynamicTemplateLoaderProvider', 'mxAuthClientProvider', '$globalConfiguration', '$airbrakeProvider', '$m42UrlParamProvider',
        function ($locationProvider, $urlRouterProvider, $stateProvider, $translateProvider, $dynamicTemplateLoaderProvider, mxAuthClientProvider, $globalConfiguration, $airbrakeProvider, $m42UrlParamProvider) {

            // error logging to airbrake
            if (!(window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost')) {
                $airbrakeProvider.setProject('124368', 'cf4ac35294004ef980557f9d8790418e', $globalConfiguration.name);
            }

            var resolveSession = {
                $currentSession: ['mxAuthSessionClient', '$location', function (mxAuthSessionClient, $location) {
                    return mxAuthSessionClient.makeSessionAvailable({ lang: $location.$$search.lang });
                }]
            };

            // Use html5 mode urls with out a hash bang (#).
            $locationProvider.html5Mode(true);

            // Add url important redirect parameters to all states.
            $m42UrlParamProvider.setup($globalConfiguration.redirectSavedUrls);

            // Setup routes.
            $urlRouterProvider.otherwise('/');

            $stateProvider
                .state('Splash', {
                    template: '',
                    url: '/',
                    controller: 'SplashCtrl'
                })
                .state('main', {
                    templateUrl: 'partials/main.html',
                    controller: 'MainCtrl',
                    resolve: resolveSession,
                    abstract: true,
                })
                .state('main.account', {
                    url: 'account',
                    templateUrl: 'partials/main.account.html',
                    controller: 'MyaccountCtrl'
                })
                .state('main.account_old', {
                    url: '/account',
                    templateUrl: 'partials/main.account.html',
                    controller: 'MyaccountCtrl'
                })
                .state('main.enterprise', {
                    url: 'enterprise/:tab',
                    templateUrl: 'partials/main.enterprise.html',
                    controller: 'EnterpriseCtrl'
                })
                .state('main.enterprise_old', {
                    url: '/enterprise/:tab',
                    templateUrl: 'partials/main.enterprise.html',
                    controller: 'EnterpriseCtrl'
                })
                .state('error', {
                    url: '/error/:id',
                    parent: null,
                    templateUrl: 'partials/error.html',
                    controller: 'ErrorCtrl'
                })
                .state('error2', {
                    url: '/error/:id/:category',
                    templateUrl: 'partials/error.html',
                    parent: null,
                    controller: 'ErrorCtrl'
                })
                .state('resetpassword', {
                    url: '/resetpassword/:account/:token',
                    templateUrl: 'partials/resetpassword.html',
                    controller: 'ResetPasswordCtrl',
                    data: {
                        title: 'Enter your new password'
                    }
                })
                .state('forgotpassword', {
                    url: '/forgotpassword',
                    templateUrl: 'partials/forgotpassword.html',
                    controller: 'ForgotPasswordCtrl',
                    data: {
                        title: 'Forgot your password'
                    }
                })
                .state('forgotpassword_old', {
                    url: '/forgotpassword/',
                    templateUrl: 'partials/forgotpassword.html',
                    controller: 'ForgotPasswordCtrl'
                })
                .state('register', {
                    url: '/register',
                    templateUrl: 'partials/register.html',
                    controller: 'RegisterCtrl'
                });

            // Translations
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: './translate/{part}/{lang}.json?t=' + new Date().getTime()
            });
            $translateProvider.usePostCompiling(true);
            $translateProvider.preferredLanguage('english');

            // Session
            var base = $globalConfiguration.apiEndpoint;
            if (!base && window.location.href.toLowerCase().indexOf('localhost/matrix42.acs') > -1) {
                base = 'https://localhost/matrix42.acs';
            }

            mxAuthClientProvider.setup({
                endpoints: {
                    authorize: base + $globalConfiguration.apiOAuthAuthorizePath,
                    signout: base + $globalConfiguration.apiOAuthSignOutPath
                },
                clientid: $globalConfiguration.apiOAuthClientID,
                scope: $globalConfiguration.apiOAuthScope
            });

            // Register the HTTP interceptors
            $dynamicTemplateLoaderProvider.registerInterceptors();
        }])
    .run(['$rootScope', '$translate', '$translatePartialLoader', 'M42Translation', '$dynamicTemplateLoader', '$m42TemplateCachingStrategy', 'mxAuthAcsSessionClient', '$m42DevMessageService', '$airbrake', '$state', '$window',
        function ($rootScope, $translate, $translatePartialLoader, M42Translation, $dynamicTemplateLoader, $m42TemplateCachingStrategy, mxAuthAcsSessionClient, $m42DevMessageService, $airbrake, $state, $window) {
            $rootScope.$on('$locationChangeStart', function (next, current) {
                M42Translation.initialize(current);
            });

            $rootScope.$on('$stateChangeSuccess', function () {
                //Not all states in stateProvider has data.title object 
                if($state.current.data !== undefined){
                $window.document.title = $state.current.data.title;}
            });

            // When adding parts and making many refreshes in a short amount of time, there is a race condition
            // between the different refreshes in the translate partial loader. This causes earlier refreshes
            // to finish after later refreshes sometimes. However the earlier refreshes do not contain all parts
            // and so some translations are missing on the page.
            // As a workaround we just load all parts on the start and then do one refresh.
            $translatePartialLoader.addPart('directive_enterprise_general');
            $translatePartialLoader.addPart('directive_enterprise_groups');
            $translatePartialLoader.addPart('directive_enterprise_users');
            $translatePartialLoader.addPart('directive_enterprise_serviceconnections');
            $translatePartialLoader.addPart('directive_myaccount_export');
            $translatePartialLoader.addPart('directive_myaccount_tokens');
            $translatePartialLoader.addPart('directive_error');
            $translatePartialLoader.addPart('directive_header');
            $translatePartialLoader.addPart('enterprise');
            $translatePartialLoader.addPart('error');
            $translatePartialLoader.addPart('forgotpassword');
            $translatePartialLoader.addPart('modals_enterprise_groups');
            $translatePartialLoader.addPart('modals_enterprise_invite_user');
            $translatePartialLoader.addPart('modals_myaccount_tokens_created');
            $translatePartialLoader.addPart('modals_myaccount_tokens_delete');
            $translatePartialLoader.addPart('modals_enterprise_serviceconnections_delete');
            $translatePartialLoader.addPart('myaccount');
            $translatePartialLoader.addPart('register');
            $translatePartialLoader.addPart('resetpassword');
            $translatePartialLoader.addPart('signin');
            $translatePartialLoader.addPart('countries');
            $translatePartialLoader.addPart('languages');
            $translate.refresh();

            // Use custom caching strategy based on a uuid, which is generated during build.
            $dynamicTemplateLoader.setCachingStrategyService($m42TemplateCachingStrategy);

            //write integration message
            $m42DevMessageService.writeIntegrationMessage();

            mxAuthAcsSessionClient.onEstablished(function (session) {
                $airbrake.setCustomContext({
                    user: session.profile.UserId,
                    enterpriseName: session.profile.EnterpriseName
                });
            });
        }
    ]);
