'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .service('$m42CsvFileDownload', [function () {
        var self = this;

        self.downloadCsv = function (csvData, fileName) {
            var downloadLink = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
            var linkElement = angular.element('<a></a>', {
                href: downloadLink,
                download: fileName
            });

            linkElement.css('display', 'none').appendTo('body');
            linkElement[0].click();
            linkElement.remove();
        };

    }]);
