'use strict';

angular.module('m42AccessControlServiceFrontendApp').service('M42AzureBlobPersistenceEngine', ['$http', '$q', 'M42BrowserCapabilities',  function($http, $q, M42BrowserCapabilities) {
    var self = this;

    self.uploadContentIntoPreSignedUrl = function(fileObject, preSignedBlobUri) {
        var defer = $q.defer();

        // check if we support a file read if not abourt
        if (!M42BrowserCapabilities.SupportsFileReader) {
            defer.reject();
            return;
        }

        // get the content
        var fileSize    = fileObject.size;
        var content     = fileObject.slice(0, fileSize);

        // upload
        $http({
            method: 'PUT',
            url: preSignedBlobUri,
            headers: {
                'x-ms-blob-type': 'BlockBlob'
            },
            data: content,

            // We need to just remove the authorization header because sometimes
            //other frameworks are injecting this header and this breaks the azure
            // upload
            transformRequest: [function(data, headersGetter){
                var headers = headersGetter();
                delete headers.Authorization;
                delete headers.authorization;

                // We need to return the data for the next in
                // transformation chain
                return data;
            }].concat($http.defaults.transformRequest)

        }).then(function() {
            defer.resolve();
        }).catch(function(reason) {
            defer.reject(reason);
        });

        return defer.promise;
    };

    self.upload = function(fileObject, target) {
        return self.uploadContentIntoPreSignedUrl(fileObject, target);
    };
}]);
