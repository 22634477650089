'use strict';

angular.module('mx.auth').service('mxAuthAcsSessionClient', ['$q', 'mxAuthSessionClient', 'jwtHelper', '$timeout', function ($q, mxAuthSessionClient, jwtHelper, $timeout) {
    var self = this;

    /*
     * This class implements the session object which allows some standard operations on the given
     * JWT token, e.g. checking if specific claims exists or the user is in a specific role
     */
    function AcsSessionModel(mxSessionModel) {
        var self = this;

        self.raw = mxSessionModel.raw;

        /*
         * The verify operation returns true or false to indicate if the session
         * is expired or is just using a bad token.
         */
        self.verify = function () {
            return mxSessionModel.verify();
        };

        /*
         * This getter gives access to the claims encoded
         * in the token. The claims are returned as object
         */
        self.__defineGetter__("claims", function () {
            return mxSessionModel.claims;
        });

        /*
         * This getter returns all claim keys
         */
        self.__defineGetter__("claim_keys", function () {
            return mxSessionModel.claim_keys;
        });

        /*
         * This getter gives access to the roles the user
         * is part of. It does not return the enterprise specific roles
         */
        self.__defineGetter__("user_roles", function () {
            return self.claims['role'].filter(function (role) {
                return (role.indexOf('enterprise_') === -1);
            })
        });

        /*
         * This getter gives access to the roles the user
         * is indirectly part of. It does not return the user specific roles
         */
        self.__defineGetter__("enterprise_roles", function () {
            return self.claims['role'].filter(function (role) {
                return (role.indexOf('enterprise_') === 0);
            }).map(function (role) {
                return role.replace('enterprise_' + self.claims['enterpriseid'] + '_', '');
            });
        });

        /*
         * This getter gives access to the roles the user
         * is part of. It returns enterprise and user specific roles
         */
        self.__defineGetter__("roles", function () {
            return self.claims['role'].map(function (role) {
                return role.replace('enterprise_' + self.claims['enterpriseid'] + '_', '');
            });
        });

        /*
         * This getter gives access to a user profile we can use in many standard scenarios
         */
        self.__defineGetter__("profile", function () {
            return {
                // Common Properties
                Salutation: self.claims['identity/claims/salutation'],
                FirstName: self.claims['identity/claims/firstname'],
                LastName: self.claims['identity/claims/lastname'],
                Contact: self.claims['email'],
                EnterpriseName: self.hasJoinedEnterprise() ? self.claims['enterprisename'] : self.claims['identity/claims/company'],
                Culture: self.claims['culture'],

                // Unique Identifier
                UserId: self.claims['unique_name'],
                EnterpriseId: self.claims['enterpriseid']
            };
        });

        /*
         * This API allows to check if a user part of a specific role
         */
        self.hasRole = function (role) {
            return (self.roles.indexOf(role) !== -1);
        };

        /*
         * Validates if a user is part of an enterprise
         */
        self.hasJoinedEnterprise = function () {
            return (self.claims['enterpriseid'] !== undefined)
        };

        /*
         * Check if the given user is manager of the enterprise
         */
        self.isEnterpriseManager = function () {
            // DO NOT USE hasRole for this
            return (self.enterprise_roles.indexOf('manager') !== -1)
        };
    }

    /*
     * This method acts as a gatekeeper between the normal controller and the authorization process. Every controller
     * should call this function during load and should enclose all other logic in them.
     */
    self.makeSessionAvailable = function (options) {
        return mxAuthSessionClient.makeSessionAvailable(options).then(function (sessionModel) {
            return new AcsSessionModel(sessionModel);
        });
    };

    /*
     * The destroy API ensures that the token becomes invalid and triggers also a session expired
     * notification so that the application can react on this.
     *
     * @param redirectUri - (Optional) When specified this will be used as a redirect url after signing
     *                      out. When not specified it will default to the current url.
     */
    self.destroySession = function (redirectUri) {
        mxAuthSessionClient.destroySession(redirectUri);
    };

    /*
     * Allows to set a callback to become notified when a new session has been established.
     */
    self.onEstablished = function (cb) {
        mxAuthSessionClient.onEstablished(function (sessionModel) {
            cb(new AcsSessionModel(sessionModel));
        });
    };

    /*
     * Allows to set a callback to become notified when the session is expired.
     */
    self.onExpired = function (cb, eventType) {
        mxAuthSessionClient.onExpired(function (sessionModel) {
            cb(new AcsSessionModel(sessionModel), eventType);
        });
    };

    /*
     * Returns the activeSession to prevent unneeded "makeSessionAvailable()"-calls when the Session is already available
     */
    self.getActiveSession = function () {
        var sessionModel = mxAuthSessionClient.getActiveSession();
        if (sessionModel) {
            return new AcsSessionModel(sessionModel);
        }
    };
}]);
