'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .controller('ResetPasswordCtrl', ['$scope', '$stateParams', '$busy', '$location', 'M42Validation', '$timeout', 'M42Helpers', '$m42AcsBackend', '$window', '$q',
        function ($scope, $stateParams, $busy, $location, M42Validation, $timeout, M42Helpers, $m42AcsBackend, $window, $q) {
            var account = $stateParams.account;
            var token = $stateParams.token;

            $scope.model = {
                newPassword: null,
                repeatPassword: null
            };

            $scope.viewMode = 0;
            $scope.httpCode = 200;
            $scope.errorMessage = '';
            $scope.loading = false;

            $scope.isValid = function () {
                return !M42Validation.isEmpty($scope.model.newPassword) && !M42Validation.isEmpty($scope.model.repeatPassword);
            };

            $scope.passwordsMatch = function () {
                if (!M42Validation.isEmpty($scope.model.newPassword) && !M42Validation.isEmpty($scope.model.repeatPassword))
                    return $scope.model.newPassword === $scope.model.repeatPassword;
                return true;
            };

            $scope.isEmpty = function (val) {
                return M42Validation.isEmpty(val);
            };

            var returnUrl = M42Helpers.parseQueryString($location.url(), true).returnUrl;

            if (returnUrl) {
                //for old emails with invalid url
                returnUrl =returnUrl.replace(/&amp;/g, '&')
            }

            $scope.setPassword = function () {
                $scope.loading = true;
                $busy.setMessage('');
                $busy.during(finishLostPasswordProcess(account, token, $scope.model.newPassword, returnUrl)
                    .then(function (profile) {
                        return createSession(profile.MailAddress, $scope.model.newPassword, returnUrl).then(function () {
                            // The createSession method redirects the user to the OTP signin endpoint. With this
                            // flag we show this to the user as long as this page is still visible.
                            $scope.loading = false;
                            $scope.viewMode = 1;
                        }).catch(function (error) {
                            $scope.loading = false;
                            $scope.httpCode = error.errorCode;
                            $scope.errorMessage = 'Failed to sign in: ' + error.errorMessage;
                        });
                    })
                    .catch(function (error) {
                        $scope.loading = false;
                        $scope.httpCode = error.errorCode;
                        $scope.errorMessage = error.errorMessage;
                    }));
            };


            function createSession(username, password, returnUrl) {
                var defered = $q.defer();

                $m42AcsBackend.session.signIn({returnUrl: returnUrl}, {
                    UserName: username,
                    Password: password
                }).$promise.then(function (otpSignInUrl) {
                        if (!otpSignInUrl) {
                            defered.reject({
                                errorCode: 401,
                                errorMessage: 'Response did not contain a valid sign in url.'
                            });
                        } else {
                            $window.location = otpSignInUrl;
                            defered.resolve();
                        }
                    }).catch(function (response) {
                        defered.reject({
                            errorCode: response.status,
                            errorMessage: response.data || response.statusText
                        });
                    });

                return defered.promise;
            }

            function finishLostPasswordProcess(resetPasswordAccount, resetPasswordToken, newPassword, returnUrl) {
                var defer = $q.defer();

                $m42AcsBackend.account.resetPassword({id: resetPasswordAccount, returnUrl: returnUrl}, {
                    ResetPasswordToken: resetPasswordToken,
                    NewPassword: newPassword
                }).$promise.then(function (response) {
                        defer.resolve(response);
                    }).catch(function (response) {
                        defer.reject({
                            errorCode: response.status,
                            errorMessage: response.data || response.statusText
                        });
                    });

                return defer.promise;
            }
        }]);
