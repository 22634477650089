angular.module('m42AccessControlServiceFrontendApp').controller('EnterpriseInviteUserCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

    $scope.openInviteUserDialog = function(enterprise, enterpriseAccounts) {

        var modalInstance = $modal.open({
            templateUrl: 'partials/modals/modal_enterprise_invite.html',
            controller: 'EnterpriseInviteUserCtrl',
            resolve: {
                enterprise: function() {
                    return enterprise;
                },
                enterpriseAccounts: function() {
                    return enterpriseAccounts;
                }
            }
        });

        // returns a promise which contains the invited user
        return modalInstance.result;
    };
}]);
