'use strict';

/**
 * @ngdoc function
 * @name m42AccessControlServiceFrontendApp.controller:MyaccountCtrl
 * @description
 * # MyaccountCtrl
 * Controller of the m42AccessControlServiceFrontendApp
 */
angular.module('m42AccessControlServiceFrontendApp')
    .controller('MyaccountCtrl', ['$scope', 'mxAuthAcsSessionClient', '$m42AcsBackend', 'M42Validation', 'M42AzureBlobPersistenceEngine', '$q', '$busy', '$location', '$m42Countries', 'M42Translation', '$stateParams',
        function ($scope, mxAuthAcsSessionClient, $m42AcsBackend, M42Validation, M42AzureBlobPersistenceEngine, $q, $busy, $location, $m42Countries, M42Translation, $stateParams) {

            // The avatar preview
            $scope.avatarPreview = null;

            $scope.showAlert = function (errorCode, errorMessage) {
                if (typeof errorMessage === 'object') {
                    if (errorMessage.Message) {
                        errorMessage = errorMessage.Message;
                    } else {
                        errorMessage = JSON.stringify(errorMessage);
                    }
                }

                $scope.errorMessage = errorMessage + ' (' + errorCode + ')';
                $scope.errorClass = 'alert-danger';
            };

            // The avatar uploaded
            // calculates the upload uri
            $scope.avatarUploadIntoBlob = function (fileObject) {

                var defer = $q.defer();

                // get the avatar uri target
                $m42AcsBackend.sessionProfile.avatarLocationW({}).$promise.then(function (target) {

                    // upload the file object
                    M42AzureBlobPersistenceEngine.upload(fileObject, target.SasUrl).then(function () {
                        defer.resolve();
                    }).catch(function (reason) {
                        $scope.showAlert(500, 'Failed to upload image');
                        defer.reject(reason);
                    });
                });

                // return the promise
                return defer.promise;
            };

            $scope.allCountries = $m42Countries.getSimpleCountryList();
            $scope.allLanguages = M42Translation.getAvailableLanguages();

            $scope.user = {};

            var originalUserCompanyName = '';

            // we use this object to determine if there were any changes on UI
            // if there were no changes, then we will disable 'save' button
            // otherwise we will enable it
            var userOriginal = {};

            $scope.httpCode = -1;
            $scope.errorMessage = '';

            $scope.isInOrganization = function () {
                return $scope.user.IsInOrganization;
            };

            $scope.isWelcome = !M42Validation.isEmpty($stateParams.welcome);
            if ($scope.isWelcome) {
                delete $location.$$search.welcome;
                setTimeout(function () {
                    $('.alert').alert('close');
                }, 3000);
            }

            $scope.isDataLoaded = false;

            $scope.getUserProfile = function () {
                $scope.isDataLoaded = true;

                userOriginal = $m42AcsBackend.sessionProfile.get({}, function () {

                    if (userOriginal.IsInOrganization) {
                        originalUserCompanyName = userOriginal.CompanyName;
                        userOriginal.CompanyName = userOriginal.EnterpriseAccount.AccountName;
                    }

                    // we need to create a copy of enterprise model
                    // but this copy should be disconnected from angular scope
                    // so changes in UI should not trigger any changes in a model
                    // a simplest way to do this is to serialize/deserialize model
                    // because it will create a new object with the same properties
                    $scope.user = JSON.parse(JSON.stringify(userOriginal));
                    $scope.avatarPreview = $scope.user.AvatarSasUrl;

                    $scope.isDataLoaded = true;
                });
            };

            $scope.isEmpty = function (val) {
                return M42Validation.isEmpty(val);
            };

            $scope.isValid = function () {
                return !M42Validation.isEmpty($scope.user.Salutation) && !M42Validation.isEmpty($scope.user.FirstName) && !M42Validation.isEmpty($scope.user.LastName) && !M42Validation.isEmpty($scope.user.Street) && !M42Validation.isEmpty($scope.user.ZipCode) && !M42Validation.isEmpty($scope.user.City) && !M42Validation.isEmpty($scope.user.Country) && !M42Validation.isEmpty($scope.user.CompanyName);
            };

            $scope.isChanged = function () {
                var changed = $scope.user.Country !== userOriginal.Country ||
                    $scope.user.City !== userOriginal.City ||
                    $scope.user.ZipCode !== userOriginal.ZipCode ||
                    $scope.user.VatId !== userOriginal.VatId ||
                    $scope.user.Street !== userOriginal.Street ||
                    $scope.user.Street2 !== userOriginal.Street2 ||
                    $scope.user.CompanyName !== userOriginal.CompanyName ||
                    $scope.user.FirstName !== userOriginal.FirstName ||
                    $scope.user.LastName !== userOriginal.LastName ||
                    $scope.user.Salutation !== userOriginal.Salutation ||
                    $scope.user.Phone !== userOriginal.Phone ||
                    $scope.user.CultureString !== userOriginal.CultureString;

                if (changed === true && $scope.httpCode === 200) {
                    $scope.httpCode = -1;
                }

                return changed;
            };

            $scope.createOrganization = function () {
                $busy.setMessage('');
                $busy.during($m42AcsBackend.account.promote({id: $scope.user.MailAddress}, {}).$promise).then(function () {

                    //Make oauth2 request to regenerate the token
                    mxAuthAcsSessionClient.destroySession();

                    $scope.httpCode = 200;
                    $scope.errorMessage = '';
                }).catch(function (response) {
                    $scope.httpCode = response.status;
                    $scope.errorMessage = response.data || response.statusText;
                });
            };

            $scope.removeFromOrganization = function () {
                $busy.setMessage('');
                $busy.during($m42AcsBackend.enterprise.leave({
                    id: $scope.user.EnterpriseAccount.Id,
                    user: $scope.user.MailAddress
                }).$promise).then(function () {
                    userOriginal.EnterpriseAccount = null;
                    $scope.user.EnterpriseAccount = null;

                    userOriginal.IsInOrganization = false;
                    $scope.user.IsInOrganization = false;

                    userOriginal.CompanyName = originalUserCompanyName;
                    $scope.user.CompanyName = originalUserCompanyName;

                    $scope.httpCode = 200;
                    $scope.errorMessage = '';
                }).catch(function (response) {
                    $scope.httpCode = response.status;
                    $scope.errorMessage = response.data || response.statusText;
                });
            };

            $scope.saveEntity = function () {
                var saveInternal = function () {
                    var defer = $q.defer();

                    $m42AcsBackend.sessionProfile.update({}, $scope.user).$promise.then(function () {

                        M42Translation.changeLanguage($scope.user.CultureString);

                        defer.resolve({code: 200});
                    }).catch(function (httpResponse) {
                        defer.resolve(httpResponse);
                    });

                    // done
                    return defer.promise;
                };

                $scope.user.EnterpriseAccount = null;
                $scope.user.IsInOrganization = false;

                $busy.setMessage('');
                $busy.during(saveInternal()).then(function (response) {
                    if (response.code !== 200) {
                        $scope.httpCode = response.status;
                        $scope.errorMessage = response.data || response.statusText;
                    } else {
                        $scope.httpCode = 200;
                        $scope.errorMessage = '';

                        if (userOriginal.IsInOrganization) {
                            $scope.user.EnterpriseAccount = userOriginal.EnterpriseAccount;
                            $scope.user.IsInOrganization = userOriginal.IsInOrganization;
                        }

                        // we need to create a copy of enterprise model
                        // but this copy should be disconnected from angular scope
                        // so changes in UI should not trigger any changes in a model
                        // a simplest way to do this is to serialize/deserialize model
                        // because it will create a new object with the same properties
                        userOriginal = JSON.parse(JSON.stringify($scope.user));
                    }
                });
            };

            $scope.getUserProfile();
        }]);
