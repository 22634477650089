'use strict';

/*
 * This factory generates the correct value of the API Host the system should use. It depends on the global
 * configuration setting which is generated from a grunt task. Currently the following logic is implemented
 *
 * grunt serve         -> Starts a development server which is using the accounts-dev.matrix42.com as backend
 * grunt serve:local   -> Starts a development server which is using https://localhost/matrix42.acs as backend
 * grunt build         -> Compiles the distribution build, the api endpoint is equal to the origin in the
 *                        browsers addressbar
 * grunt serve:dist    -> Same as grunt build but starts a local server
 *
 * To override please edit /scripts/config.js but be careful the file will be generated from the system when ever
 * grunt server:??? will be executed.
 *
 */
angular.module('m42AccessControlServiceFrontendApp').factory('$m42AcsHost', [ '$globalConfiguration', '$window', function ResolveHost($globalConfiguration, $window) {

    if ($globalConfiguration.apiEndpoint) {
        return $globalConfiguration.apiEndpoint;
    } else {
        var origin = getOrigin($window.location);

        // Make it work with local IIS running under https://localhost/matrix42.acs/...
        if ($window.location.href.toLowerCase().indexOf('/matrix42.acs/') > -1) {
            return origin + '/matrix42.acs';
        } else {
            return origin;
        }
    }
}]);
