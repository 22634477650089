'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:mxGlass
 * @description
 * # mxGlass
 */
angular.module('m42AccessControlServiceFrontendApp')
    .directive('mxGlass', function () {
        return {
              transclude: true,
              template: '<div class="glass"><div></div><div style="position: absolute; top: 0; left: 0;" ng-transclude></div></div>',
              scope: true,
              restrict: 'C'
        };
    });
