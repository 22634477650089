'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:enterpriseServiceConnections
 * @description
 * # enterpriseServiceConnections
 */
angular.module('m42AccessControlServiceFrontendApp')
    .directive('enterpriseServiceConnections', function () {
        return {
            templateUrl: 'partials/directives/enterprise_serviceconnections.html',
            controller: 'enterpriseServiceConnectionsCtrl',
            restrict: 'C',
            scope: {
                enterprise: '=',
                error: '&'
            }
        };
    })
    .controller('enterpriseServiceConnectionsCtrl', ['$scope', '$controller', '$modal', '$m42AcsBackend', '$translate', '$busy', function ($scope, $controller, $modal, $m42AcsBackend, $translate, $busy) {
   
        $scope.openServiceConnectionDialogParent = function (enterprise) {
            $scope.openServiceConnectionDialog(enterprise).then(function () {
    
                // refresh the list of service connections groups, may be we created a new one.
                $scope.enterpriseServiceConnections = $scope.getCompanySpecificServiceConnections($scope.enterprise.Id);
    
            });
        };
    
        $scope.$watch('enterprise', function () {
            if ($scope.enterprise && $scope.enterprise !== undefined && $scope.enterprise.AccountName && $scope.enterprise.AccountName !== undefined) {
                $scope.getCompanySpecificServiceConnections($scope.enterprise.Id);
            }
        });
    
        //Calls an ACS API to get a collection of Company specific roles by enterpriseId.
        $scope.getCompanySpecificServiceConnections = function () {
    
            $busy.during($m42AcsBackend.enterprise.getEnterpriseOAuth2Clients({id: $scope.enterprise.Id}).$promise).then(function (enterpriseServiceConnections) {
    
                // assign the roles to the scope
                $scope.enterpriseServiceConnections = enterpriseServiceConnections;
    
            }).catch(function () {
                //debugger;
            });
        };
    
        //Calls an ACS API to remoce a Company specific role.

        $scope.deleteServiceConnection = function (enterprise, clientId) {
            return $modal.open({
                templateUrl: 'partials/modals/modal_enterprise_serviceconnection_delete.html',
                controller: 'EnterpriseServiceConnectionDeleteCtrl',
                resolve: {
                    enterprise: function () {
                        return enterprise;
                    },
                    clientId: function () {
                        return clientId
                    }
                }
            }).result.then(function () {
                return $busy.during($m42AcsBackend.enterprise.deleteOAuth2ClientById({
                    id: enterprise.Id,
                    clientId: clientId
                }).$promise)
                    .then(function () {
                        
            angular.forEach($scope.enterpriseServiceConnections, function (serviceConnection, index) {

                if (serviceConnection.ClientId === clientId) {
                    $scope.enterpriseServiceConnections.splice(index, 1);
                }

            });
                    })
                    .catch(function (error) {
                        $scope.error({code: 500, message: error.data || error.statusText});
                    });
            });
        };

        $scope.getServiceConnectionImage = function(isGenericServiceConnection){
                if(isGenericServiceConnection == true)
                {
                    return "images/enterprise/service-connection-generic-logo.png";
                }else{
                    return "images/enterprise/service-connection-mx42-logo.png";
                }
        };
    }]);
    