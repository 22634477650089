'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:mxBox
 * @description
 * # mxBox
 */
angular.module('m42AccessControlServiceFrontendApp')
  .directive('mxBox', function() {
    return {
      transclude: true,
      template: '<div class="mx-box-wrapper clearfix" ng-class="{orange: orange}"><div class="mx-box-content clearfix"><h1 ng-bind-html="title"></h1><div ng-transclude class="clearfix"></div></div><div class="mx-box-rounded-image clearfix" style="background-image: url(\'{{image}}\');"></div></div>',
      restrict: 'A',
      scope: true,
      link: function postLink(scope, element, attrs) {
          scope.title   = attrs.mxBox;
          scope.image   = attrs.icon;
          scope.orange  = element.hasClass('orange');

          attrs.$observe('mxBox', function(val) {
              scope.title = val;
          });
      }
    };
  });
