'use strict';

/*
 * This service offers capabilities to clone an entity by deep serialization into JSON and back
 * to object. This also decouples the object as self and the different child from angular js
 * code.
 */
angular.module('m42AccessControlServiceFrontendApp').service('$m42Clone', function() {
    var self = this;

    self.clone = function(entity) {
        return JSON.parse(JSON.stringify(entity));
    };
});