'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:enterpriseGeneral
 * @description
 * # enterpriseGeneral
 */
angular.module('m42AccessControlServiceFrontendApp')
    .directive('enterpriseGeneral', function () {
        return {
            templateUrl: 'partials/directives/enterprise_general.html',
            controller: 'enterpriseGeneralCtrl',
            restrict: 'C',
            scope: {
                enterprise: '=',
                error: '&'
            }
        };
    })
    .controller('enterpriseGeneralCtrl', ['$scope', '$q', '$busy', '$m42AcsBackend', 'M42Validation', '$translate', '$m42Countries',
        function ($scope, $q, $busy, $m42AcsBackend, M42Validation, $translate, $m42Countries) {
            $scope.isFormChanged = false;
            $scope.isFormValid = true;

            $scope.allCountries = $m42Countries.getSimpleCountryList();

            $scope.formChanged = function () {

                // set changed
                $scope.isFormChanged = true;

                // validate
                $scope.isFormValid = !M42Validation.isEmpty($scope.enterprise.AccountName) &&
                    !M42Validation.isEmpty($scope.enterprise.Street) &&
                    !M42Validation.isEmpty($scope.enterprise.ZipCode) &&
                    !M42Validation.isEmpty($scope.enterprise.City) &&
                    !M42Validation.isEmpty($scope.enterprise.Country);

                // validate the customer number
                if ($scope.isFormValid && !M42Validation.isEmpty($scope.enterprise.CustomerNumber)) {
                    $scope.isFormValid = /^[0-9]{7}$|^[0-9]{3}$/.test($scope.enterprise.CustomerNumber);
                }


            };

            $scope.saveEnterprise = function () {
                $busy.setMessage('');
                $busy.beBusy();

                $q.all([
                    $m42AcsBackend.enterprise.accounts({id: $scope.enterprise.Id}).$promise,
                    $m42AcsBackend.enterprise.memberships({id: $scope.enterprise.Id}).$promise,
                    $m42AcsBackend.enterprise.emaildomains({id: $scope.enterprise.Id}).$promise
                ]).then(function (results) {
                    $scope.enterprise.UserProfiles = results[0];
                    $scope.enterprise.Roles = results[1];
                    $scope.enterprise.EmailDomains = results[2];

                    return $m42AcsBackend.enterprise.update({}, $scope.enterprise).$promise;
                }).then(function () {
                    $translate('EnterpriseGeneral.SavedSuccessfully').then(function (msg) {
                        // set the form not save
                        $scope.isFormChanged = false;

                        // send the success code
                        $scope.error({code: 0, message: msg});
                    });

                    $busy.beFree();
                }).catch(function (reason) {
                    // just send the error
                    $scope.error({code: reason.status, message: reason.statusText});
                    $busy.beFree();
                });
            };
        }]);
