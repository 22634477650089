'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .directive('inputSelectOnFocus', function () {
        return {
            restrict: 'A',
            scope: true,
            link: function postLink(scope, element) {
                element.on('focus click', function () {
                    this.select();
                });
            }
        };
    });
