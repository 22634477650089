'use strict';

angular.module('m42Filters', [])
  /**
   * orderByTranslated Filter
   * Sort ng-options or ng-repeat by translated values
   * @example
   *   ng-repeat="country in allCountries | orderByTranslated:'name'"
   * @param  {Array} array
   * @param  {String} objKey
   * @return {Array}
   */
  .filter('orderByTranslated', ['$translate',  function($translate) {
    return function(array, objKey) {
      var translated = [];
      angular.forEach(array, function(value) {
        var i18nKey = objKey ? value[objKey] : value;
        translated.push({
          key: value,
          label: $translate.instant(i18nKey)
        });
      });

      return translated.sort(function (a, b) {
          return a.label.localeCompare(b.label);
      }).map(function (obj) {
          return obj.key;
      });

    };
  }]);