'use strict';

/**
 * @ngdoc service
 * @name m42AccessControlServiceFrontendApp.m42ErrorService
 * @description
 * # m42ErrorService
 * Service in the m42AccessControlServiceFrontendApp.
 */
angular.module('m42AccessControlServiceFrontendApp').service('M42ErrorService', ['$resource', '$m42AcsHost', function($resource, $m42AcsHost) {
    var self = this;

    self.action = $resource($m42AcsHost + '/api/error/:id', {}, {
        get:                { method: 'GET' },
        getByIdAndCategory: { method: 'GET', url: $m42AcsHost + '/api/error/:id/:category' }
    });
}]);
