'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .controller('ForgotPasswordCtrl', ['$scope', '$location', '$busy', 'M42Validation', 'M42Translation', 'M42Helpers', '$m42AcsBackend', '$q', '$state',
        function ($scope, $location, $busy, M42Validation, M42Translation, M42Helpers, $m42AcsBackend, $q, $state) {
            $scope.model = {
                email: null
            };

            $scope.viewMode = 0;
            $scope.loading = false;

            $scope.isValid = function () {
                return !M42Validation.isEmpty($scope.model.email);
            };

            $scope.lostPassword = function () {
                $scope.loading = true;
                var language = M42Translation.getCurrentLanguage();
                var returnUrl = M42Helpers.parseQueryString($location.url(), true).ReturnUrl;

                var url = encodeURIComponent(getApplicationRoot(window.location) + '?lang=' + language);

                $busy.setMessage('');
                $busy.during(initializeLostPasswordProcess($scope.model.email, url, returnUrl)).then(function () {
                    $scope.viewMode = 1;
                    $scope.loading = false;
                });
            };

            // This function initializes the lost password process in the backend which means
            // the enduser will get an e-mail
            function initializeLostPasswordProcess(email, redirectUri, returnUrl) {
                var defer = $q.defer();

                $m42AcsBackend.account.lostPassword({id: email}, {
                    LostPasswordHandler: redirectUri,
                    ReturnUrl: returnUrl
                }).$promise.then(function () {
                        defer.resolve();
                    }).catch(function () {
                        defer.reject();
                    });

                return defer.promise;
            }

            var restPasswordToken = $location.$$search.resettoken;
            var restPasswordAccount = $location.$$search.resetaccount;

            if (restPasswordToken !== undefined) {
                $state.go('resetpassword', {"account": restPasswordAccount, "token": restPasswordToken});
            }
        }]);
