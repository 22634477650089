'use strict';

/**
 * @ngdoc service
 * @name m42AccessControlServiceFrontendApp.m42Session
 * @description
 * # m42Session
 * Service in the m42AccessControlServiceFrontendApp.
 */
angular.module('m42AccessControlServiceFrontendApp').
    service('$m42AcsBackend', ['$q', '$http', '$globalConfiguration', '$resource', '$m42AcsHost', 'mxAuthAcsSessionClient',
        function ($q, $http, $globalConfiguration, $resource, $m42AcsHost, mxAuthAcsSessionClient) {
            var self = this;

            var defaultHeaders = {
                'Authorization': function () {
                    // load the token
                    var session = mxAuthAcsSessionClient.getActiveSession() || {},
                    token = session.raw;
                    if (token !== null && token !== undefined) {
                        return 'Bearer ' + token;
                    } else {
                        return null;
                    }
                }
            };

            function transformResponseCallbackEncapsulateStringResponse() {
                return [function (data) {
                    return {data: data.replace(/"/g, '')};
                }];
            }

            // Use interceptor instead of transformResponse to get id from location header
            // because transformResponse does not seem to work with new version of angular.
            function interceptorGetNewIdFromLocationHeader() {
                return {
                    response: function (response) {
                        var locationHeader = response.headers().location;
                        return locationHeader && locationHeader.split('/').pop();
                    }
                };
            }

            function interceptorGetUrlFromLocationHeader() {
                return {
                    response: function (response) {
                        return response.headers().location;
                    }
                };
            }

            // The session backend allows to handle session creation and destroying
            // an established session. Mostly no authorization is required
            self.session = $resource($m42AcsHost + '/api/session', {}, {
                signIn: { method: 'POST', url: $m42AcsHost + '/api/session/signin', params: {returnUrl: ':returnUrl'}, interceptor: interceptorGetUrlFromLocationHeader() }
            });

            // The session profile is available to get the logged in users information e.g.
            // name or the avatar image. In addition it can be used for updating the user
            // as well
            self.sessionProfile = $resource($m42AcsHost + '/api/session/profile', {}, {
                get:             { method: 'GET', headers: defaultHeaders },
                empty:           { method: 'GET', url: $m42AcsHost + '/api/session/profile/empty', headers: defaultHeaders },
                avatarLocationW: { method: 'GET', url: $m42AcsHost + '/api/session/profile/avatar', params: {rights: 'w'}, headers: defaultHeaders },
                update:          { method: 'PUT', headers: defaultHeaders }
            });

            // The session profile for the enterprise can be used to get information about the
            // associated enterprise profile the user is part of.
            self.sessionEnterpriseProfile = $resource($m42AcsHost + '/api/session/enterprise', {}, {
                get: { method: 'GET', headers: defaultHeaders }
            });

            self.sessionApplications = $resource($m42AcsHost + '/api/session/applications', {}, {
                get: { method: 'GET', isArray: true, headers: defaultHeaders }
            });

            self.sessionTokens = $resource($m42AcsHost + '/api/session/tokens', {}, {
                get:    { method: 'GET', isArray: true, headers: defaultHeaders },
                create: { method: 'POST', headers: defaultHeaders },
                delete: { method: 'DELETE', url: $m42AcsHost + '/api/session/tokens/:id', headers: defaultHeaders }
            });

            self.role = $resource($m42AcsHost + 'api/role', {}, {
                getAccounts: { method: 'GET', url: $m42AcsHost + 'api/role/:id/accounts', isArray: true, headers: defaultHeaders }
            });

            // The account endpoint is mainly used to manage user accounts in the admin mode. The caller
            // should be the managed of an enterprise the target user is part of or a global admin.
            self.account = $resource($m42AcsHost + '/api/account/:id', {}, {
                get:                   { method: 'GET', headers: defaultHeaders },
                lostPassword:          { method: 'POST', url: $m42AcsHost + '/api/account/:id/LostPassword'},
                resetPassword:         { method: 'PUT',  url: $m42AcsHost + '/api/account/:id/resetPassword', params: {returnUrl: ':returnUrl' }},
                getSingleUsers:        { method: 'GET',  url: $m42AcsHost + '/api/account?enterprise=0', isArray: true, headers: defaultHeaders },
                getUsersForEnterprise: { method: 'GET',  url: $m42AcsHost + '/api/account?enterprise=:id', isArray: true, headers: defaultHeaders },
                getRoles:              { method: 'GET',  url: $m42AcsHost + '/api/account/:id/roles', isArray: true, headers: defaultHeaders },
                setRoles:              { method: 'PUT',  url: $m42AcsHost + '/api/account/:id/roles', headers: defaultHeaders },
                update:                { method: 'PUT', headers: defaultHeaders },
                reactivate:            { method: 'PUT',  url: $m42AcsHost + '/api/account/:id/reactivate', headers: defaultHeaders },
                register:              { method: 'POST', url: $m42AcsHost + '/api/account', interceptor: interceptorGetUrlFromLocationHeader() },
                avatarLocationR:       { method: 'GET',  url: $m42AcsHost + '/api/account/:id/avatar', params: {rights: 'r'}, headers: defaultHeaders },
                promote:               { method: 'PUT',  url: $m42AcsHost + '/api/accounts/:id/promote', headers: defaultHeaders}
            });

            self.enterprise = $resource($m42AcsHost + '/api/enterprises/:id', {}, {
                avatarLocationW:      { method: 'GET', url: $m42AcsHost + '/api/enterprises/:id/avatar', params: {rights: 'w'}, headers: defaultHeaders },
                avatarLocationR:      { method: 'GET', url: $m42AcsHost + '/api/enterprises/:id/avatar', params: {rights: 'r'}, headers: defaultHeaders },
                validateMailDomain:   { method: 'GET', url: $m42AcsHost + '/api/enterprises/validate/emaildomain/:id', headers: defaultHeaders, transformResponse: transformResponseCallbackEncapsulateStringResponse() },
                getCompanyNameByMail: { method: 'GET', url: $m42AcsHost + '/api/enterprises/name/:id', headers: defaultHeaders, transformResponse: transformResponseCallbackEncapsulateStringResponse() },
                update:               { method: 'PUT', headers: defaultHeaders },

                accounts: { method: 'GET',    url: $m42AcsHost + '/api/enterprises/:id/accounts', isArray: true, headers: defaultHeaders },
                leave:    { method: 'DELETE', url: $m42AcsHost + '/api/enterprises/:id/accounts/:user', headers: defaultHeaders },
                invite:   { method: 'POST',   url: $m42AcsHost + '/api/enterprises/:id/invite', headers: defaultHeaders },

                createEnterpriseSpecificRole:           { method: 'POST',   url: $m42AcsHost + '/api/enterprise/:id/roles', headers: defaultHeaders, interceptor: interceptorGetNewIdFromLocationHeader() },
                assignAccountsToEnterpriseSpecificRole: { method: 'POST',   url: $m42AcsHost + '/api/enterprise/:id/roles/:roleId', headers: defaultHeaders },
                deleteAccountsToEnterpriseSpecificRole: { method: 'DELETE', url: $m42AcsHost + '/api/enterprise/:id/roles/:roleId', headers: defaultHeaders },
                getEnterpriseSpecificRoleDetails:       { method: 'GET',    url: $m42AcsHost + '/api/enterprise/:id/roles/:roleId', headers: defaultHeaders },
                roles:                                  { method: 'GET',    url: $m42AcsHost + '/api/enterprise/:id/roles', isArray: true, headers: defaultHeaders },

                memberships: { method: 'GET',    url: $m42AcsHost + '/api/enterprises/:id/roles', isArray: true, headers: defaultHeaders },

                emaildomains: { method: 'GET', url: $m42AcsHost + '/api/enterprises/:id/emaildomains', isArray: true, headers: defaultHeaders },

                applications:         { method: 'GET',    url: $m42AcsHost + '/api/enterprises/:id/applications', isArray: true, headers: defaultHeaders },
                applicationById:      { method: 'GET',    url: $m42AcsHost + '/api/enterprises/:id/applications/:applicationId', headers: defaultHeaders },
                applicationByIdIconW: { method: 'GET',    url: $m42AcsHost + '/api/enterprises/:id/applications/:applicationId/icon', params: {rights: 'w'}, headers: defaultHeaders },
                createApplication:    { method: 'POST',   url: $m42AcsHost + '/api/enterprises/:id/applications', headers: defaultHeaders, interceptor: interceptorGetNewIdFromLocationHeader() },
                updateApplication:    { method: 'PUT',    url: $m42AcsHost + '/api/enterprises/:id/applications/:applicationId', headers: defaultHeaders },
                deleteApplication:    { method: 'DELETE', url: $m42AcsHost + '/api/enterprises/:id/applications/:applicationId', headers: defaultHeaders },

                protocolClients:                   { method: 'GET',    url: $m42AcsHost + '/api/enterprise/:id/protocolclients', isArray: true, headers: defaultHeaders },
                protocolClientById:                { method: 'GET',    url: $m42AcsHost + '/api/enterprise/:id/protocolclients/:protocolClientId', headers: defaultHeaders },
                createProtocolClient:              { method: 'POST',   url: $m42AcsHost + '/api/enterprise/:id/protocolclients', headers: defaultHeaders, interceptor: interceptorGetNewIdFromLocationHeader() },
                updateProtocolClient:              { method: 'PUT',    url: $m42AcsHost + '/api/enterprise/:id/protocolclients/:protocolClientId', headers: defaultHeaders },
                deleteProtocolClient:              { method: 'DELETE', url: $m42AcsHost + '/api/enterprise/:id/protocolclients/:protocolClientId', headers: defaultHeaders },
                generateProtocolClientCertificate: { method: 'POST',   url: $m42AcsHost + '/api/enterprise/:id/protocolclients/:protocolClientId/certificate', headers: defaultHeaders },

                getEnterpriseOAuth2Clients: { method: 'GET',   url: $m42AcsHost + '/api/enterprises/:id/oauth2/clients', isArray: true, headers: defaultHeaders },
                deleteOAuth2ClientById: { method: 'DELETE',   url: $m42AcsHost + '/api/enterprises/:id/oauth2/clients/:clientId', headers: defaultHeaders }
            });

            self.metadata = $resource($m42AcsHost + '/api/metadata', {}, {
                forProtocolClients: { method: 'GET', url: $m42AcsHost + '/api/metadata', params: {filter: 'protocolclients'}, headers: defaultHeaders }
            });

            self.export = $resource($m42AcsHost + '/api/export', {}, {
                accounts:    { method: 'GET', url: $m42AcsHost + '/api/export/accounts?createdAfter=:createdAfter&format=csv', headers: defaultHeaders, transformResponse: transformResponseCallbackEncapsulateStringResponse() },
                enterprises: { method: 'GET', url: $m42AcsHost + '/api/export/enterprises?createdAfter=:createdAfter&format=csv', headers: defaultHeaders, transformResponse: transformResponseCallbackEncapsulateStringResponse() }
            });
        }]);
