'use strict';

/**
 * @ngdoc function
 * @name m42AccessControlServiceFrontendApp.controller:RegisterCtrl
 * @description
 * # RegisterCtrl
 * Controller of the m42AccessControlServiceFrontendApp
 */
angular.module('m42AccessControlServiceFrontendApp').controller('RegisterCtrl', [
    '$scope', 'M42Validation', 'M42Helpers', '$location', '$busy', 'M42Translation', '$m42Countries', 'mxAuthAcsSessionClient', '$m42AcsBackend', '$timeout', '$window', '$m42AcsHost',
    function ($scope, M42Validation, M42Helpers, $location, $busy, M42Translation, $m42Countries, mxAuthAcsSessionClient, $m42AcsBackend, $timeout, $window, $m42AcsHost) {

        // Redirect the user when a session is already established
        if (mxAuthAcsSessionClient.getActiveSession()) {
            $location.path('/');
        } else {
            var userName = M42Helpers.parseQueryString($location.url(), true).username;
            var invitationToken = M42Helpers.parseQueryString($location.url()).invitation;
            var returnUrl = M42Helpers.parseQueryString($location.url(), true).ReturnUrl;

            $scope.user = {};

            $scope.isEmailDomainAvailable = false;
            $scope.originalEmail = '';

            $scope.companyNamePrevVersion = '';

            $scope.user.IsCreateEnterpriseAccount = false;
            $scope.userAcceptedPolicy = false;

            $scope.allLanguages = M42Translation.getAvailableLanguages();
            $timeout(function () {
                $scope.user.CultureString = M42Translation.getCurrentLanguage();
            }, 10);

            var isInvited = false;

            if (userName !== undefined && invitationToken !== undefined && !M42Validation.isEmpty(userName) && !M42Validation.isEmpty(invitationToken)) {
                $scope.user.MailAddress = userName;
                $scope.user.InvitationToken = invitationToken;
                $scope.user.CreationType = 20; //Invited to Enterprise
                isInvited = true;
            }

            $scope.passwordConfirm = null;
            $scope.registeredSuccessfully = false;
            $scope.httpCode = 200;
            $scope.errorMessage = null;

            $scope.isInvitedToEnterprise = function () {
                return isInvited;
            };

            $scope.isEmpty = function (val) {
                return M42Validation.isEmpty(val);
            };

            $scope.getEmailDomain = function (email) {
                var emailDomain = '';

                if (!M42Validation.isEmpty(email)) {
                    var index = email.indexOf('@');

                    if (index > 0) {
                        emailDomain = email.substr(index + 1, email.length - index);
                    }
                }

                return emailDomain;
            };

            // Calls API to check is email domain available - it means that if for some company
            // there is the same email domain that was assigned to it then we shouldn't allow to create an account and company
            // using that email domain.
            $scope.isEmailDomainAvailableAtServer = function (emailDomain) {
                $m42AcsBackend.enterprise.validateMailDomain({id: emailDomain},
                    function (result) {

                        // The API returns true or false as single JSON value :-(
                        $scope.isEmailDomainAvailable = (result.data == 'true');

                        // check what the result
                        if (!$scope.isEmailDomainAvailable) {
                            $scope.user.IsCreateEnterpriseAccount = false;
                        }
                    },
                    function () {
                        // do nothing just catch the exception
                    });
            };

            // Calls API to get company name based on account email domain.
            $scope.setCompanyNameByEmail = function (emailDomain) {

                if (!M42Validation.isEmpty(emailDomain)) {

                    $m42AcsBackend.enterprise.getCompanyNameByMail({id: emailDomain}).$promise.then(function (result) {
                        $scope.user.CompanyName = result.data;
                        $scope.companyNamePrevVersion = $scope.user.CompanyName;
                    }).catch(function () {
                        // do nothing just catch the exception
                    });
                }
            };

            $scope.companyNameChanged = function () {

                //If user cleaned the CompanyName field, then try to autogenerate the CompanyName from email domain.
                if (M42Validation.isEmpty($scope.user.CompanyName)) {
                    var emailDomain = $scope.getEmailDomain($scope.user.MailAddress);
                    $scope.setCompanyNameByEmail(emailDomain);
                }
            };

            $scope.emailChanged = function () {

                //We don't want to invoke API if the email changed but domain left the same.
                if ($scope.originalEmail !== $scope.user.MailAddress) {
                    //Grab email domain from mail address
                    var emailDomain = $scope.getEmailDomain($scope.user.MailAddress);
                    var originalEmailDomain = $scope.getEmailDomain($scope.originalEmail);

                    if (emailDomain !== originalEmailDomain) {
                        if (!M42Validation.isEmpty(emailDomain)) {

                            $scope.isEmailDomainAvailableAtServer(emailDomain);

                            if ($scope.companyNamePrevVersion === $scope.user.CompanyName || $scope.user.CompanyName === undefined || M42Validation.isEmpty($scope.user.CompanyName)) {

                                $scope.setCompanyNameByEmail(emailDomain);
                            }
                        }

                        $scope.originalEmail = $scope.user.MailAddress;
                    }
                }
            };

            $scope.isValid = function () {

                // first check all mandatory fields
                var isValid = (
                !M42Validation.isEmpty($scope.user.Salutation) && !M42Validation.isEmpty($scope.user.FirstName) && !M42Validation.isEmpty($scope.user.LastName) && !M42Validation.isEmpty($scope.user.MailAddress) && !M42Validation.isEmpty($scope.user.Password) && !M42Validation.isEmpty($scope.passwordConfirm) &&
                $scope.user.Password === $scope.passwordConfirm &&
                $scope.userAcceptedPolicy === true);

                if (!isValid) {
                    return false;
                }

                // check specific values when we are not part of an enterprise
                if (!$scope.user.IsCreateEnterpriseAccount && !$scope.isInvitedToEnterprise()) {
                    isValid = !M42Validation.isEmpty($scope.user.CompanyName);
                }

                if (!isValid) {
                    return false;
                }

                // check attributes when user creating enterprise
                if ($scope.user.IsCreateEnterpriseAccount) {
                    isValid = !M42Validation.isEmpty($scope.user.Street) && !M42Validation.isEmpty($scope.user.ZipCode) && !M42Validation.isEmpty($scope.user.City) && !M42Validation.isEmpty($scope.user.Country);
                }

                if (!isValid) {
                    return false;
                }

                // good to go
                return isValid;
            };

            $scope.saveEntity = function () {
                if (returnUrl) {
                    $scope.user.ActivationHandler = returnUrl;
                } else {
                    $scope.user.ActivationHandler = $m42AcsHost + '/my/#/?lang=' + $scope.user.CultureString;
                }

                //User will be joined to company and will have the company name.
                if ($scope.user.IsCreateEnterpriseAccount) {
                    $scope.user.CompanyName = null;
                }

                $busy.setMessage('');

                $busy.during($m42AcsBackend.account.register({}, $scope.user).$promise.then(function (redirectUri) {
                    $scope.registeredSuccessfully = true;
                    $scope.httpCode = 200;
                    $scope.errorMessage = null;

                    if (redirectUri) {
                        $window.location = redirectUri;
                    }
                }).catch(function (response) {
                    $scope.httpCode = response.status;
                    $scope.errorMessage = response.statusText;
                }));
            };

            // assign all countries
            $scope.allCountries = $m42Countries.getSimpleCountryList();
        }
    }
]);
