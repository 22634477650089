'use strict';

/**
 * @ngdoc service
 * @name m42AccessControlServiceFrontendApp.m42Countries
 * @description
 * # m42Countries
 * Service in the m42AccessControlServiceFrontendApp.
 */
angular.module('m42AccessControlServiceFrontendApp').service('$m42Countries', [function m42Countries() {
    var self = this;

    self.findCountryByCode = function(code) {
        for (var prop in self.all) {
            if (self.all.hasOwnProperty(prop)) {
                if (self.all[prop].alpha2 === code || self.all[prop].alpha3 === code) {
                    return self.all[prop];
                }
            }
        }
    };

    self.findCountryByNumber = function(num) {
        num = parseInt(num, 10);
        for (var prop in self.all) {
            if (self.all.hasOwnProperty(prop)) {
                if (parseInt(self.all[prop].number, 10) === num) {
                    return self.all[prop];
                }
            }
        }
    };

    self.findCountryByName = function(name) {
        for (var prop in self.all) {
            if (self.all.hasOwnProperty(prop)) {
                if (self.all[prop].name === name) {
                    return self.all[prop];
                } else if (self.all[prop].names && self.all[prop].names.indexOf(name) > -1) {
                    return self.all[prop];
                }
            }
        }
    };

    self.findCountriesByRegion = function(region) {
        var results = [];

        for (var prop in self.all) {
            if (self.all.hasOwnProperty(prop)) {
                if (self.all[prop].region === region) {
                    results.push(self.all[prop]);
                }
            }
        }
        if (!results.length) {
            return undefined;
        }
        return results;
    };

    self.getSimpleCountryList = function() {

        var results = [];

        for(var prop in self.all) {

            results.push({
                value: self.all[prop].value,
                name: 'Countries.' + self.all[prop].value
            });
        }

        return results.sort(function(a,b) {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        });
    };

    self.getAllISOCodes = function() {
        return Object.keys(self.all).sort();
    };

    self.all = {
        'AD': {
            'value': 'AD',
            'name': 'Andorra',
            'names': [
                'Andorre',
                'Andorra'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'AD',
            'alpha3': 'AND',
            'ioc': 'AND',
            'number': '020',
            'tel': '376',
            'latitude': '42 30 N',
            'longitude': '1 30 E',
            'un': 'AD'
        },
        'AE': {
            'value': 'AE',
            'name': 'United Arab Emirates',
            'names': [
                'United Arab Emirates',
                'Vereinigte Arabische Emirate',
                'Émirats Arabes Unis',
                'Emiratos Árabes Unidos'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'AED',
            'alpha2': 'AE',
            'alpha3': 'ARE',
            'ioc': 'UAE',
            'number': '784',
            'tel': '971',
            'latitude': '24 00 N',
            'longitude': '54 00 E',
            'un': 'AE'
        },
        'AF': {
            'value': 'AF',
            'name': 'Afghanistan',
            'names': [
                'Afghanistan',
                'Afganistán'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'AFN',
            'alpha2': 'AF',
            'alpha3': 'AFG',
            'ioc': 'AFG',
            'number': '004',
            'tel': '93',
            'latitude': '33 00 N',
            'longitude': '65 00 E',
            'un': 'AF'
        },
        'AG': {
            'value': 'AG',
            'name': 'Antigua and Barbuda',
            'names': [
                'Antigua and Barbuda',
                'Antigua und Barbuda',
                'Antigua et Barbuda',
                'Antigua y Barbuda'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'XCD',
            'alpha2': 'AG',
            'alpha3': 'ATG',
            'ioc': 'ANT',
            'number': '028',
            'tel': '1',
            'latitude': '17 03 N',
            'longitude': '61 48 W',
            'un': 'AG'
        },
        'AI': {
            'value': 'AI',
            'name': 'Anguilla',
            'names': [
                'Anguilla'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'XCD',
            'alpha2': 'AI',
            'alpha3': 'AIA',
            'ioc': null,
            'number': '660',
            'tel': '1',
            'latitude': '18 15 N',
            'longitude': '63 10 W',
            'un': 'AI'
        },
        'AL': {
            'value': 'AL',
            'name': 'Albania',
            'names': [
                'Albania',
                'Albanien',
                'Albanie'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'ALL',
            'alpha2': 'AL',
            'alpha3': 'ALB',
            'ioc': 'ALB',
            'number': '008',
            'tel': '355',
            'latitude': '41 00 N',
            'longitude': '20 00 E',
            'un': 'AL'
        },
        'AM': {
            'value': 'AM',
            'name': 'Armenia',
            'names': [
                'Armenia',
                'Armenien',
                'Arménie'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'AMD',
            'alpha2': 'AM',
            'alpha3': 'ARM',
            'ioc': 'ARM',
            'number': '051',
            'tel': '374',
            'latitude': '40 00 N',
            'longitude': '45 00 E',
            'un': 'AM'
        },
        'AN': {
            'value': 'AN',
            'name': 'Netherlands Antilles',
            'names': [
                'Netherlands Antilles',
                'Niederländische Antillen',
                'Antilles néerlandaises',
                'Antillas Holandesas'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'ANG',
            'alpha2': 'AN',
            'alpha3': 'ANT',
            'ioc': 'AHO',
            'number': '530',
            'tel': '599',
            'latitude': '',
            'longitude': '',
            'un': 'AN'
        },
        'AO': {
            'value': 'AO',
            'name': 'Angola',
            'names': [
                'Angola'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': 'AOA',
            'alpha2': 'AO',
            'alpha3': 'AGO',
            'ioc': 'ANG',
            'number': '024',
            'tel': '244',
            'latitude': '12 30 S',
            'longitude': '18 30 E',
            'un': 'AO'
        },
        'AQ': {
            'value': 'AQ',
            'name': 'Antarctica',
            'names': [
                'Antarctica',
                'Antarktis',
                'Antarctique',
                'Antártida'
            ],
            'region': '',
            'subregion': '',
            'currency': null,
            'alpha2': 'AQ',
            'alpha3': 'ATA',
            'ioc': null,
            'number': '010',
            'tel': '672',
            'latitude': '90 00 S',
            'longitude': '0 00 E',
            'un': 'AQ'
        },
        'AR': {
            'value': 'AR',
            'name': 'Argentina',
            'names': [
                'Argentina',
                'Argentinien',
                'Argentine'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'ARS',
            'alpha2': 'AR',
            'alpha3': 'ARG',
            'ioc': 'ARG',
            'number': '032',
            'tel': '54',
            'latitude': '34 00 S',
            'longitude': '64 00 W',
            'un': 'AR'
        },
        'AS': {
            'value': 'AS',
            'name': 'American Samoa',
            'names': [
                'American Samoa',
                'Amerikanisch-Samoa',
                'Samoa américaines',
                'Samoa Americana'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'USD',
            'alpha2': 'AS',
            'alpha3': 'ASM',
            'ioc': 'ASA',
            'number': '016',
            'tel': '1',
            'latitude': '14 20 S',
            'longitude': '170 00 W',
            'un': 'AS'
        },
        'AT': {
            'value': 'AT',
            'name': 'Austria',
            'names': [
                'Österreich',
                'Autriche',
                'Austria'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'EUR',
            'alpha2': 'AT',
            'alpha3': 'AUT',
            'ioc': 'AUT',
            'number': '040',
            'tel': '43',
            'latitude': '47 20 N',
            'longitude': '13 20 E',
            'un': 'AT'
        },
        'AU': {
            'value': 'AU',
            'name': 'Australia',
            'names': [
                'Australien',
                'Australie',
                'Australia'
            ],
            'region': 'Oceania',
            'subregion': 'Australia and New Zealand',
            'currency': 'AUD',
            'alpha2': 'AU',
            'alpha3': 'AUS',
            'ioc': 'AUS',
            'number': '036',
            'tel': '61',
            'latitude': '27 00 S',
            'longitude': '133 00 E',
            'un': 'AU'
        },
        'AW': {
            'value': 'AW',
            'name': 'Aruba',
            'names': [
                'Aruba'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'AWG',
            'alpha2': 'AW',
            'alpha3': 'ABW',
            'ioc': 'ARU',
            'number': '533',
            'tel': '297',
            'latitude': '12 30 N',
            'longitude': '69 58 W',
            'un': 'AW'
        },
        'AX': {
            'value': 'AX',
            'name': 'Åland Islands',
            'names': [
                'Åland Islands'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': null,
            'alpha2': 'AX',
            'alpha3': 'ALA',
            'ioc': null,
            'number': '248',
            'tel': '358',
            'latitude': '',
            'longitude': '',
            'un': null
        },
        'AZ': {
            'value': 'AZ',
            'name': 'Azerbaijan',
            'names': [
                'Azerbaijan',
                'Aserbaidschan',
                'Azerbaïdjan',
                'Azerbaiyán'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'AZN',
            'alpha2': 'AZ',
            'alpha3': 'AZE',
            'ioc': 'AZE',
            'number': '031',
            'tel': '994',
            'latitude': '40 30 N',
            'longitude': '47 30 E',
            'un': 'AZ'
        },
        'BA': {
            'value': 'BA',
            'name': 'Bosnia and Herzegovina',
            'names': [
                'Bosnia and Herzegovina',
                'Bosnien und Herzegowina',
                'Bosnie et Herzégovine',
                'Bosnia y Herzegovina'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'BAM',
            'alpha2': 'BA',
            'alpha3': 'BIH',
            'ioc': 'BIH',
            'number': '070',
            'tel': '387',
            'latitude': '44 00 N',
            'longitude': '18 00 E',
            'un': 'BA'
        },
        'BB': {
            'value': 'BB',
            'name': 'Barbados',
            'names': [
                'Barbade',
                'Barbados'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'BBD',
            'alpha2': 'BB',
            'alpha3': 'BRB',
            'ioc': 'BAR',
            'number': '052',
            'tel': '1',
            'latitude': '13 10 N',
            'longitude': '59 32 W',
            'un': 'BB'
        },
        'BD': {
            'value': 'BD',
            'name': 'Bangladesh',
            'names': [
                'Bangladesh',
                'Bangladesch'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'BTD',
            'alpha2': 'BD',
            'alpha3': 'BGD',
            'ioc': 'BAN',
            'number': '050',
            'tel': '880',
            'latitude': '24 00 N',
            'longitude': '90 00 E',
            'un': 'BD'
        },
        'BE': {
            'value': 'BE',
            'name': 'Belgium',
            'names': [
                'Belgium',
                'Belgien',
                'Belgique',
                'Bélgica'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'EUR',
            'alpha2': 'BE',
            'alpha3': 'BEL',
            'ioc': 'BEL',
            'number': '056',
            'tel': '32',
            'latitude': '50 50 N',
            'longitude': '4 00 E',
            'un': 'BE'
        },
        'BF': {
            'value': 'BF',
            'name': 'Burkina Faso',
            'names': [
                'Burkina Faso'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'XOF',
            'alpha2': 'BF',
            'alpha3': 'BFA',
            'ioc': 'BUR',
            'number': '854',
            'tel': '226',
            'latitude': '13 00 N',
            'longitude': '2 00 W',
            'un': 'BF'
        },
        'BG': {
            'value': 'BG',
            'name': 'Bulgaria',
            'names': [
                'Bulgaria',
                'Bulgarien',
                'Bulgarie'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'BGN',
            'alpha2': 'BG',
            'alpha3': 'BGR',
            'ioc': 'BUL',
            'number': '100',
            'tel': '359',
            'latitude': '43 00 N',
            'longitude': '25 00 E',
            'un': 'BG'
        },
        'BH': {
            'value': 'BH',
            'name': 'Bahrain',
            'names': [
                'Bahrain',
                'Bahreïn',
                'Bahrein'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'BHD',
            'alpha2': 'BH',
            'alpha3': 'BHR',
            'ioc': 'BRN',
            'number': '048',
            'tel': '973',
            'latitude': '26 00 N',
            'longitude': '50 33 E',
            'un': 'BH'
        },
        'BI': {
            'value': 'BI',
            'name': 'Burundi',
            'names': [
                'Burundi'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'BIF',
            'alpha2': 'BI',
            'alpha3': 'BDI',
            'ioc': 'BDI',
            'number': '108',
            'tel': '257',
            'latitude': '3 30 S',
            'longitude': '30 00 E',
            'un': 'BI'
        },
        'BJ': {
            'value': 'BJ',
            'name': 'Benin',
            'names': [
                'Benin',
                'Bénin'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'XOF',
            'alpha2': 'BJ',
            'alpha3': 'BEN',
            'ioc': 'BEN',
            'number': '204',
            'tel': '229',
            'latitude': '9 30 N',
            'longitude': '2 15 E',
            'un': 'BJ'
        },
        'BL': {
            'value': 'BL',
            'name': 'Saint Barthélemy',
            'names': [
                'Saint Barthélemy'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': null,
            'alpha2': 'BL',
            'alpha3': 'BLM',
            'ioc': null,
            'number': '652',
            'tel': '590',
            'latitude': '17 90 N',
            'longitude': '62 85 W',
            'un': null
        },
        'BM': {
            'value': 'BM',
            'name': 'Bermuda',
            'names': [
                'Bermuda',
                'Bermudes',
                'Bermudas'
            ],
            'region': 'Americas',
            'subregion': 'Northern America',
            'currency': 'BMD',
            'alpha2': 'BM',
            'alpha3': 'BMU',
            'ioc': 'BER',
            'number': '060',
            'tel': '1',
            'latitude': '32 20 N',
            'longitude': '64 45 W',
            'un': 'BM'
        },
        'BN': {
            'value': 'BN',
            'name': 'Brunei Darussalam',
            'names': [
                'Brunei'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'BND',
            'alpha2': 'BN',
            'alpha3': 'BRN',
            'ioc': 'BRU',
            'number': '096',
            'tel': '673',
            'latitude': '4 30 N',
            'longitude': '114 40 E',
            'un': 'BN'
        },
        'BO': {
            'value': 'BO',
            'name': 'Bolivia',
            'names': [
                'Bolivia',
                'Bolivien',
                'Bolivie'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'BOB',
            'alpha2': 'BO',
            'alpha3': 'BOL',
            'ioc': 'BOL',
            'number': '068',
            'tel': '591',
            'latitude': '17 00 S',
            'longitude': '65 00 W',
            'un': 'BO'
        },
        'BR': {
            'value': 'BR',
            'name': 'Brazil',
            'names': [
                'Brazil',
                'Brasilien',
                'Brésil',
                'Brasil'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'BRL',
            'alpha2': 'BR',
            'alpha3': 'BRA',
            'ioc': 'BRA',
            'number': '076',
            'tel': '55',
            'latitude': '10 00 S',
            'longitude': '55 00 W',
            'un': 'BR'
        },
        'BS': {
            'value': 'BS',
            'name': 'Bahamas',
            'names': [
                'Bahamas'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'BSD',
            'alpha2': 'BS',
            'alpha3': 'BHS',
            'ioc': 'BAH',
            'number': '044',
            'tel': '1',
            'latitude': '24 15 N',
            'longitude': '76 00 W',
            'un': 'BS'
        },
        'BT': {
            'value': 'BT',
            'name': 'Bhutan',
            'names': [
                'Bhutan',
                'Bhoutan',
                'Bután'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'BTN',
            'alpha2': 'BT',
            'alpha3': 'BTN',
            'ioc': 'BHU',
            'number': '064',
            'tel': '975',
            'latitude': '27 30 N',
            'longitude': '90 30 E',
            'un': 'BT'
        },
        'BV': {
            'value': 'BV',
            'name': 'Bouvet Island',
            'names': [
                'Bouvet Island'
            ],
            'region': '',
            'subregion': '',
            'currency': 'NOK',
            'alpha2': 'BV',
            'alpha3': 'BVT',
            'ioc': null,
            'number': '074',
            'tel': '',
            'latitude': '54 26 S',
            'longitude': '3 24 E',
            'un': null
        },
        'BW': {
            'value': 'BW',
            'name': 'Botswana',
            'names': [
                'Botswana'
            ],
            'region': 'Africa',
            'subregion': 'Southern Africa',
            'currency': 'BWP',
            'alpha2': 'BW',
            'alpha3': 'BWA',
            'ioc': 'BOT',
            'number': '072',
            'tel': '267',
            'latitude': '22 00 S',
            'longitude': '24 00 E',
            'un': 'BW'
        },
        'BY': {
            'value': 'BY',
            'name': 'Belarus',
            'names': [
                'Belarus',
                'Weißrussland',
                'Biélorussie',
                'Bielorrusia'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'BYR',
            'alpha2': 'BY',
            'alpha3': 'BLR',
            'ioc': 'BLR',
            'number': '112',
            'tel': '375',
            'latitude': '53 00 N',
            'longitude': '28 00 E',
            'un': 'BY'
        },
        'BZ': {
            'value': 'BZ',
            'name': 'Belize',
            'names': [
                'Belize',
                'Belice'
            ],
            'region': 'Americas',
            'subregion': 'Central America',
            'currency': 'BZD',
            'alpha2': 'BZ',
            'alpha3': 'BLZ',
            'ioc': 'BIZ',
            'number': '084',
            'tel': '501',
            'latitude': '17 15 N',
            'longitude': '88 45 W',
            'un': 'BZ'
        },
        'CA': {
            'value': 'CA',
            'name': 'Canada',
            'names': [
                'Canada',
                'Kanada',
                'Canadá'
            ],
            'region': 'Americas',
            'subregion': 'Northern America',
            'currency': 'CAD',
            'alpha2': 'CA',
            'alpha3': 'CAN',
            'ioc': 'CAN',
            'number': '124',
            'tel': '1',
            'latitude': '60 00 N',
            'longitude': '95 00 W',
            'un': 'CA'
        },
        'CC': {
            'value': 'CC',
            'name': 'Cocos (Keeling) Islands',
            'names': [
                'Cocos (Keeling) Islands'
            ],
            'region': 'Oceania',
            'subregion': 'Australia and New Zealand',
            'currency': 'AUD',
            'alpha2': 'CC',
            'alpha3': 'CCK',
            'ioc': null,
            'number': '166',
            'tel': '61',
            'latitude': '12 30 S',
            'longitude': '96 50 E',
            'un': 'CC'
        },
        'CD': {
            'value': 'CD',
            'name': 'Congo, The Democratic Republic Of The',
            'names': [
                'Congo (Dem. Rep.)',
                'Kongo (Dem. Rep.)',
                'Congo (Rep. Dem.)',
                'Congo (Rep. Dem.)'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': null,
            'alpha2': 'CD',
            'alpha3': 'COD',
            'ioc': 'COD',
            'number': '180',
            'tel': '243',
            'latitude': '0 00 N',
            'longitude': '25 00 E',
            'un': 'CD'
        },
        'CF': {
            'value': 'CF',
            'name': 'Central African Republic',
            'names': [
                'Central African Republic',
                'Zentralafrikanische Republik',
                'République Centrafricaine',
                'República Centroafricana'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': 'XAF',
            'alpha2': 'CF',
            'alpha3': 'CAF',
            'ioc': 'CAF',
            'number': '140',
            'tel': '236',
            'latitude': '7 00 N',
            'longitude': '21 00 E',
            'un': 'CF'
        },
        'CG': {
            'value': 'CG',
            'name': 'Congo',
            'names': [
                'Congo',
                'Kongo'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': null,
            'alpha2': 'CG',
            'alpha3': 'COG',
            'ioc': 'CGO',
            'number': '178',
            'tel': '242',
            'latitude': '1 00 S',
            'longitude': '15 00 E',
            'un': 'CG'
        },
        'CH': {
            'value': 'CH',
            'name': 'Switzerland',
            'names': [
                'Switzerland',
                'Schweiz',
                'Suisse',
                'Suiza'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'CHF',
            'alpha2': 'CH',
            'alpha3': 'CHE',
            'ioc': 'SUI',
            'number': '756',
            'tel': '41',
            'latitude': '47 00 N',
            'longitude': '8 00 E',
            'un': 'CH'
        },
        'CI': {
            'value': 'CI',
            'name': 'Côte D\'Ivoire',
            'names': [
                'Côte D\'Ivoire'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'XOF',
            'alpha2': 'CI',
            'alpha3': 'CIV',
            'ioc': 'CIV',
            'number': '384',
            'tel': '225',
            'latitude': '8 00 N',
            'longitude': '5 00 W',
            'un': 'CI'
        },
        'CK': {
            'value': 'CK',
            'name': 'Cook Islands',
            'names': [
                'Cook Islands',
                'Cookinseln',
                'Îles Cook',
                'Islas Cook'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'NZD',
            'alpha2': 'CK',
            'alpha3': 'COK',
            'ioc': 'COK',
            'number': '184',
            'tel': '682',
            'latitude': '21 14 S',
            'longitude': '159 46 W',
            'un': 'CK'
        },
        'CL': {
            'value': 'CL',
            'name': 'Chile',
            'names': [
                'Chile'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'CLP',
            'alpha2': 'CL',
            'alpha3': 'CHL',
            'ioc': 'CHI',
            'number': '152',
            'tel': '56',
            'latitude': '30 00 S',
            'longitude': '71 00 W',
            'un': 'CL'
        },
        'CM': {
            'value': 'CM',
            'name': 'Cameroon',
            'names': [
                'Cameroon',
                'Kamerun',
                'Cameroun',
                'Camerún'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': 'XAF',
            'alpha2': 'CM',
            'alpha3': 'CMR',
            'ioc': 'CMR',
            'number': '120',
            'tel': '237',
            'latitude': '6 00 N',
            'longitude': '12 00 E',
            'un': 'CM'
        },
        'CN': {
            'value': 'CN',
            'name': 'China',
            'names': [
                'China',
                'Chine'
            ],
            'region': 'Asia',
            'subregion': 'Eastern Asia',
            'currency': 'CNY',
            'alpha2': 'CN',
            'alpha3': 'CHN',
            'ioc': 'CHN',
            'number': '156',
            'tel': '86',
            'latitude': '35 00 N',
            'longitude': '105 00 E',
            'un': 'CN'
        },
        'CO': {
            'value': 'CO',
            'name': 'Colombia',
            'names': [
                'Colombia',
                'Kolumbien',
                'Colombie'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'COP',
            'alpha2': 'CO',
            'alpha3': 'COL',
            'ioc': 'COL',
            'number': '170',
            'tel': '57',
            'latitude': '4 00 N',
            'longitude': '72 00 W',
            'un': 'CO'
        },
        'CR': {
            'value': 'CR',
            'name': 'Costa Rica',
            'names': [
                'Costa Rica'
            ],
            'region': 'Americas',
            'subregion': 'Central America',
            'currency': 'CRC',
            'alpha2': 'CR',
            'alpha3': 'CRI',
            'ioc': 'CRC',
            'number': '188',
            'tel': '506',
            'latitude': '10 00 N',
            'longitude': '84 00 W',
            'un': 'CR'
        },
        'CU': {
            'value': 'CU',
            'name': 'Cuba',
            'names': [
                'Cuba',
                'Kuba'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'CUP',
            'alpha2': 'CU',
            'alpha3': 'CUB',
            'ioc': 'CUB',
            'number': '192',
            'tel': '53',
            'latitude': '21 30 N',
            'longitude': '80 00 W',
            'un': 'CU'
        },
        'CV': {
            'value': 'CV',
            'name': 'Cape Verde',
            'names': [
                'Cape Verde',
                'Kap Verde',
                'Cap Vert',
                'Cabo Verde'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'CVE',
            'alpha2': 'CV',
            'alpha3': 'CPV',
            'ioc': 'CPV',
            'number': '132',
            'tel': '238',
            'latitude': '16 00 N',
            'longitude': '24 00 W',
            'un': 'CV'
        },
        'CX': {
            'value': 'CX',
            'name': 'Christmas Island',
            'names': [
                'Christmas Island'
            ],
            'region': 'Oceania',
            'subregion': 'Australia and New Zealand',
            'currency': 'AUD',
            'alpha2': 'CX',
            'alpha3': 'CXR',
            'ioc': null,
            'number': '162',
            'tel': '61',
            'latitude': '10 30 S',
            'longitude': '105 40 E',
            'un': 'CX'
        },
        'CY': {
            'value': 'CY',
            'name': 'Cyprus',
            'names': [
                'Cyprus',
                'Zypern',
                'Chypre',
                'Chipre'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'EUR',
            'alpha2': 'CY',
            'alpha3': 'CYP',
            'ioc': 'CYP',
            'number': '196',
            'tel': '357',
            'latitude': '35 00 N',
            'longitude': '33 00 E',
            'un': 'CY'
        },
        'CZ': {
            'value': 'CZ',
            'name': 'Czech Republic',
            'names': [
                'Czech Republic',
                'Tschechische Republik',
                'République Tchèque',
                'República Checa'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'CZK',
            'alpha2': 'CZ',
            'alpha3': 'CZE',
            'ioc': 'CZE',
            'number': '203',
            'tel': '420',
            'latitude': '49 45 N',
            'longitude': '15 30 E',
            'un': 'CZ'
        },
        'DE': {
            'value': 'DE',
            'name': 'Germany',
            'names': [
                'Germany',
                'Deutschland',
                'Allemagne',
                'Alemania'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'EUR',
            'alpha2': 'DE',
            'alpha3': 'DEU',
            'ioc': 'GER',
            'number': '276',
            'tel': '49',
            'latitude': '51 00 N',
            'longitude': '9 00 E',
            'un': 'DE'
        },
        'DJ': {
            'value': 'DJ',
            'name': 'Djibouti',
            'names': [
                'Djibouti'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'DJF',
            'alpha2': 'DJ',
            'alpha3': 'DJI',
            'ioc': 'DJI',
            'number': '262',
            'tel': '253',
            'latitude': '11 30 N',
            'longitude': '43 00 E',
            'un': 'DJ'
        },
        'DK': {
            'value': 'DK',
            'name': 'Denmark',
            'names': [
                'Denmark',
                'Dänemark',
                'Danemark',
                'Dinamarca'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'DKK',
            'alpha2': 'DK',
            'alpha3': 'DNK',
            'ioc': 'DEN',
            'number': '208',
            'tel': '45',
            'latitude': '56 00 N',
            'longitude': '10 00 E',
            'un': 'DK'
        },
        'DM': {
            'value': 'DM',
            'name': 'Dominica',
            'names': [
                'Dominica'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'XCD',
            'alpha2': 'DM',
            'alpha3': 'DMA',
            'ioc': 'DMA',
            'number': '212',
            'tel': '1',
            'latitude': '15 25 N',
            'longitude': '61 20 W',
            'un': 'DM'
        },
        'DO': {
            'value': 'DO',
            'name': 'Dominican Republic',
            'names': [
                'Dominican Republic',
                'Dominikanische Republik',
                'République Dominicaine',
                'República Dominicana'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'DOP',
            'alpha2': 'DO',
            'alpha3': 'DOM',
            'ioc': 'DOM',
            'number': '214',
            'tel': '1',
            'latitude': '19 00 N',
            'longitude': '70 40 W',
            'un': 'DO'
        },
        'DZ': {
            'value': 'DZ',
            'name': 'Algeria',
            'names': [
                'Algeria',
                'Algerien',
                'Algérie',
                'Argelia'
            ],
            'region': 'Africa',
            'subregion': 'Northern Africa',
            'currency': 'DZD',
            'alpha2': 'DZ',
            'alpha3': 'DZA',
            'ioc': 'ALG',
            'number': '012',
            'tel': '213',
            'latitude': '28 00 N',
            'longitude': '3 00 E',
            'un': 'DZ'
        },
        'EC': {
            'value': 'EC',
            'name': 'Ecuador',
            'names': [
                'Équateur',
                'Ecuador'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'USD',
            'alpha2': 'EC',
            'alpha3': 'ECU',
            'ioc': 'ECU',
            'number': '218',
            'tel': '593',
            'latitude': '2 00 S',
            'longitude': '77 30 W',
            'un': 'EC'
        },
        'EE': {
            'value': 'EE',
            'name': 'Estonia',
            'names': [
                'Estland',
                'Estonie',
                'Estonia'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'EEK',
            'alpha2': 'EE',
            'alpha3': 'EST',
            'ioc': 'EST',
            'number': '233',
            'tel': '372',
            'latitude': '59 00 N',
            'longitude': '26 00 E',
            'un': 'EE'
        },
        'EG': {
            'value': 'EG',
            'name': 'Egypt',
            'names': [
                'Egypt',
                'Ägypten',
                'Égypte',
                'Egipto'
            ],
            'region': 'Africa',
            'subregion': 'Northern Africa',
            'currency': 'EGP',
            'alpha2': 'EG',
            'alpha3': 'EGY',
            'ioc': 'EGY',
            'number': '818',
            'tel': '20',
            'latitude': '27 00 N',
            'longitude': '30 00 E',
            'un': 'EG'
        },
        'EH': {
            'value': 'EH',
            'name': 'Western Sahara',
            'names': [
                'Western Sahara',
                'Westsahara',
                'Sahara Occidental',
                'Sahara Occidental'
            ],
            'region': 'Africa',
            'subregion': 'Northern Africa',
            'currency': 'MAD',
            'alpha2': 'EH',
            'alpha3': 'ESH',
            'ioc': null,
            'number': '732',
            'tel': '212',
            'latitude': '24 30 N',
            'longitude': '13 00 W',
            'un': 'EH'
        },
        'ER': {
            'value': 'ER',
            'name': 'Eritrea',
            'names': [
                'Érythrée',
                'Eritrea'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'ETB',
            'alpha2': 'ER',
            'alpha3': 'ERI',
            'ioc': 'ERI',
            'number': '232',
            'tel': '291',
            'latitude': '15 00 N',
            'longitude': '39 00 E',
            'un': 'ER'
        },
        'ES': {
            'value': 'ES',
            'name': 'Spain',
            'names': [
                'Spain',
                'Spanien',
                'Espagne',
                'España'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'ES',
            'alpha3': 'ESP',
            'ioc': 'ESP',
            'number': '724',
            'tel': '34',
            'latitude': '40 00 N',
            'longitude': '4 00 W',
            'un': 'ES'
        },
        'ET': {
            'value': 'ET',
            'name': 'Ethiopia',
            'names': [
                'Ethiopia',
                'Äthiopien',
                'Éthiopie',
                'Etiopía'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'ETB',
            'alpha2': 'ET',
            'alpha3': 'ETH',
            'ioc': 'ETH',
            'number': '231',
            'tel': '251',
            'latitude': '8 00 N',
            'longitude': '38 00 E',
            'un': 'ET'
        },
        'FI': {
            'value': 'FI',
            'name': 'Finland',
            'names': [
                'Finland',
                'Finnland',
                'Finlande',
                'Finlandia'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'EUR',
            'alpha2': 'FI',
            'alpha3': 'FIN',
            'ioc': null,
            'number': '246',
            'tel': '358',
            'latitude': '64 00 N',
            'longitude': '26 00 E',
            'un': null
        },
        'FJ': {
            'value': 'FJ',
            'name': 'Fiji',
            'names': [
                'Fiji',
                'Fidschi',
                'Fidji'
            ],
            'region': 'Oceania',
            'subregion': 'Melanesia',
            'currency': 'USD',
            'alpha2': 'FJ',
            'alpha3': 'FJI',
            'ioc': 'FIJ',
            'number': '242',
            'tel': '679',
            'latitude': '18 00 S',
            'longitude': '175 00 E',
            'un': 'FJ'
        },
        'FK': {
            'value': 'FK',
            'name': 'Falkland Islands (Malvinas)',
            'names': [
                'Falkland Islands',
                'Falklandinseln',
                'Îles Malouines',
                'Islas Malvinas'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'FKP',
            'alpha2': 'FK',
            'alpha3': 'FLK',
            'ioc': null,
            'number': '238',
            'tel': '500',
            'latitude': '51 45 S',
            'longitude': '59 00 W',
            'un': 'FK'
        },
        'FM': {
            'value': 'FM',
            'name': 'Micronesia, Federated States Of',
            'names': [
                'Micronesia',
                'Mikronesien',
                'Micronésie',
                'Micronesia'
            ],
            'region': 'Oceania',
            'subregion': 'Micronesia',
            'currency': 'USD',
            'alpha2': 'FM',
            'alpha3': 'FSM',
            'ioc': 'FSM',
            'number': '583',
            'tel': '691',
            'latitude': '6 55 N',
            'longitude': '158 15 E',
            'un': 'FM'
        },
        'FO': {
            'value': 'FO',
            'name': 'Faroe Islands',
            'names': [
                'Faroe Islands',
                'Färöer-Inseln',
                'Îles Féroé',
                'Islas Faroe'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'DKK',
            'alpha2': 'FO',
            'alpha3': 'FRO',
            'ioc': 'FRO',
            'number': '234',
            'tel': '298',
            'latitude': '62 00 N',
            'longitude': '7 00 W',
            'un': 'FO'
        },
        'FR': {
            'value': 'FR',
            'name': 'France',
            'names': [
                'France',
                'Frankreich',
                'Francia',
                'the French Republic'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'EUR',
            'alpha2': 'FR',
            'alpha3': 'FRA',
            'ioc': 'FRA',
            'number': '250',
            'tel': '33',
            'latitude': '46 00 N',
            'longitude': '2 00 E ',
            'un': 'FR'
        },
        'GA': {
            'value': 'GA',
            'name': 'Gabon',
            'names': [
                'Gabon',
                'Gabun',
                'Gabón'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': 'XAF',
            'alpha2': 'GA',
            'alpha3': 'GAB',
            'ioc': 'GAB',
            'number': '266',
            'tel': '241',
            'latitude': '1 00 S',
            'longitude': '11 45 E',
            'un': 'GA'
        },
        'GB': {
            'value': 'GB',
            'name': 'United Kingdom',
            'names': [
                'United Kingdom',
                'Großbritannien',
                'Royaume-Uni',
                'Reino Unido'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'GBP',
            'alpha2': 'GB',
            'alpha3': 'GBR',
            'ioc': null,
            'number': '826',
            'tel': '44',
            'latitude': '54 00 N',
            'longitude': '2 00 W',
            'un': null
        },
        'GD': {
            'value': 'GD',
            'name': 'Grenada',
            'names': [
                'Grenada'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'XCD',
            'alpha2': 'GD',
            'alpha3': 'GRD',
            'ioc': 'GRN',
            'number': '308',
            'tel': '1',
            'latitude': '12 07 N',
            'longitude': '61 40 W',
            'un': 'GD'
        },
        'GE': {
            'value': 'GE',
            'name': 'Georgia',
            'names': [
                'Georgien',
                'Géorgie',
                'Georgia'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'GEL',
            'alpha2': 'GE',
            'alpha3': 'GEO',
            'ioc': 'GEO',
            'number': '268',
            'tel': '995',
            'latitude': '42 00 N',
            'longitude': '43 30 E',
            'un': 'GE'
        },
        'GF': {
            'value': 'GF',
            'name': 'French Guiana',
            'names': [
                'French Guiana',
                'Französisch Guyana',
                'Guyane française',
                'Guayana Francesa'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'EUR',
            'alpha2': 'GF',
            'alpha3': 'GUF',
            'ioc': null,
            'number': '254',
            'tel': '594',
            'latitude': '4 00 N',
            'longitude': '53 00 W ',
            'un': 'GF'
        },
        'GG': {
            'value': 'GG',
            'name': 'Guernsey',
            'names': [
                'Guernsey and Alderney',
                'Guernsey und Alderney',
                'Guernsey et Alderney',
                'Guernsey y Alderney'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'GGP',
            'alpha2': 'GG',
            'alpha3': 'GGY',
            'ioc': null,
            'number': '831',
            'tel': '44',
            'latitude': '49 28 N',
            'longitude': '2 35 W',
            'un': null
        },
        'GH': {
            'value': 'GH',
            'name': 'Ghana',
            'names': [
                'Ghana'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'GHS',
            'alpha2': 'GH',
            'alpha3': 'GHA',
            'ioc': 'GHA',
            'number': '288',
            'tel': '233',
            'latitude': '8 00 N',
            'longitude': '2 00 W',
            'un': 'GH'
        },
        'GI': {
            'value': 'GI',
            'name': 'Gibraltar',
            'names': [
                'Gibraltar'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'GIP',
            'alpha2': 'GI',
            'alpha3': 'GIB',
            'ioc': null,
            'number': '292',
            'tel': '350',
            'latitude': '36 08 N',
            'longitude': '5 21 W',
            'un': 'GI'
        },
        'GL': {
            'value': 'GL',
            'name': 'Greenland',
            'names': [
                'Greenland',
                'Grönland',
                'Groenland',
                'Groenlandia'
            ],
            'region': 'Americas',
            'subregion': 'Northern America',
            'currency': 'DKK',
            'alpha2': 'GL',
            'alpha3': 'GRL',
            'ioc': null,
            'number': '304',
            'tel': '299',
            'latitude': '72 00 N',
            'longitude': '40 00 W',
            'un': 'GL'
        },
        'GM': {
            'value': 'GM',
            'name': 'Gambia',
            'names': [
                'Gambia'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'GMD',
            'alpha2': 'GM',
            'alpha3': 'GMB',
            'ioc': 'GAM',
            'number': '270',
            'tel': '220',
            'latitude': '13 28 N',
            'longitude': '16 34 W',
            'un': 'GM'
        },
        'GN': {
            'value': 'GN',
            'name': 'Guinea',
            'names': [
                'Guinea',
                'Guinea',
                'Guinée',
                'Guinea'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'GNF',
            'alpha2': 'GN',
            'alpha3': 'GIN',
            'ioc': 'GUI',
            'number': '324',
            'tel': '224',
            'latitude': '11 00 N',
            'longitude': '10 00 W',
            'un': 'GN'
        },
        'GP': {
            'value': 'GP',
            'name': 'Guadeloupe',
            'names': [
                'Guadeloupe',
                'Guadalupe'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'EUR',
            'alpha2': 'GP',
            'alpha3': 'GLP',
            'ioc': null,
            'number': '312',
            'tel': '590',
            'latitude': '',
            'longitude': '',
            'un': 'GP'
        },
        'GQ': {
            'value': 'GQ',
            'name': 'Equatorial Guinea',
            'names': [
                'Equatorial Guinea',
                'Äquatorial-Guinea',
                'Guinée Équatoriale',
                'Guinea Ecuatorial'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': null,
            'alpha2': 'GQ',
            'alpha3': 'GNQ',
            'ioc': 'GEQ',
            'number': '226',
            'tel': '240',
            'latitude': '2 00 N',
            'longitude': '10 00 E',
            'un': 'GQ'
        },
        'GR': {
            'value': 'GR',
            'name': 'Greece',
            'names': [
                'Greece',
                'Griechenland',
                'Grèce',
                'Grecia'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'GR',
            'alpha3': 'GRC',
            'ioc': 'GRE',
            'number': '300',
            'tel': '30',
            'latitude': '39 00 N',
            'longitude': '22 00 E',
            'un': 'GR'
        },
        'GS': {
            'value': 'GS',
            'name': 'South Georgia and the South Sandwich Islands',
            'names': [
                'South Georgia and the South Sandwich Islands'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': null,
            'alpha2': 'GS',
            'alpha3': 'SGS',
            'ioc': null,
            'number': '239',
            'tel': '500',
            'latitude': '54 30 S',
            'longitude': '37 00 W',
            'un': 'GS'
        },
        'GT': {
            'value': 'GT',
            'name': 'Guatemala',
            'names': [
                'Guatemala'
            ],
            'region': 'Americas',
            'subregion': 'Central America',
            'currency': 'GTQ',
            'alpha2': 'GT',
            'alpha3': 'GTM',
            'ioc': 'GUA',
            'number': '320',
            'tel': '502',
            'latitude': '15 30 N',
            'longitude': '90 15 W',
            'un': 'GT'
        },
        'GU': {
            'value': 'GU',
            'name': 'Guam',
            'names': [
                'Guam'
            ],
            'region': 'Oceania',
            'subregion': 'Micronesia',
            'currency': 'USD',
            'alpha2': 'GU',
            'alpha3': 'GUM',
            'ioc': 'GUM',
            'number': '316',
            'tel': '1',
            'latitude': '13 28 N',
            'longitude': '144 47 E',
            'un': 'GU'
        },
        'GW': {
            'value': 'GW',
            'name': 'Guinea-Bissau',
            'names': [
                'Guinea-Bissau',
                'Guinée-Bissau'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'XOF',
            'alpha2': 'GW',
            'alpha3': 'GNB',
            'ioc': 'GBS',
            'number': '624',
            'tel': '245',
            'latitude': '12 00 N',
            'longitude': '15 00 W',
            'un': 'GW'
        },
        'GY': {
            'value': 'GY',
            'name': 'Guyana',
            'names': [
                'Guyana'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'GYD',
            'alpha2': 'GY',
            'alpha3': 'GUY',
            'ioc': 'GUY',
            'number': '328',
            'tel': '592',
            'latitude': '5 00 N',
            'longitude': '59 00 W',
            'un': 'GY'
        },
        'HK': {
            'value': 'HK',
            'name': 'Hong Kong',
            'names': [
                'Hong Kong'
            ],
            'region': 'Asia',
            'subregion': 'Eastern Asia',
            'currency': 'HKD',
            'alpha2': 'HK',
            'alpha3': 'HKG',
            'ioc': 'HKG',
            'number': '344',
            'tel': '852',
            'latitude': '22 15 N',
            'longitude': '114 10 E',
            'un': 'HK'
        },
        'HM': {
            'value': 'HM',
            'name': 'Heard and McDonald Islands',
            'names': [
                'Heard and McDonald Islands'
            ],
            'region': '',
            'subregion': '',
            'currency': 'AUD',
            'alpha2': 'HM',
            'alpha3': 'HMD',
            'ioc': null,
            'number': '334',
            'tel': '',
            'latitude': '53 06 S',
            'longitude': '72 31 E',
            'un': 'HM'
        },
        'HN': {
            'value': 'HN',
            'name': 'Honduras',
            'names': [
                'Honduras'
            ],
            'region': 'Americas',
            'subregion': 'Central America',
            'currency': 'HNL',
            'alpha2': 'HN',
            'alpha3': 'HND',
            'ioc': 'HON',
            'number': '340',
            'tel': '504',
            'latitude': '15 00 N',
            'longitude': '86 30 W',
            'un': 'HN'
        },
        'HR': {
            'value': 'HR',
            'name': 'Croatia',
            'names': [
                'Croatia',
                'Kroatien',
                'Croatie',
                'Croacia'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'HRK',
            'alpha2': 'HR',
            'alpha3': 'HRV',
            'ioc': 'CRO',
            'number': '191',
            'tel': '385',
            'latitude': '45 10 N',
            'longitude': '15 30 E',
            'un': 'HR'
        },
        'HT': {
            'value': 'HT',
            'name': 'Haiti',
            'names': [
                'Haiti'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'USD',
            'alpha2': 'HT',
            'alpha3': 'HTI',
            'ioc': 'HAI',
            'number': '332',
            'tel': '509',
            'latitude': '19 00 N',
            'longitude': '72 25 W',
            'un': 'HT'
        },
        'HU': {
            'value': 'HU',
            'name': 'Hungary',
            'names': [
                'Hungary',
                'Ungarn',
                'Hongrie',
                'Hungría'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'HUF',
            'alpha2': 'HU',
            'alpha3': 'HUN',
            'ioc': 'HUN',
            'number': '348',
            'tel': '36',
            'latitude': '47 00 N',
            'longitude': '20 00 E',
            'un': 'HU'
        },
        'ID': {
            'value': 'ID',
            'name': 'Indonesia',
            'names': [
                'Indonesia',
                'Indonesien',
                'Indonésie'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'IDR',
            'alpha2': 'ID',
            'alpha3': 'IDN',
            'ioc': 'INA',
            'number': '360',
            'tel': '62',
            'latitude': '5 00 S',
            'longitude': '120 00 E',
            'un': 'ID'
        },
        'IE': {
            'value': 'IE',
            'name': 'Ireland',
            'names': [
                'Ireland',
                'Irland',
                'Irlande',
                'Irlanda'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'EUR',
            'alpha2': 'IE',
            'alpha3': 'IRL',
            'ioc': 'IRL',
            'number': '372',
            'tel': '353',
            'latitude': '53 00 N',
            'longitude': '8 00 W',
            'un': 'IE'
        },
        'IL': {
            'value': 'IL',
            'name': 'Israel',
            'names': [
                'Israël',
                'Israel'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'ILS',
            'alpha2': 'IL',
            'alpha3': 'ISR',
            'ioc': 'ISR',
            'number': '376',
            'tel': '972',
            'latitude': '31 30 N',
            'longitude': '34 45 E',
            'un': 'IL'
        },
        'IM': {
            'value': 'IM',
            'name': 'Isle of Man',
            'names': [
                'Isle of Man',
                'Man',
                'Île de Man',
                'Isla de Man'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'IMP',
            'alpha2': 'IM',
            'alpha3': 'IMN',
            'ioc': null,
            'number': '833',
            'tel': '44',
            'latitude': '54 15 N',
            'longitude': '4 30 W',
            'un': null
        },
        'IN': {
            'value': 'IN',
            'name': 'India',
            'names': [
                'India',
                'Indien',
                'Inde'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'INR',
            'alpha2': 'IN',
            'alpha3': 'IND',
            'ioc': 'IND',
            'number': '356',
            'tel': '91',
            'latitude': '20 00 N',
            'longitude': '77 00 E',
            'un': 'IN'
        },
        'IO': {
            'value': 'IO',
            'name': 'British Indian Ocean Territory',
            'names': [
                'British Indian Ocean Territory'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'USD',
            'alpha2': 'IO',
            'alpha3': 'IOT',
            'ioc': null,
            'number': '086',
            'tel': '246',
            'latitude': '6 00 S',
            'longitude': '71 30 E',
            'un': 'IO'
        },
        'IQ': {
            'value': 'IQ',
            'name': 'Iraq',
            'names': [
                'Iraq',
                'Irak'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'IQD',
            'alpha2': 'IQ',
            'alpha3': 'IRQ',
            'ioc': 'IRQ',
            'number': '368',
            'tel': '964',
            'latitude': '33 00 N',
            'longitude': '44 00 E',
            'un': 'IQ'
        },
        'IR': {
            'value': 'IR',
            'name': 'Iran, Islamic Republic Of',
            'names': [
                'Iran',
                'Irán'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'IRR',
            'alpha2': 'IR',
            'alpha3': 'IRN',
            'ioc': 'IRI',
            'number': '364',
            'tel': '98',
            'latitude': '32 00 N',
            'longitude': '53 00 E',
            'un': 'IR'
        },
        'IS': {
            'value': 'IS',
            'name': 'Iceland',
            'names': [
                'Iceland',
                'Island',
                'Islande',
                'Islandia'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'ISK',
            'alpha2': 'IS',
            'alpha3': 'ISL',
            'ioc': 'ISL',
            'number': '352',
            'tel': '354',
            'latitude': '65 00 N',
            'longitude': '18 00 W',
            'un': 'IS'
        },
        'IT': {
            'value': 'IT',
            'name': 'Italy',
            'names': [
                'Italy',
                'Italien',
                'Italie',
                'Italia'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'IT',
            'alpha3': 'ITA',
            'ioc': 'ITA',
            'number': '380',
            'tel': '39',
            'latitude': '42 50 N',
            'longitude': '12 50 E',
            'un': 'IT'
        },
        'JE': {
            'value': 'JE',
            'name': 'Jersey',
            'names': [
                'Jersey'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'JEP',
            'alpha2': 'JE',
            'alpha3': 'JEY',
            'ioc': null,
            'number': '832',
            'tel': '44',
            'latitude': '49 15 N',
            'longitude': '2 10 W',
            'un': null
        },
        'JM': {
            'value': 'JM',
            'name': 'Jamaica',
            'names': [
                'Jamaica',
                'Jamaika',
                'Jamaïque'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'JMD',
            'alpha2': 'JM',
            'alpha3': 'JAM',
            'ioc': 'JAM',
            'number': '388',
            'tel': '1',
            'latitude': '18 15 N',
            'longitude': '77 30 W',
            'un': 'JM'
        },
        'JO': {
            'value': 'JO',
            'name': 'Jordan',
            'names': [
                'Jordan',
                'Jordanien',
                'Jordanie',
                'Jordania'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'JOD',
            'alpha2': 'JO',
            'alpha3': 'JOR',
            'ioc': 'JOR',
            'number': '400',
            'tel': '962',
            'latitude': '31 00 N',
            'longitude': '36 00 E',
            'un': 'JO'
        },
        'JP': {
            'value': 'JP',
            'name': 'Japan',
            'names': [
                'Japan',
                'Japan',
                'Japon',
                'Japón'
            ],
            'region': 'Asia',
            'subregion': 'Eastern Asia',
            'currency': 'JPY',
            'alpha2': 'JP',
            'alpha3': 'JPN',
            'ioc': 'JPN',
            'number': '392',
            'tel': '81',
            'latitude': '36 00 N',
            'longitude': '138 00 E',
            'un': 'JP'
        },
        'KE': {
            'value': 'KE',
            'name': 'Kenya',
            'names': [
                'Kenya',
                'Kenia',
                'Kenya',
                'Kenia'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'KES',
            'alpha2': 'KE',
            'alpha3': 'KEN',
            'ioc': 'KEN',
            'number': '404',
            'tel': '254',
            'latitude': '1 00 N',
            'longitude': '38 00 E',
            'un': 'KE'
        },
        'KG': {
            'value': 'KG',
            'name': 'Kyrgyzstan',
            'names': [
                'Kyrgyzstan',
                'Kirgisistan',
                'Kirghizistan',
                'Kirguizistán'
            ],
            'region': 'Asia',
            'subregion': 'Central Asia',
            'currency': 'KGS',
            'alpha2': 'KG',
            'alpha3': 'KGZ',
            'ioc': 'KGZ',
            'number': '417',
            'tel': '996',
            'latitude': '41 00 N',
            'longitude': '75 00 E',
            'un': 'KG'
        },
        'KH': {
            'value': 'KH',
            'name': 'Cambodia',
            'names': [
                'Cambodia',
                'Kambodscha',
                'Cambodge',
                'Camboya'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'KHR',
            'alpha2': 'KH',
            'alpha3': 'KHM',
            'ioc': 'CAM',
            'number': '116',
            'tel': '855',
            'latitude': '13 00 N',
            'longitude': '105 00 E',
            'un': 'KH'
        },
        'KI': {
            'value': 'KI',
            'name': 'Kiribati',
            'names': [
                'Kiribati',
                'Kiribati',
                'Kiribati',
                'Kiribati'
            ],
            'region': 'Oceania',
            'subregion': 'Micronesia',
            'currency': 'AUD',
            'alpha2': 'KI',
            'alpha3': 'KIR',
            'ioc': 'KIR',
            'number': '296',
            'tel': '686',
            'latitude': '1 25 N',
            'longitude': '173 00 E',
            'un': 'KI'
        },
        'KM': {
            'value': 'KM',
            'name': 'Comoros',
            'names': [
                'Comoros',
                'Komoren',
                'Comores'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'KMF',
            'alpha2': 'KM',
            'alpha3': 'COM',
            'ioc': 'COM',
            'number': '174',
            'tel': '269',
            'latitude': '12 10 S',
            'longitude': '44 15 E',
            'un': 'KM'
        },
        'KN': {
            'value': 'KN',
            'name': 'Saint Kitts And Nevis',
            'names': [
                'Saint Kitts and Nevis',
                'Saint Kitts et Nevis',
                'Saint Kitts y Nevis'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'XCD',
            'alpha2': 'KN',
            'alpha3': 'KNA',
            'ioc': 'SKN',
            'number': '659',
            'tel': '1',
            'latitude': '17 20 N',
            'longitude': '62 45 W',
            'un': 'KN'
        },
        'KP': {
            'value': 'KP',
            'name': 'Korea, Democratic People\'s Republic Of',
            'names': [
                'Korea (North)',
                'Nordkorea',
                'Corée du Nord',
                'Corea del Norte'
            ],
            'region': 'Asia',
            'subregion': 'Eastern Asia',
            'currency': 'KPW',
            'alpha2': 'KP',
            'alpha3': 'PRK',
            'ioc': 'PRK',
            'number': '408',
            'tel': '850',
            'latitude': '40 00 N',
            'longitude': '127 00 E',
            'un': 'KP'
        },
        'KR': {
            'value': 'KR',
            'name': 'Korea, Republic of',
            'names': [
                'Korea (South)',
                'Südkorea',
                'Corée du Sud',
                'Corea del Sur'
            ],
            'region': 'Asia',
            'subregion': 'Eastern Asia',
            'currency': 'KRW',
            'alpha2': 'KR',
            'alpha3': 'KOR',
            'ioc': 'KOR',
            'number': '410',
            'tel': '82',
            'latitude': '37 00 N',
            'longitude': '127 30 E',
            'un': 'KR'
        },
        'KW': {
            'value': 'KW',
            'name': 'Kuwait',
            'names': [
                'Koweït',
                'Kuwait'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'KWD',
            'alpha2': 'KW',
            'alpha3': 'KWT',
            'ioc': 'KUW',
            'number': '414',
            'tel': '965',
            'latitude': '29 30 N',
            'longitude': '45 45 E',
            'un': 'KW'
        },
        'KY': {
            'value': 'KY',
            'name': 'Cayman Islands',
            'names': [
                'Cayman Islands',
                'Cayman-Inseln',
                'Îles Caïmans',
                'Islas Caimán'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'KYD',
            'alpha2': 'KY',
            'alpha3': 'CYM',
            'ioc': 'CAY',
            'number': '136',
            'tel': '1',
            'latitude': '19 30 N',
            'longitude': '80 30 W',
            'un': 'KY'
        },
        'KZ': {
            'value': 'KZ',
            'name': 'Kazakhstan',
            'names': [
                'Kazakhstan',
                'Kasachstan',
                'Kazakhstan',
                'Kazajistán'
            ],
            'region': 'Asia',
            'subregion': 'Central Asia',
            'currency': 'KZT',
            'alpha2': 'KZ',
            'alpha3': 'KAZ',
            'ioc': 'KAZ',
            'number': '398',
            'tel': '7',
            'latitude': '48 00 N',
            'longitude': '68 00 E',
            'un': 'KZ'
        },
        'LA': {
            'value': 'LA',
            'name': 'Lao People\'s Democratic Republic',
            'names': [
                'Laos',
                'Laos',
                'Laos',
                'Laos'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'LAK',
            'alpha2': 'LA',
            'alpha3': 'LAO',
            'ioc': 'LAO',
            'number': '418',
            'tel': '856',
            'latitude': '18 00 N',
            'longitude': '105 00 E',
            'un': 'LA',
            'commonname': 'Laos'
        },
        'LB': {
            'value': 'LB',
            'name': 'Lebanon',
            'names': [
                'Lebanon',
                'Libanon',
                'Liban',
                'Líbano'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'LBP',
            'alpha2': 'LB',
            'alpha3': 'LBN',
            'ioc': 'LIB',
            'number': '422',
            'tel': '961',
            'latitude': '33 50 N',
            'longitude': '35 50 E',
            'un': 'LB'
        },
        'LC': {
            'value': 'LC',
            'name': 'Saint Lucia',
            'names': [
                'Saint Lucia',
                'Saint-Lucie',
                'Santa Lucía'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'XCD',
            'alpha2': 'LC',
            'alpha3': 'LCA',
            'ioc': 'LCA',
            'number': '662',
            'tel': '1',
            'latitude': '13 53 N',
            'longitude': '60 58 W',
            'un': 'LC'
        },
        'LI': {
            'value': 'LI',
            'name': 'Liechtenstein',
            'names': [
                'Liechtenstein'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'CHF',
            'alpha2': 'LI',
            'alpha3': 'LIE',
            'ioc': 'LIE',
            'number': '438',
            'tel': '423',
            'latitude': '47 16 N',
            'longitude': '9 32 E',
            'un': 'LI'
        },
        'LK': {
            'value': 'LK',
            'name': 'Sri Lanka',
            'names': [
                'Sri Lanka'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'LKR',
            'alpha2': 'LK',
            'alpha3': 'LKA',
            'ioc': 'SRI',
            'number': '144',
            'tel': '94',
            'latitude': '7 00 N',
            'longitude': '81 00 E',
            'un': 'LK'
        },
        'LR': {
            'value': 'LR',
            'name': 'Liberia',
            'names': [
                'Liberia',
                'Liberia',
                'Liberia',
                'Liberia'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'LRD',
            'alpha2': 'LR',
            'alpha3': 'LBR',
            'ioc': 'LBR',
            'number': '430',
            'tel': '231',
            'latitude': '6 30 N',
            'longitude': '9 30 W',
            'un': 'LR'
        },
        'LS': {
            'value': 'LS',
            'name': 'Lesotho',
            'names': [
                'Lesotho',
                'Lesotho',
                'Lesotho',
                'Lesotho'
            ],
            'region': 'Africa',
            'subregion': 'Southern Africa',
            'currency': 'LSL',
            'alpha2': 'LS',
            'alpha3': 'LSO',
            'ioc': 'LES',
            'number': '426',
            'tel': '266',
            'latitude': '29 30 S',
            'longitude': '28 30 E',
            'un': 'LS'
        },
        'LT': {
            'value': 'LT',
            'name': 'Lithuania',
            'names': [
                'Lithuania',
                'Litauen',
                'Lituanie',
                'Lituania'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'LTL',
            'alpha2': 'LT',
            'alpha3': 'LTU',
            'ioc': 'LTU',
            'number': '440',
            'tel': '370',
            'latitude': '56 00 N',
            'longitude': '24 00 E',
            'un': 'LT'
        },
        'LU': {
            'value': 'LU',
            'name': 'Luxembourg',
            'names': [
                'Luxembourg',
                'Luxemburg',
                'Luxembourg',
                'Luxemburgo'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'EUR',
            'alpha2': 'LU',
            'alpha3': 'LUX',
            'ioc': 'LUX',
            'number': '442',
            'tel': '352',
            'latitude': '49 45 N',
            'longitude': '6 10 E',
            'un': 'LU'
        },
        'LV': {
            'value': 'LV',
            'name': 'Latvia',
            'names': [
                'Latvia',
                'Lettland',
                'Lettonie',
                'Letonia'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'LVL',
            'alpha2': 'LV',
            'alpha3': 'LVA',
            'ioc': 'LAT',
            'number': '428',
            'tel': '371',
            'latitude': '57 00 N',
            'longitude': '25 00 E',
            'un': 'LV'
        },
        'LY': {
            'value': 'LY',
            'name': 'Libyan Arab Jamahiriya',
            'names': [
                'Libya',
                'Libyen',
                'Libye',
                'Libia'
            ],
            'region': 'Africa',
            'subregion': 'Northern Africa',
            'currency': 'LYD',
            'alpha2': 'LY',
            'alpha3': 'LBY',
            'ioc': 'LBA',
            'number': '434',
            'tel': '218',
            'latitude': '25 00 N',
            'longitude': '17 00 E',
            'un': 'LY'
        },
        'MA': {
            'value': 'MA',
            'name': 'Morocco',
            'names': [
                'Morocco',
                'Marokko',
                'Maroc',
                'Marruecos'
            ],
            'region': 'Africa',
            'subregion': 'Northern Africa',
            'currency': 'MAD',
            'alpha2': 'MA',
            'alpha3': 'MAR',
            'ioc': 'MAR',
            'number': '504',
            'tel': '212',
            'latitude': '32 00 N',
            'longitude': '5 00 W',
            'un': 'MA'
        },
        'MC': {
            'value': 'MC',
            'name': 'Monaco',
            'names': [
                'Monaco',
                'Mónaco'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'EUR',
            'alpha2': 'MC',
            'alpha3': 'MCO',
            'ioc': 'MON',
            'number': '492',
            'tel': '377',
            'latitude': '43 44 N',
            'longitude': '7 24 E',
            'un': 'MC'
        },
        'MD': {
            'value': 'MD',
            'name': 'Moldova, Republic of',
            'names': [
                'Moldova',
                'Moldawien',
                'Moldavie',
                'Moldavia',
                'the Republic of Moldova'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'MDL',
            'alpha2': 'MD',
            'alpha3': 'MDA',
            'ioc': 'MDA',
            'number': '498',
            'tel': '373',
            'latitude': '47 00 N',
            'longitude': '29 00 E',
            'un': 'MD'
        },
        'ME': {
            'value': 'ME',
            'name': 'Montenegro',
            'names': [
                'Crna Gora',
                'Montenegro'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'ME',
            'alpha3': 'MNE',
            'ioc': 'MNE',
            'number': '499',
            'tel': '382',
            'latitude': '42 30 N',
            'longitude': '19 18 E',
            'un': 'ME'
        },
        'MF': {
            'value': 'MF',
            'name': 'Saint Martin',
            'names': [
                'Saint Martin'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'EUR',
            'alpha2': 'MF',
            'alpha3': 'MAF',
            'ioc': null,
            'number': '663',
            'tel': '590',
            'latitude': '18 05 N',
            'longitude': '63 57 W',
            'un': null
        },
        'MG': {
            'value': 'MG',
            'name': 'Madagascar',
            'names': [
                'Madagascar',
                'Madagaskar',
                'the Republic of Madagascar'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': null,
            'alpha2': 'MG',
            'alpha3': 'MDG',
            'ioc': 'MAD',
            'number': '450',
            'tel': '261',
            'latitude': '20 00 S',
            'longitude': '47 00 E',
            'un': 'MG'
        },
        'MH': {
            'value': 'MH',
            'name': 'Marshall Islands',
            'names': [
                'Marshall Islands',
                'Marshall-Inseln',
                'Îles Marshall',
                'Islas Marshall'
            ],
            'region': 'Oceania',
            'subregion': 'Micronesia',
            'currency': 'USD',
            'alpha2': 'MH',
            'alpha3': 'MHL',
            'ioc': 'MHL',
            'number': '584',
            'tel': '692',
            'latitude': '9 00 N',
            'longitude': '168 00 E',
            'un': 'MH'
        },
        'MK': {
            'value': 'MK',
            'name': 'Macedonia, the Former Yugoslav Republic Of',
            'names': [
                'Macedonia',
                'Mazedonien',
                'Macédoine',
                'Macedonia'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': null,
            'alpha2': 'MK',
            'alpha3': 'MKD',
            'ioc': 'MKD',
            'number': '807',
            'tel': '389',
            'latitude': '41 50 N',
            'longitude': '22 00 E',
            'un': 'MK'
        },
        'ML': {
            'value': 'ML',
            'name': 'Mali',
            'names': [
                'Mali'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'XOF',
            'alpha2': 'ML',
            'alpha3': 'MLI',
            'ioc': 'MLI',
            'number': '466',
            'tel': '223',
            'latitude': '17 00 N',
            'longitude': '4 00 W',
            'un': 'ML'
        },
        'MM': {
            'value': 'MM',
            'name': 'Myanmar',
            'names': [
                'Myanmar',
                'Myanmar',
                'Myanmar',
                'Myanmar'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'MNK',
            'alpha2': 'MM',
            'alpha3': 'MMR',
            'ioc': 'MYA',
            'number': '104',
            'tel': '95',
            'latitude': '22 00 N',
            'longitude': '98 00 E',
            'un': 'MM'
        },
        'MN': {
            'value': 'MN',
            'name': 'Mongolia',
            'names': [
                'Mongolei',
                'Mongolie',
                'Mongolia'
            ],
            'region': 'Asia',
            'subregion': 'Eastern Asia',
            'currency': 'MNT',
            'alpha2': 'MN',
            'alpha3': 'MNG',
            'ioc': 'MGL',
            'number': '496',
            'tel': '976',
            'latitude': '46 00 N',
            'longitude': '105 00 E',
            'un': 'MN'
        },
        'MO': {
            'value': 'MO',
            'name': 'Macao',
            'names': [
                'Macao'
            ],
            'region': 'Asia',
            'subregion': 'Eastern Asia',
            'currency': 'MOP',
            'alpha2': 'MO',
            'alpha3': 'MAC',
            'ioc': null,
            'number': '446',
            'tel': '853',
            'latitude': '22 10 N',
            'longitude': '113 33 E',
            'un': 'MO'
        },
        'MP': {
            'value': 'MP',
            'name': 'Northern Mariana Islands',
            'names': [
                'Northern Mariana Islands',
                'Nördliche Marianen',
                'Mariannes du Nord',
                'Islas Marianas del Norte'
            ],
            'region': 'Oceania',
            'subregion': 'Micronesia',
            'currency': 'USD',
            'alpha2': 'MP',
            'alpha3': 'MNP',
            'ioc': null,
            'number': '580',
            'tel': '1',
            'latitude': '15 12 N',
            'longitude': '145 45 E',
            'un': 'MP'
        },
        'MQ': {
            'value': 'MQ',
            'name': 'Martinique',
            'names': [
                'Martinique',
                'Martinique',
                'Martinique',
                'Martinica'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'EUR',
            'alpha2': 'MQ',
            'alpha3': 'MTQ',
            'ioc': null,
            'number': '474',
            'tel': '596',
            'latitude': '',
            'longitude': '',
            'un': 'MQ'
        },
        'MR': {
            'value': 'MR',
            'name': 'Mauritania',
            'names': [
                'Mauritania',
                'Mauretanien',
                'Mauritanie',
                'Mauritania'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'MRO',
            'alpha2': 'MR',
            'alpha3': 'MRT',
            'ioc': 'MTN',
            'number': '478',
            'tel': '222',
            'latitude': '20 00 N',
            'longitude': '12 00 W',
            'un': 'MR'
        },
        'MS': {
            'value': 'MS',
            'name': 'Montserrat',
            'names': [
                'Montserrat',
                'Montserrat',
                'Montserrat',
                'Montserrat'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'XCD',
            'alpha2': 'MS',
            'alpha3': 'MSR',
            'ioc': null,
            'number': '500',
            'tel': '1',
            'latitude': '16 45 N',
            'longitude': '62 12 W',
            'un': 'MS'
        },
        'MT': {
            'value': 'MT',
            'name': 'Malta',
            'names': [
                'Malta',
                'Malta',
                'Malte',
                'Malta'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'MT',
            'alpha3': 'MLT',
            'ioc': 'MLT',
            'number': '470',
            'tel': '356',
            'latitude': '35 50 N',
            'longitude': '14 35 E',
            'un': 'MT'
        },
        'MU': {
            'value': 'MU',
            'name': 'Mauritius',
            'names': [
                'Mauritius',
                'Mauritius',
                'Île Maurice',
                'Mauricio'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'MUR',
            'alpha2': 'MU',
            'alpha3': 'MUS',
            'ioc': 'MRI',
            'number': '480',
            'tel': '230',
            'latitude': '20 17 S',
            'longitude': '57 33 E',
            'un': 'MU'
        },
        'MV': {
            'value': 'MV',
            'name': 'Maldives',
            'names': [
                'Maldives',
                'Malediven',
                'Maldivas'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'MVR',
            'alpha2': 'MV',
            'alpha3': 'MDV',
            'ioc': 'MDV',
            'number': '462',
            'tel': '960',
            'latitude': '3 15 N',
            'longitude': '73 00 E',
            'un': 'MV'
        },
        'MW': {
            'value': 'MW',
            'name': 'Malawi',
            'names': [
                'Malawi'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'MWK',
            'alpha2': 'MW',
            'alpha3': 'MWI',
            'ioc': 'MAW',
            'number': '454',
            'tel': '265',
            'latitude': '13 30 S',
            'longitude': '34 00 E',
            'un': 'MW'
        },
        'MX': {
            'value': 'MX',
            'name': 'Mexico',
            'names': [
                'Mexico',
                'Mexiko',
                'Mexique',
                'México'
            ],
            'region': 'Americas',
            'subregion': 'Central America',
            'currency': 'MXN',
            'alpha2': 'MX',
            'alpha3': 'MEX',
            'ioc': 'MEX',
            'number': '484',
            'tel': '52',
            'latitude': '23 00 N',
            'longitude': '102 00 W',
            'un': 'MX'
        },
        'MY': {
            'value': 'MY',
            'name': 'Malaysia',
            'names': [
                'Malaysia',
                'Malaisie',
                'Malasia'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'MYR',
            'alpha2': 'MY',
            'alpha3': 'MYS',
            'ioc': 'MAS',
            'number': '458',
            'tel': '60',
            'latitude': '2 30 N',
            'longitude': '112 30 E',
            'un': 'MY'
        },
        'MZ': {
            'value': 'MZ',
            'name': 'Mozambique',
            'names': [
                'Mozambique',
                'Mosambik',
                'Mozambique',
                'Mozambique'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'MZN',
            'alpha2': 'MZ',
            'alpha3': 'MOZ',
            'ioc': 'MOZ',
            'number': '508',
            'tel': '258',
            'latitude': '18 15 S',
            'longitude': '35 00 E',
            'un': 'MZ'
        },
        'NA': {
            'value': 'NA',
            'name': 'Namibia',
            'names': [
                'Namibia',
                'Namibia',
                'Namibie',
                'Namibia'
            ],
            'region': 'Africa',
            'subregion': 'Southern Africa',
            'currency': 'NAD',
            'alpha2': 'NA',
            'alpha3': 'NAM',
            'ioc': 'NAM',
            'number': '516',
            'tel': '264',
            'latitude': '22 00 S',
            'longitude': '17 00 E',
            'un': 'NA'
        },
        'NC': {
            'value': 'NC',
            'name': 'New Caledonia',
            'names': [
                'New Caledonia',
                'Neukaledonien',
                'Nouvelle Calédonie',
                'Nueva Caledonia'
            ],
            'region': 'Oceania',
            'subregion': 'Melanesia',
            'currency': 'XPF',
            'alpha2': 'NC',
            'alpha3': 'NCL',
            'ioc': null,
            'number': '540',
            'tel': '687',
            'latitude': '21 30 S',
            'longitude': '165 30 E',
            'un': 'NC'
        },
        'NE': {
            'value': 'NE',
            'name': 'Niger',
            'names': [
                'Níger'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'XOF',
            'alpha2': 'NE',
            'alpha3': 'NER',
            'ioc': 'NIG',
            'number': '562',
            'tel': '227',
            'latitude': '16 00 N',
            'longitude': '8 00 E',
            'un': 'NE'
        },
        'NF': {
            'value': 'NF',
            'name': 'Norfolk Island',
            'names': [
                'Norfolk Island',
                'Norfolk Island',
                'Île de Norfolk',
                'Isla de Norfolk'
            ],
            'region': 'Oceania',
            'subregion': 'Australia and New Zealand',
            'currency': 'AUD',
            'alpha2': 'NF',
            'alpha3': 'NFK',
            'ioc': null,
            'number': '574',
            'tel': '672',
            'latitude': '29 02 S',
            'longitude': '167 57 E',
            'un': 'NF'
        },
        'NG': {
            'value': 'NG',
            'name': 'Nigeria',
            'names': [
                'Nigeria',
                'Nigéria',
                'the Federal Republic of Nigeria'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'NGN',
            'alpha2': 'NG',
            'alpha3': 'NGA',
            'ioc': 'NGR',
            'number': '566',
            'tel': '234',
            'latitude': '10 00 N',
            'longitude': '8 00 E',
            'un': 'NG'
        },
        'NI': {
            'value': 'NI',
            'name': 'Nicaragua',
            'names': [
                'Nicaragua',
                'Nicaragua',
                'Nicaragua',
                'Nicaragua'
            ],
            'region': 'Americas',
            'subregion': 'Central America',
            'currency': 'NIO',
            'alpha2': 'NI',
            'alpha3': 'NIC',
            'ioc': 'NCA',
            'number': '558',
            'tel': '505',
            'latitude': '13 00 N',
            'longitude': '85 00 W',
            'un': 'NI'
        },
        'NL': {
            'value': 'NL',
            'name': 'Netherlands',
            'names': [
                'Netherlands',
                'Niederlande',
                'Pays-Bas',
                'Países Bajos'
            ],
            'region': 'Europe',
            'subregion': 'Western Europe',
            'currency': 'EUR',
            'alpha2': 'NL',
            'alpha3': 'NLD',
            'ioc': 'NED',
            'number': '528',
            'tel': '31',
            'latitude': '52 30 N',
            'longitude': '5 45 E',
            'un': 'NL'
        },
        'NO': {
            'value': 'NO',
            'name': 'Norway',
            'names': [
                'Norway',
                'Norwegen',
                'Norvège',
                'Noruega'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'NOK',
            'alpha2': 'NO',
            'alpha3': 'NOR',
            'ioc': 'NED',
            'number': '578',
            'tel': '47',
            'latitude': '62 00 N',
            'longitude': '10 00 E',
            'un': 'NL'
        },
        'NP': {
            'value': 'NP',
            'name': 'Nepal',
            'names': [
                'Nepal',
                'Nepal',
                'Népal',
                'Nepal',
                'the Federal Democratic Republic of Nepal'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'NPR',
            'alpha2': 'NP',
            'alpha3': 'NPL',
            'ioc': 'NEP',
            'number': '524',
            'tel': '977',
            'latitude': '28 00 N',
            'longitude': '84 00 E',
            'un': 'NP'
        },
        'NR': {
            'value': 'NR',
            'name': 'Nauru',
            'names': [
                'Nauru',
                'Nauru',
                'Nauru',
                'Nauru'
            ],
            'region': 'Oceania',
            'subregion': 'Micronesia',
            'currency': 'AUD',
            'alpha2': 'NR',
            'alpha3': 'NRU',
            'ioc': 'NRU',
            'number': '520',
            'tel': '674',
            'latitude': '0 32 S',
            'longitude': '166 55 E',
            'un': 'NR'
        },
        'NU': {
            'value': 'NU',
            'name': 'Niue',
            'names': [
                'Niue'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': null,
            'alpha2': 'NU',
            'alpha3': 'NIU',
            'ioc': null,
            'number': '570',
            'tel': '683',
            'latitude': '19 02 S',
            'longitude': '169 52 W',
            'un': 'NU'
        },
        'NZ': {
            'value': 'NZ',
            'name': 'New Zealand',
            'names': [
                'New Zealand',
                'Neuseeland',
                'Nouvelle ZÃ©lande',
                'Nueva Zelanda'
            ],
            'region': 'Oceania',
            'subregion': 'Australia and New Zealand',
            'currency': 'NZD',
            'alpha2': 'NZ',
            'alpha3': 'NZL',
            'ioc': 'NZL',
            'number': '554',
            'tel': '64',
            'latitude': '41 00 S',
            'longitude': '174 00 E',
            'un': 'NZ'
        },
        'OM': {
            'value': 'OM',
            'name': 'Oman',
            'names': [
                'Oman',
                'Omán'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'OMR',
            'alpha2': 'OM',
            'alpha3': 'OMN',
            'ioc': 'OMA',
            'number': '512',
            'tel': '968',
            'latitude': '21 00 N',
            'longitude': '57 00 E',
            'un': 'OM'
        },
        'PA': {
            'value': 'PA',
            'name': 'Panama',
            'names': [
                'Panama',
                'Panamá'
            ],
            'region': 'Americas',
            'subregion': 'Central America',
            'currency': 'PAB',
            'alpha2': 'PA',
            'alpha3': 'PAN',
            'ioc': 'PAN',
            'number': '591',
            'tel': '507',
            'latitude': '9 00 N',
            'longitude': '80 00 W',
            'un': 'PA'
        },
        'PE': {
            'value': 'PE',
            'name': 'Peru',
            'names': [
                'Peru',
                'Pérou',
                'Perú'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'PEN',
            'alpha2': 'PE',
            'alpha3': 'PER',
            'ioc': 'PER',
            'number': '604',
            'tel': '51',
            'latitude': '10 00 S',
            'longitude': '76 00 W',
            'un': 'PE'
        },
        'PF': {
            'value': 'PF',
            'name': 'French Polynesia',
            'names': [
                'French Polynesia',
                'Französisch-Polynesien',
                'Polynésie Française',
                'Polinesia Francesa'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'XPF',
            'alpha2': 'PF',
            'alpha3': 'PYF',
            'ioc': null,
            'number': '258',
            'tel': '689',
            'latitude': '15 00 S',
            'longitude': '140 00 W',
            'un': 'PF'
        },
        'PG': {
            'value': 'PG',
            'name': 'Papua New Guinea',
            'names': [
                'Papua New Guinea',
                'Papua-Neuguinea',
                'Papouasie Nouvelle-Guinée',
                'Papúa Nueva Guinea'
            ],
            'region': 'Oceania',
            'subregion': 'Melanesia',
            'currency': 'PGK',
            'alpha2': 'PG',
            'alpha3': 'PNG',
            'ioc': 'PNG',
            'number': '598',
            'tel': '675',
            'latitude': '6 00 S',
            'longitude': '147 00 E',
            'un': 'PG'
        },
        'PH': {
            'value': 'PH',
            'name': 'Philippines',
            'names': [
                'Philippinen',
                'Philippines',
                'Filipinas'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'PHP',
            'alpha2': 'PH',
            'alpha3': 'PHL',
            'ioc': 'PHI',
            'number': '608',
            'tel': '63',
            'latitude': '13 00 N',
            'longitude': '122 00 E',
            'un': 'PH'
        },
        'PK': {
            'value': 'PK',
            'name': 'Pakistan',
            'names': [
                'Pakistan',
                'Paquistán'
            ],
            'region': 'Asia',
            'subregion': 'Southern Asia',
            'currency': 'PKR',
            'alpha2': 'PK',
            'alpha3': 'PAK',
            'ioc': 'PAK',
            'number': '586',
            'tel': '92',
            'latitude': '30 00 N',
            'longitude': '70 00 E',
            'un': 'PK'
        },
        'PL': {
            'value': 'PL',
            'name': 'Poland',
            'names': [
                'Poland',
                'Polen',
                'Pologne',
                'Polonia'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'PLN',
            'alpha2': 'PL',
            'alpha3': 'POL',
            'ioc': 'POL',
            'number': '616',
            'tel': '48',
            'latitude': '52 00 N',
            'longitude': '20 00 E',
            'un': 'PL'
        },
        'PM': {
            'value': 'PM',
            'name': 'Saint Pierre And Miquelon',
            'names': [
                'Saint Pierre and Miquelon',
                'Saint-Pierre-et-Miquelon',
                'Saint-Pierre-et-Miquelon',
                'San Pedro y Miquelón'
            ],
            'region': 'Americas',
            'subregion': 'Northern America',
            'currency': 'EUR',
            'alpha2': 'PM',
            'alpha3': 'SPM',
            'ioc': null,
            'number': '666',
            'tel': '508',
            'latitude': '46 50 N',
            'longitude': '56 20 W',
            'un': 'PM'
        },
        'PN': {
            'value': 'PN',
            'name': 'Pitcairn',
            'names': [
                'Pitcairn'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'NZD',
            'alpha2': 'PN',
            'alpha3': 'PCN',
            'ioc': null,
            'number': '612',
            'tel': '',
            'latitude': '25 04 S',
            'longitude': '130 06 W',
            'un': 'PN'
        },
        'PR': {
            'value': 'PR',
            'name': 'Puerto Rico',
            'names': [
                'Puerto Rico'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'USD',
            'alpha2': 'PR',
            'alpha3': 'PRI',
            'ioc': 'PUR',
            'number': '630',
            'tel': '1',
            'latitude': '18 15 N',
            'longitude': '66 30 W',
            'un': 'PR'
        },
        'PS': {
            'value': 'PS',
            'name': 'Palestinian Territory, Occupied',
            'names': [
                'Palestine',
                'Palästina',
                'Palestine',
                'Palestina',
                'the Occupied Palestinian Territory'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': null,
            'alpha2': 'PS',
            'alpha3': 'PSE',
            'ioc': 'PLE',
            'number': '275',
            'tel': '970',
            'latitude': '',
            'longitude': '',
            'un': null
        },
        'PT': {
            'value': 'PT',
            'name': 'Portugal',
            'names': [
                'Portugal'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'PT',
            'alpha3': 'PRT',
            'ioc': 'POR',
            'number': '620',
            'tel': '351',
            'latitude': '39 30 N',
            'longitude': '8 00 W',
            'un': 'PT'
        },
        'PW': {
            'value': 'PW',
            'name': 'Palau',
            'names': [
                'Palau'
            ],
            'region': 'Oceania',
            'subregion': 'Micronesia',
            'currency': 'USD',
            'alpha2': 'PW',
            'alpha3': 'PLW',
            'ioc': 'PLW',
            'number': '585',
            'tel': '680',
            'latitude': '7 30 N',
            'longitude': '134 30 E',
            'un': 'PW'
        },
        'PY': {
            'value': 'PY',
            'name': 'Paraguay',
            'names': [
                'Paraguay'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'PYG',
            'alpha2': 'PY',
            'alpha3': 'PRY',
            'ioc': 'PAR',
            'number': '600',
            'tel': '595',
            'latitude': '23 00 S',
            'longitude': '58 00 W',
            'un': 'PY'
        },
        'QA': {
            'value': 'QA',
            'name': 'Qatar',
            'names': [
                'Qatar',
                'Katar'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'QAR',
            'alpha2': 'QA',
            'alpha3': 'QAT',
            'ioc': 'QAT',
            'number': '634',
            'tel': '974',
            'latitude': '25 30 N',
            'longitude': '51 15 E',
            'un': 'QA'
        },
        'RE': {
            'value': 'RE',
            'name': 'Réunion',
            'names': [
                'Réunion',
                'Réunion',
                'Réunion',
                'Reunión'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'EUR',
            'alpha2': 'RE',
            'alpha3': 'REU',
            'ioc': null,
            'number': '638',
            'tel': '262',
            'latitude': '',
            'longitude': '',
            'un': 'RE'
        },
        'RO': {
            'value': 'RO',
            'name': 'Romania',
            'names': [
                'Romania',
                'Rumänien',
                'Roumanie',
                'Rumania'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'RON',
            'alpha2': 'RO',
            'alpha3': 'ROU',
            'ioc': 'ROU',
            'number': '642',
            'tel': '40',
            'latitude': '46 00 N',
            'longitude': '25 00 E',
            'un': 'RO'
        },
        'RS': {
            'value': 'RS',
            'name': 'Serbia',
            'names': [
                'Serbia',
                'Serbien',
                'Serbie',
                'Serbia'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'RSD',
            'alpha2': 'RS',
            'alpha3': 'SRB',
            'ioc': 'SRB',
            'number': '688',
            'tel': '381',
            'latitude': '44 00 N',
            'longitude': '21 00 E',
            'un': 'RS'
        },
        'RU': {
            'value': 'RU',
            'name': 'Russian Federation',
            'names': [
                'Russia',
                'Russland',
                'Russie',
                'Rusia'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'RUB',
            'alpha2': 'RU',
            'alpha3': 'RUS',
            'ioc': 'RUS',
            'number': '643',
            'tel': '7',
            'latitude': '60 00 N',
            'longitude': '100 00 E',
            'un': 'RU'
        },
        'RW': {
            'value': 'RW',
            'name': 'Rwanda',
            'names': [
                'Rwanda',
                'Ruanda'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'RWF',
            'alpha2': 'RW',
            'alpha3': 'RWA',
            'ioc': 'RWA',
            'number': '646',
            'tel': '250',
            'latitude': '2 00 S',
            'longitude': '30 00 E',
            'un': 'RW'
        },
        'SA': {
            'value': 'SA',
            'name': 'Saudi Arabia',
            'names': [
                'Saudi Arabia',
                'Saudi-Arabien',
                'Arabie Saoudite',
                'Arabia Saudí'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'SAR',
            'alpha2': 'SA',
            'alpha3': 'SAU',
            'ioc': 'KSA',
            'number': '682',
            'tel': '966',
            'latitude': '25 00 N',
            'longitude': '45 00 E',
            'un': 'SA'
        },
        'SB': {
            'value': 'SB',
            'name': 'Solomon Islands',
            'names': [
                'Solomon Islands',
                'Salomonen',
                'Îles Salomon',
                'Islas Salomón'
            ],
            'region': 'Oceania',
            'subregion': 'Melanesia',
            'currency': 'SBD',
            'alpha2': 'SB',
            'alpha3': 'SLB',
            'ioc': 'SOL',
            'number': '090',
            'tel': '677',
            'latitude': '8 00 S',
            'longitude': '159 00 E',
            'un': 'SB'
        },
        'SC': {
            'value': 'SC',
            'name': 'Seychelles',
            'names': [
                'Seychelles'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'SCR',
            'alpha2': 'SC',
            'alpha3': 'SYC',
            'ioc': 'SEY',
            'number': '690',
            'tel': '248',
            'latitude': '4 35 S',
            'longitude': '55 40 E',
            'un': 'SC'
        },
        'SD': {
            'value': 'SD',
            'name': 'Sudan',
            'names': [
                'Sudan',
                'Soudan',
                'Sudán'
            ],
            'region': 'Africa',
            'subregion': 'Northern Africa',
            'currency': 'SDG',
            'alpha2': 'SD',
            'alpha3': 'SDN',
            'ioc': 'SUD',
            'number': '736',
            'tel': '249',
            'latitude': '15 00 N',
            'longitude': '30 00 E',
            'un': 'SD'
        },
        'SE': {
            'value': 'SE',
            'name': 'Sweden',
            'names': [
                'Sweden',
                'Schweden',
                'Suède',
                'Suecia'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'SEK',
            'alpha2': 'SE',
            'alpha3': 'SWE',
            'ioc': 'SWE',
            'number': '752',
            'tel': '46',
            'latitude': '62 00 N',
            'longitude': '15 00 E',
            'un': 'SE'
        },
        'SG': {
            'value': 'SG',
            'name': 'Singapore',
            'names': [
                'Singapore',
                'Singapur',
                'Singapour',
                'Singapur'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'SGD',
            'alpha2': 'SG',
            'alpha3': 'SGP',
            'ioc': 'SIN',
            'number': '702',
            'tel': '65',
            'latitude': '1 22 N',
            'longitude': '103 48 E',
            'un': 'SG'
        },
        'SH': {
            'value': 'SH',
            'name': 'Saint Helena',
            'names': [
                'Saint Helena',
                'Sankt Helena',
                'Sainte Hélène',
                'Santa Helena'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'SHP',
            'alpha2': 'SH',
            'alpha3': 'SHN',
            'ioc': null,
            'number': '654',
            'tel': '290',
            'latitude': 'Saint Helena: 15 57 ',
            'longitude': 'Saint Helena: 5 42 W',
            'un': 'SH'
        },
        'SI': {
            'value': 'SI',
            'name': 'Slovenia',
            'names': [
                'Slovenia',
                'Slowenien',
                'Slovénie',
                'Eslovenia'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'SI',
            'alpha3': 'SVN',
            'ioc': 'SLO',
            'number': '705',
            'tel': '386',
            'latitude': '46 07 N',
            'longitude': '14 49 E',
            'un': 'SI'
        },
        'SJ': {
            'value': 'SJ',
            'name': 'Svalbard And Jan Mayen',
            'names': [
                'Svalbard and Jan Mayen',
                'Svalbard und Jan Mayen',
                'Îles Svalbard et Jan Mayen',
                'Islas Svalbard y Jan Mayen'
            ],
            'region': 'Europe',
            'subregion': 'Northern Europe',
            'currency': 'NOK',
            'alpha2': 'SJ',
            'alpha3': 'SJM',
            'ioc': null,
            'number': '744',
            'tel': '47',
            'latitude': '78 00 N',
            'longitude': '20 00 E',
            'un': 'SJ'
        },
        'SK': {
            'value': 'SK',
            'name': 'Slovakia',
            'names': [
                'Slovakia',
                'Slowakei',
                'Slovaquie',
                'República Eslovaca'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'EUR',
            'alpha2': 'SK',
            'alpha3': 'SVK',
            'ioc': 'SVK',
            'number': '703',
            'tel': '421',
            'latitude': '48 40 N',
            'longitude': '19 30 E',
            'un': 'SK'
        },
        'SL': {
            'value': 'SL',
            'name': 'Sierra Leone',
            'names': [
                'Sierra Leone'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'SLL',
            'alpha2': 'SL',
            'alpha3': 'SLE',
            'ioc': 'SLE',
            'number': '694',
            'tel': '232',
            'latitude': '8 30 N',
            'longitude': '11 30 W',
            'un': 'SL'
        },
        'SM': {
            'value': 'SM',
            'name': 'San Marino',
            'names': [
                'San Marino',
                'Saint-Marin'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'SM',
            'alpha3': 'SMR',
            'ioc': 'SMR',
            'number': '674',
            'tel': '378',
            'latitude': '43 46 N',
            'longitude': '12 25 E',
            'un': 'SM'
        },
        'SN': {
            'value': 'SN',
            'name': 'Senegal',
            'names': [
                'Senegal',
                'Sénégal'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'XOF',
            'alpha2': 'SN',
            'alpha3': 'SEN',
            'ioc': 'SEN',
            'number': '686',
            'tel': '221',
            'latitude': '14 00 N',
            'longitude': '14 00 W',
            'un': 'SN'
        },
        'SO': {
            'value': 'SO',
            'name': 'Somalia',
            'names': [
                'Somalia'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'SOS',
            'alpha2': 'SO',
            'alpha3': 'SOM',
            'ioc': 'SOM',
            'number': '706',
            'tel': '252',
            'latitude': '10 00 N',
            'longitude': '49 00 E',
            'un': 'SO'
        },
        'SR': {
            'value': 'SR',
            'name': 'Suriname',
            'names': [
                'Suriname',
                'Surinam'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'SRD',
            'alpha2': 'SR',
            'alpha3': 'SUR',
            'ioc': 'SUR',
            'number': '740',
            'tel': '597',
            'latitude': '4 00 N',
            'longitude': '56 00 W',
            'un': 'SR'
        },
        'ST': {
            'value': 'ST',
            'name': 'Sao Tome and Principe',
            'names': [
                'São Tomé and Príncipe',
                'São Tomé und Príncipe',
                'São Tomé et Príncipe',
                'Santo Tomé y Príncipe'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': 'STD',
            'alpha2': 'ST',
            'alpha3': 'STP',
            'ioc': 'STP',
            'number': '678',
            'tel': '239',
            'latitude': '1 00 N',
            'longitude': '7 00 E',
            'un': 'ST'
        },
        'SV': {
            'value': 'SV',
            'name': 'El Salvador',
            'names': [
                'Salvador',
                'El Salvador'
            ],
            'region': 'Americas',
            'subregion': 'Central America',
            'currency': 'USD',
            'alpha2': 'SV',
            'alpha3': 'SLV',
            'ioc': 'ESA',
            'number': '222',
            'tel': '503',
            'latitude': '13 50 N',
            'longitude': '88 55 W',
            'un': 'SV'
        },
        'SY': {
            'value': 'SY',
            'name': 'Syrian Arab Republic',
            'names': [
                'Syria',
                'Syrien',
                'Syrie',
                'Siria'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'SYP',
            'alpha2': 'SY',
            'alpha3': 'SYR',
            'ioc': 'SYR',
            'number': '760',
            'tel': '963',
            'latitude': '35 00 N',
            'longitude': '38 00 E',
            'un': 'SY'
        },
        'SZ': {
            'value': 'SZ',
            'name': 'Swaziland',
            'names': [
                'Swaziland',
                'Swasiland',
                'Swaziland',
                'Suazilandia'
            ],
            'region': 'Africa',
            'subregion': 'Southern Africa',
            'currency': 'SZL',
            'alpha2': 'SZ',
            'alpha3': 'SWZ',
            'ioc': 'SWZ',
            'number': '748',
            'tel': '268',
            'latitude': '26 30 S',
            'longitude': '31 30 E',
            'un': 'SZ'
        },
        'TC': {
            'value': 'TC',
            'name': 'Turks and Caicos Islands',
            'names': [
                'Turks and Caicos Islands',
                'Turks- und Caicosinseln',
                'Îles Turks et Caïcos',
                'Islas Turks y Caicos'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'USD',
            'alpha2': 'TC',
            'alpha3': 'TCA',
            'ioc': null,
            'number': '796',
            'tel': '1',
            'latitude': '21 45 N',
            'longitude': '71 35 W',
            'un': 'TC'
        },
        'TD': {
            'value': 'TD',
            'name': 'Chad',
            'names': [
                'Chad',
                'Tschad',
                'Tchad'
            ],
            'region': 'Africa',
            'subregion': 'Middle Africa',
            'currency': 'XAF',
            'alpha2': 'TD',
            'alpha3': 'TCD',
            'ioc': 'CHA',
            'number': '148',
            'tel': '235',
            'latitude': '15 00 N',
            'longitude': '19 00 E',
            'un': 'TD'
        },
        'TF': {
            'value': 'TF',
            'name': 'French Southern Territories',
            'names': [
                'French Southern Territories',
                'Französische Südgebiete',
                'Terres Australes Françaises',
                'Territorios Franceses del Sur'
            ],
            'region': '',
            'subregion': '',
            'currency': 'EUR',
            'alpha2': 'TF',
            'alpha3': 'ATF',
            'ioc': null,
            'number': '260',
            'tel': '',
            'latitude': '',
            'longitude': '',
            'un': null
        },
        'TG': {
            'value': 'TG',
            'name': 'Togo',
            'names': [
                'Togo'
            ],
            'region': 'Africa',
            'subregion': 'Western Africa',
            'currency': 'XOF',
            'alpha2': 'TG',
            'alpha3': 'TGO',
            'ioc': 'TOG',
            'number': '768',
            'tel': '228',
            'latitude': '8 00 N',
            'longitude': '1 10 E',
            'un': 'TG'
        },
        'TH': {
            'value': 'TH',
            'name': 'Thailand',
            'names': [
                'Thailand',
                'Thailand',
                'Thaïlande',
                'Tailandia'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'THB',
            'alpha2': 'TH',
            'alpha3': 'THA',
            'ioc': 'THA',
            'number': '764',
            'tel': '66',
            'latitude': '15 00 N',
            'longitude': '100 00 E',
            'un': 'TH'
        },
        'TJ': {
            'value': 'TJ',
            'name': 'Tajikistan',
            'names': [
                'Tajikistan',
                'Tadschikistan',
                'Tajikistan',
                'Tayikistán'
            ],
            'region': 'Asia',
            'subregion': 'Central Asia',
            'currency': 'TJS',
            'alpha2': 'TJ',
            'alpha3': 'TJK',
            'ioc': 'TJK',
            'number': '762',
            'tel': '992',
            'latitude': '39 00 N',
            'longitude': '71 00 E',
            'un': 'TJ'
        },
        'TK': {
            'value': 'TK',
            'name': 'Tokelau',
            'names': [
                'Tokelau',
                'Tokelau',
                'Îles Tokelau',
                'Islas Tokelau'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'NZD',
            'alpha2': 'TK',
            'alpha3': 'TKL',
            'ioc': null,
            'number': '772',
            'tel': '690',
            'latitude': '9 00 S',
            'longitude': '172 00 W',
            'un': 'TK'
        },
        'TL': {
            'value': 'TL',
            'name': 'Timor-Leste',
            'names': [
                'East Timor',
                'Ost-Timor',
                'Timor oriental',
                'Timor Oriental'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'IDR',
            'alpha2': 'TL',
            'alpha3': 'TLS',
            'ioc': null,
            'number': '626',
            'tel': '670',
            'latitude': '8 50 S',
            'longitude': '125 55 E',
            'un': null
        },
        'TM': {
            'value': 'TM',
            'name': 'Turkmenistan',
            'names': [
                'Turkmenistan',
                'Turkmenistan',
                'Turkménistan',
                'Turkmenistán'
            ],
            'region': 'Asia',
            'subregion': 'Central Asia',
            'currency': 'TMT',
            'alpha2': 'TM',
            'alpha3': 'TKM',
            'ioc': 'TKM',
            'number': '795',
            'tel': '993',
            'latitude': '40 00 N',
            'longitude': '60 00 E',
            'un': 'TM'
        },
        'TN': {
            'value': 'TN',
            'name': 'Tunisia',
            'names': [
                'Tunisia',
                'Tunesien',
                'Tunisie',
                'Túnez'
            ],
            'region': 'Africa',
            'subregion': 'Northern Africa',
            'currency': 'TND',
            'alpha2': 'TN',
            'alpha3': 'TUN',
            'ioc': 'TUN',
            'number': '788',
            'tel': '216',
            'latitude': '34 00 N',
            'longitude': '9 00 E',
            'un': 'TN'
        },
        'TO': {
            'value': 'TO',
            'name': 'Tonga',
            'names': [
                'Tonga'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'TOP',
            'alpha2': 'TO',
            'alpha3': 'TON',
            'ioc': 'TGA',
            'number': '776',
            'tel': '676',
            'latitude': '20 00 S',
            'longitude': '175 00 W',
            'un': 'TO'
        },
        'TR': {
            'value': 'TR',
            'name': 'Turkey',
            'names': [
                'Turkey',
                'Türkei',
                'Turquie',
                'Turquía'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'TRY',
            'alpha2': 'TR',
            'alpha3': 'TUR',
            'ioc': 'TUR',
            'number': '792',
            'tel': '90',
            'latitude': '39 00 N',
            'longitude': '35 00 E',
            'un': 'TR'
        },
        'TT': {
            'value': 'TT',
            'name': 'Trinidad and Tobago',
            'names': [
                'Trinidad and Tobago',
                'Trinidad und Tobago',
                'Trinité et Tobago',
                'Trinidad y Tobago'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'TTD',
            'alpha2': 'TT',
            'alpha3': 'TTO',
            'ioc': 'TRI',
            'number': '780',
            'tel': '1',
            'latitude': '11 00 N',
            'longitude': '61 00 W',
            'un': 'TT'
        },
        'TV': {
            'value': 'TV',
            'name': 'Tuvalu',
            'names': [
                'Tuvalu',
                'Tuvalu',
                'Tuvalu',
                'Tuvalu'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'TVD',
            'alpha2': 'TV',
            'alpha3': 'TUV',
            'ioc': 'TUV',
            'number': '798',
            'tel': '688',
            'latitude': '8 00 S',
            'longitude': '178 00 E',
            'un': 'TV'
        },
        'TW': {
            'value': 'TW',
            'name': 'Taiwan, Province Of China',
            'names': [
                'Taiwan',
                'Taiwán'
            ],
            'region': 'Asia',
            'subregion': 'Eastern Asia',
            'currency': 'TWD',
            'alpha2': 'TW',
            'alpha3': 'TWN',
            'ioc': 'TPE',
            'number': '158',
            'tel': '886',
            'latitude': '23 30 N',
            'longitude': '121 00 E',
            'un': 'TW',
            'commonname': 'Taiwan'
        },
        'TZ': {
            'value': 'TZ',
            'name': 'Tanzania, United Republic of',
            'names': [
                'Tanzania',
                'Tansania',
                'Tanzanie'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'TZS',
            'alpha2': 'TZ',
            'alpha3': 'TZA',
            'ioc': 'TAN',
            'number': '834',
            'tel': '255',
            'latitude': '6 00 S',
            'longitude': '35 00 E',
            'un': 'TZ',
            'commonname': 'Tanzania'
        },
        'UA': {
            'value': 'UA',
            'name': 'Ukraine',
            'names': [
                'Ukraine',
                'Ukraine',
                'Ukraine',
                'Ucrania'
            ],
            'region': 'Europe',
            'subregion': 'Eastern Europe',
            'currency': 'UAH',
            'alpha2': 'UA',
            'alpha3': 'UKR',
            'ioc': 'UKR',
            'number': '804',
            'tel': '380',
            'latitude': '49 00 N',
            'longitude': '32 00 E',
            'un': 'UA'
        },
        'UG': {
            'value': 'UG',
            'name': 'Uganda',
            'names': [
                'Uganda'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'UGX',
            'alpha2': 'UG',
            'alpha3': 'UGA',
            'ioc': 'UGA',
            'number': '800',
            'tel': '256',
            'latitude': '1 00 N',
            'longitude': '32 00 E',
            'un': 'UG'
        },
        'UM': {
            'value': 'UM',
            'name': 'United States Minor Outlying Islands',
            'names': [
                'United States Minor Outlying Islands',
                'US-Amerikanische Hoheitsgebiete',
                'Dépendances américaines',
                'Islas menores de Estados Unidos'
            ],
            'region': 'Americas',
            'subregion': 'Northern America',
            'currency': 'USD',
            'alpha2': 'UM',
            'alpha3': 'UMI',
            'ioc': null,
            'number': '581',
            'tel': '',
            'latitude': '',
            'longitude': '',
            'un': 'UM'
        },
        'US': {
            'value': 'US',
            'name': 'United States',
            'names': [
                'United States of America',
                'Vereinigte Staaten von Amerika',
                'États-Unis',
                'Estados Unidos'
            ],
            'region': 'Americas',
            'subregion': 'Northern America',
            'currency': 'USD',
            'alpha2': 'US',
            'alpha3': 'USA',
            'ioc': 'USA',
            'number': '840',
            'tel': '1',
            'latitude': '38 00 N',
            'longitude': '97 00 W',
            'un': 'US'
        },
        'UY': {
            'value': 'UY',
            'name': 'Uruguay',
            'names': [
                'Uruguay'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'UYU',
            'alpha2': 'UY',
            'alpha3': 'URY',
            'ioc': 'URU',
            'number': '858',
            'tel': '598',
            'latitude': '33 00 S',
            'longitude': '56 00 W',
            'un': 'UY'
        },
        'UZ': {
            'value': 'UZ',
            'name': 'Uzbekistan',
            'names': [
                'Uzbekistan',
                'Usbekistan',
                'Ouzbékistan',
                'Uzbekistán'
            ],
            'region': 'Asia',
            'subregion': 'Central Asia',
            'currency': 'UZS',
            'alpha2': 'UZ',
            'alpha3': 'UZB',
            'ioc': 'UZB',
            'number': '860',
            'tel': '998',
            'latitude': '41 00 N',
            'longitude': '64 00 E',
            'un': 'UZ'
        },
        'VA': {
            'value': 'VA',
            'name': 'Holy See (Vatican City State)',
            'names': [
                'Vatican City',
                'Vatikan',
                'Cité du Vatican',
                'Ciudad del Vaticano'
            ],
            'region': 'Europe',
            'subregion': 'Southern Europe',
            'currency': 'EUR',
            'alpha2': 'VA',
            'alpha3': 'VAT',
            'ioc': null,
            'number': '336',
            'tel': '39',
            'latitude': '41 54 N',
            'longitude': '12 27 E',
            'un': 'VA'
        },
        'VC': {
            'value': 'VC',
            'name': 'Saint Vincent And The Grenedines',
            'names': [
                'Saint Vincent and the Grenadines',
                'Saint Vincent und die Grenadinen',
                'Saint-Vincent et les Grenadines',
                'San Vicente y Granadinas'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'XCD',
            'alpha2': 'VC',
            'alpha3': 'VCT',
            'ioc': 'VIN',
            'number': '670',
            'tel': '1',
            'latitude': '13 15 N',
            'longitude': '61 12 W',
            'un': 'VC'
        },
        'VE': {
            'value': 'VE',
            'name': 'Venezuela, Bolivarian Republic of',
            'names': [
                'Venezuela'
            ],
            'region': 'Americas',
            'subregion': 'South America',
            'currency': 'VEF',
            'alpha2': 'VE',
            'alpha3': 'VEN',
            'ioc': 'VEN',
            'number': '862',
            'tel': '58',
            'latitude': '8 00 N',
            'longitude': '66 00 W',
            'un': 'VE',
            'commonname': 'Venezuela'
        },
        'VG': {
            'value': 'VG',
            'name': 'Virgin Islands, British',
            'names': [
                'British Virgin Islands',
                'Britische Jungferninseln',
                'Îles Vierges britanniques',
                'Islas Vírgenes del Reino Unido'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'USD',
            'alpha2': 'VG',
            'alpha3': 'VGB',
            'ioc': 'IVB',
            'number': '092',
            'tel': '1',
            'latitude': '',
            'longitude': '',
            'un': 'VG'
        },
        'VI': {
            'value': 'VI',
            'name': 'Virgin Islands, U.S.',
            'names': [
                'Virgin Islands of the United States',
                'Amerikanische Jungferninseln',
                'Îles Vierges américaines',
                'Islas Vírgenes de los Estados Unidos'
            ],
            'region': 'Americas',
            'subregion': 'Caribbean',
            'currency': 'USD',
            'alpha2': 'VI',
            'alpha3': 'VIR',
            'ioc': 'ISV',
            'number': '850',
            'tel': '1',
            'latitude': '',
            'longitude': '',
            'un': 'VI'
        },
        'VN': {
            'value': 'VN',
            'name': 'Viet Nam',
            'names': [
                'Vietnam'
            ],
            'region': 'Asia',
            'subregion': 'South-Eastern Asia',
            'currency': 'VND',
            'alpha2': 'VN',
            'alpha3': 'VNM',
            'ioc': 'VIE',
            'number': '704',
            'tel': '84',
            'latitude': '16 10 N',
            'longitude': '107 50 E',
            'un': 'VN'
        },
        'VU': {
            'value': 'VU',
            'name': 'Vanuatu',
            'names': [
                'Vanuatu'
            ],
            'region': 'Oceania',
            'subregion': 'Melanesia',
            'currency': null,
            'alpha2': 'VU',
            'alpha3': 'VUT',
            'ioc': 'VAN',
            'number': '548',
            'tel': '678',
            'latitude': '16 00 S',
            'longitude': '167 00 E',
            'un': 'VU'
        },
        'WF': {
            'value': 'WF',
            'name': 'Wallis and Futuna',
            'names': [
                'Wallis and Futuna',
                'Wallis und Futuna',
                'Wallis et Futuna',
                'Wallis y Futuna'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'XPF',
            'alpha2': 'WF',
            'alpha3': 'WLF',
            'ioc': null,
            'number': '876',
            'tel': '681',
            'latitude': '13 18 S',
            'longitude': '176 12 W',
            'un': 'WF'
        },
        'WS': {
            'value': 'WS',
            'name': 'Samoa',
            'names': [
                'Samoa'
            ],
            'region': 'Oceania',
            'subregion': 'Polynesia',
            'currency': 'USD',
            'alpha2': 'WS',
            'alpha3': 'WSM',
            'ioc': 'SAM',
            'number': '882',
            'tel': '685',
            'latitude': '13 35 S',
            'longitude': '172 20 W',
            'un': 'WS'
        },
        'YE': {
            'value': 'YE',
            'name': 'Yemen',
            'names': [
                'Yemen',
                'Jemen',
                'Yémen'
            ],
            'region': 'Asia',
            'subregion': 'Western Asia',
            'currency': 'YER',
            'alpha2': 'YE',
            'alpha3': 'YEM',
            'ioc': 'YEM',
            'number': '887',
            'tel': '967',
            'latitude': '15 00 N',
            'longitude': '48 00 E',
            'un': 'YE'
        },
        'YT': {
            'value': 'YT',
            'name': 'Mayotte',
            'names': [
                'Mayotte',
                'Mayotte',
                'Mayotte',
                'Mayotte'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'EUR',
            'alpha2': 'YT',
            'alpha3': 'MYT',
            'ioc': null,
            'number': '175',
            'tel': '269',
            'latitude': '12 50 S',
            'longitude': '45 10 E',
            'un': 'YT'
        },
        'ZA': {
            'value': 'ZA',
            'name': 'South Africa',
            'names': [
                'South Africa',
                'Republik Südafrika',
                'Afrique du Sud',
                'República de Sudáfrica'
            ],
            'region': 'Africa',
            'subregion': 'Southern Africa',
            'currency': 'ZAR',
            'alpha2': 'ZA',
            'alpha3': 'ZAF',
            'ioc': 'RSA',
            'number': '710',
            'tel': '27',
            'latitude': '29 00 S',
            'longitude': '24 00 E',
            'un': 'ZA'
        },
        'ZM': {
            'value': 'ZM',
            'name': 'Zambia',
            'names': [
                'Zambia',
                'Sambia',
                'Zambie'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'ZMK',
            'alpha2': 'ZM',
            'alpha3': 'ZMB',
            'ioc': 'ZAM',
            'number': '894',
            'tel': '260',
            'latitude': '15 00 S',
            'longitude': '30 00 E',
            'un': 'ZM'
        },
        'ZW': {
            'value': 'ZW',
            'name': 'Zimbabwe',
            'names': [
                'Zimbabwe',
                'Simbabwe',
                'Zimbabue'
            ],
            'region': 'Africa',
            'subregion': 'Eastern Africa',
            'currency': 'ZWD',
            'alpha2': 'ZW',
            'alpha3': 'ZWE',
            'ioc': 'ZIM',
            'number': '716',
            'tel': '263',
            'latitude': '20 00 S',
            'longitude': '30 00 E',
            'un': 'ZW'
        }
    };

    self.currencies = {
        'EUR': {
            'value': 'EUR',
            'name': 'EUR',
            'countries': [
                'AD',
                'AT',
                'BE',
                'CY',
                'DE',
                'ES',
                'FI',
                'FR',
                'GF',
                'GP',
                'GR',
                'IE',
                'IT',
                'LU',
                'MC',
                'ME',
                'MF',
                'MQ',
                'MT',
                'NL',
                'PM',
                'PT',
                'RE',
                'SI',
                'SK',
                'SM',
                'TF',
                'VA',
                'YT'
            ]
        },
        'AED': {
            'value': 'AED',
            'name': 'AED',
            'countries': [
                'AE'
            ]
        },
        'AFN': {
            'value': 'AFN',
            'name': 'AFN',
            'countries': [
                'AF'
            ]
        },
        'XCD': {
            'value': 'XCD',
            'name': 'XCD',
            'countries': [
                'AG',
                'AI',
                'DM',
                'GD',
                'KN',
                'LC',
                'MS',
                'VC'
            ]
        },
        'ALL': {
            'value': 'ALL',
            'name': 'ALL',
            'countries': [
                'AL'
            ]
        },
        'AMD': {
            'value': 'AMD',
            'name': 'AMD',
            'countries': [
                'AM'
            ]
        },
        'ANG': {
            'value': 'ANG',
            'name': 'ANG',
            'countries': [
                'AN'
            ]
        },
        'AOA': {
            'value': 'AOA',
            'name': 'AOA',
            'countries': [
                'AO'
            ]
        },
        'null': {
            'value': null,
            'name': null,
            'countries': [
                'AQ',
                'AX',
                'BL',
                'CD',
                'CG',
                'GQ',
                'GS',
                'MG',
                'MK',
                'NU',
                'PS',
                'VU'
            ]
        },
        'ARS': {
            'value': 'ARS',
            'name': 'ARS',
            'countries': [
                'AR'
            ]
        },
        'USD': {
            'value': 'USD',
            'name': 'USD',
            'countries': [
                'AS',
                'EC',
                'FJ',
                'FM',
                'GU',
                'HT',
                'IO',
                'MH',
                'MP',
                'PR',
                'PW',
                'SV',
                'TC',
                'UM',
                'US',
                'VG',
                'VI',
                'WS'
            ]
        },
        'AUD': {
            'value': 'AUD',
            'name': 'AUD',
            'countries': [
                'AU',
                'CC',
                'CX',
                'HM',
                'KI',
                'NF',
                'NR'
            ]
        },
        'AWG': {
            'value': 'AWG',
            'name': 'AWG',
            'countries': [
                'AW'
            ]
        },
        'AZN': {
            'value': 'AZN',
            'name': 'AZN',
            'countries': [
                'AZ'
            ]
        },
        'BAM': {
            'value': 'BAM',
            'name': 'BAM',
            'countries': [
                'BA'
            ]
        },
        'BBD': {
            'value': 'BBD',
            'name': 'BBD',
            'countries': [
                'BB'
            ]
        },
        'BTD': {
            'value': 'BTD',
            'name': 'BTD',
            'countries': [
                'BD'
            ]
        },
        'XOF': {
            'value': 'XOF',
            'name': 'XOF',
            'countries': [
                'BF',
                'BJ',
                'CI',
                'GW',
                'ML',
                'NE',
                'SN',
                'TG'
            ]
        },
        'BGN': {
            'value': 'BGN',
            'name': 'BGN',
            'countries': [
                'BG'
            ]
        },
        'BHD': {
            'value': 'BHD',
            'name': 'BHD',
            'countries': [
                'BH'
            ]
        },
        'BIF': {
            'value': 'BIF',
            'name': 'BIF',
            'countries': [
                'BI'
            ]
        },
        'BMD': {
            'value': 'BMD',
            'name': 'BMD',
            'countries': [
                'BM'
            ]
        },
        'BND': {
            'value': 'BND',
            'name': 'BND',
            'countries': [
                'BN'
            ]
        },
        'BOB': {
            'value': 'BOB',
            'name': 'BOB',
            'countries': [
                'BO'
            ]
        },
        'BRL': {
            'value': 'BRL',
            'name': 'BRL',
            'countries': [
                'BR'
            ]
        },
        'BSD': {
            'value': 'BSD',
            'name': 'BSD',
            'countries': [
                'BS'
            ]
        },
        'BTN': {
            'value': 'BTN',
            'name': 'BTN',
            'countries': [
                'BT'
            ]
        },
        'NOK': {
            'value': 'NOK',
            'name': 'NOK',
            'countries': [
                'BV',
                'NO',
                'SJ'
            ]
        },
        'BWP': {
            'value': 'BWP',
            'name': 'BWP',
            'countries': [
                'BW'
            ]
        },
        'BYR': {
            'value': 'BYR',
            'name': 'BYR',
            'countries': [
                'BY'
            ]
        },
        'BZD': {
            'value': 'BZD',
            'name': 'BZD',
            'countries': [
                'BZ'
            ]
        },
        'CAD': {
            'value': 'CAD',
            'name': 'CAD',
            'countries': [
                'CA'
            ]
        },
        'XAF': {
            'value': 'XAF',
            'name': 'XAF',
            'countries': [
                'CF',
                'CM',
                'GA',
                'TD'
            ]
        },
        'CHF': {
            'value': 'CHF',
            'name': 'CHF',
            'countries': [
                'CH',
                'LI'
            ]
        },
        'NZD': {
            'value': 'NZD',
            'name': 'NZD',
            'countries': [
                'CK',
                'NZ',
                'PN',
                'TK'
            ]
        },
        'CLP': {
            'value': 'CLP',
            'name': 'CLP',
            'countries': [
                'CL'
            ]
        },
        'CNY': {
            'value': 'CNY',
            'name': 'CNY',
            'countries': [
                'CN'
            ]
        },
        'COP': {
            'value': 'COP',
            'name': 'COP',
            'countries': [
                'CO'
            ]
        },
        'CRC': {
            'value': 'CRC',
            'name': 'CRC',
            'countries': [
                'CR'
            ]
        },
        'CUP': {
            'value': 'CUP',
            'name': 'CUP',
            'countries': [
                'CU'
            ]
        },
        'CVE': {
            'value': 'CVE',
            'name': 'CVE',
            'countries': [
                'CV'
            ]
        },
        'CZK': {
            'value': 'CZK',
            'name': 'CZK',
            'countries': [
                'CZ'
            ]
        },
        'DJF': {
            'value': 'DJF',
            'name': 'DJF',
            'countries': [
                'DJ'
            ]
        },
        'DKK': {
            'value': 'DKK',
            'name': 'DKK',
            'countries': [
                'DK',
                'FO',
                'GL'
            ]
        },
        'DOP': {
            'value': 'DOP',
            'name': 'DOP',
            'countries': [
                'DO'
            ]
        },
        'DZD': {
            'value': 'DZD',
            'name': 'DZD',
            'countries': [
                'DZ'
            ]
        },
        'EEK': {
            'value': 'EEK',
            'name': 'EEK',
            'countries': [
                'EE'
            ]
        },
        'EGP': {
            'value': 'EGP',
            'name': 'EGP',
            'countries': [
                'EG'
            ]
        },
        'MAD': {
            'value': 'MAD',
            'name': 'MAD',
            'countries': [
                'EH',
                'MA'
            ]
        },
        'ETB': {
            'value': 'ETB',
            'name': 'ETB',
            'countries': [
                'ER',
                'ET'
            ]
        },
        'FKP': {
            'value': 'FKP',
            'name': 'FKP',
            'countries': [
                'FK'
            ]
        },
        'GBP': {
            'value': 'GBP',
            'name': 'GBP',
            'countries': [
                'GB'
            ]
        },
        'GEL': {
            'value': 'GEL',
            'name': 'GEL',
            'countries': [
                'GE'
            ]
        },
        'GGP': {
            'value': 'GGP',
            'name': 'GGP',
            'countries': [
                'GG'
            ]
        },
        'GHS': {
            'value': 'GHS',
            'name': 'GHS',
            'countries': [
                'GH'
            ]
        },
        'GIP': {
            'value': 'GIP',
            'name': 'GIP',
            'countries': [
                'GI'
            ]
        },
        'GMD': {
            'value': 'GMD',
            'name': 'GMD',
            'countries': [
                'GM'
            ]
        },
        'GNF': {
            'value': 'GNF',
            'name': 'GNF',
            'countries': [
                'GN'
            ]
        },
        'GTQ': {
            'value': 'GTQ',
            'name': 'GTQ',
            'countries': [
                'GT'
            ]
        },
        'GYD': {
            'value': 'GYD',
            'name': 'GYD',
            'countries': [
                'GY'
            ]
        },
        'HKD': {
            'value': 'HKD',
            'name': 'HKD',
            'countries': [
                'HK'
            ]
        },
        'HNL': {
            'value': 'HNL',
            'name': 'HNL',
            'countries': [
                'HN'
            ]
        },
        'HRK': {
            'value': 'HRK',
            'name': 'HRK',
            'countries': [
                'HR'
            ]
        },
        'HUF': {
            'value': 'HUF',
            'name': 'HUF',
            'countries': [
                'HU'
            ]
        },
        'IDR': {
            'value': 'IDR',
            'name': 'IDR',
            'countries': [
                'ID',
                'TL'
            ]
        },
        'ILS': {
            'value': 'ILS',
            'name': 'ILS',
            'countries': [
                'IL'
            ]
        },
        'IMP': {
            'value': 'IMP',
            'name': 'IMP',
            'countries': [
                'IM'
            ]
        },
        'INR': {
            'value': 'INR',
            'name': 'INR',
            'countries': [
                'IN'
            ]
        },
        'IQD': {
            'value': 'IQD',
            'name': 'IQD',
            'countries': [
                'IQ'
            ]
        },
        'IRR': {
            'value': 'IRR',
            'name': 'IRR',
            'countries': [
                'IR'
            ]
        },
        'ISK': {
            'value': 'ISK',
            'name': 'ISK',
            'countries': [
                'IS'
            ]
        },
        'JEP': {
            'value': 'JEP',
            'name': 'JEP',
            'countries': [
                'JE'
            ]
        },
        'JMD': {
            'value': 'JMD',
            'name': 'JMD',
            'countries': [
                'JM'
            ]
        },
        'JOD': {
            'value': 'JOD',
            'name': 'JOD',
            'countries': [
                'JO'
            ]
        },
        'JPY': {
            'value': 'JPY',
            'name': 'JPY',
            'countries': [
                'JP'
            ]
        },
        'KES': {
            'value': 'KES',
            'name': 'KES',
            'countries': [
                'KE'
            ]
        },
        'KGS': {
            'value': 'KGS',
            'name': 'KGS',
            'countries': [
                'KG'
            ]
        },
        'KHR': {
            'value': 'KHR',
            'name': 'KHR',
            'countries': [
                'KH'
            ]
        },
        'KMF': {
            'value': 'KMF',
            'name': 'KMF',
            'countries': [
                'KM'
            ]
        },
        'KPW': {
            'value': 'KPW',
            'name': 'KPW',
            'countries': [
                'KP'
            ]
        },
        'KRW': {
            'value': 'KRW',
            'name': 'KRW',
            'countries': [
                'KR'
            ]
        },
        'KWD': {
            'value': 'KWD',
            'name': 'KWD',
            'countries': [
                'KW'
            ]
        },
        'KYD': {
            'value': 'KYD',
            'name': 'KYD',
            'countries': [
                'KY'
            ]
        },
        'KZT': {
            'value': 'KZT',
            'name': 'KZT',
            'countries': [
                'KZ'
            ]
        },
        'LAK': {
            'value': 'LAK',
            'name': 'LAK',
            'countries': [
                'LA'
            ]
        },
        'LBP': {
            'value': 'LBP',
            'name': 'LBP',
            'countries': [
                'LB'
            ]
        },
        'LKR': {
            'value': 'LKR',
            'name': 'LKR',
            'countries': [
                'LK'
            ]
        },
        'LRD': {
            'value': 'LRD',
            'name': 'LRD',
            'countries': [
                'LR'
            ]
        },
        'LSL': {
            'value': 'LSL',
            'name': 'LSL',
            'countries': [
                'LS'
            ]
        },
        'LTL': {
            'value': 'LTL',
            'name': 'LTL',
            'countries': [
                'LT'
            ]
        },
        'LVL': {
            'value': 'LVL',
            'name': 'LVL',
            'countries': [
                'LV'
            ]
        },
        'LYD': {
            'value': 'LYD',
            'name': 'LYD',
            'countries': [
                'LY'
            ]
        },
        'MDL': {
            'value': 'MDL',
            'name': 'MDL',
            'countries': [
                'MD'
            ]
        },
        'MNK': {
            'value': 'MNK',
            'name': 'MNK',
            'countries': [
                'MM'
            ]
        },
        'MNT': {
            'value': 'MNT',
            'name': 'MNT',
            'countries': [
                'MN'
            ]
        },
        'MOP': {
            'value': 'MOP',
            'name': 'MOP',
            'countries': [
                'MO'
            ]
        },
        'MRO': {
            'value': 'MRO',
            'name': 'MRO',
            'countries': [
                'MR'
            ]
        },
        'MUR': {
            'value': 'MUR',
            'name': 'MUR',
            'countries': [
                'MU'
            ]
        },
        'MVR': {
            'value': 'MVR',
            'name': 'MVR',
            'countries': [
                'MV'
            ]
        },
        'MWK': {
            'value': 'MWK',
            'name': 'MWK',
            'countries': [
                'MW'
            ]
        },
        'MXN': {
            'value': 'MXN',
            'name': 'MXN',
            'countries': [
                'MX'
            ]
        },
        'MYR': {
            'value': 'MYR',
            'name': 'MYR',
            'countries': [
                'MY'
            ]
        },
        'MZN': {
            'value': 'MZN',
            'name': 'MZN',
            'countries': [
                'MZ'
            ]
        },
        'NAD': {
            'value': 'NAD',
            'name': 'NAD',
            'countries': [
                'NA'
            ]
        },
        'XPF': {
            'value': 'XPF',
            'name': 'XPF',
            'countries': [
                'NC',
                'PF',
                'WF'
            ]
        },
        'NGN': {
            'value': 'NGN',
            'name': 'NGN',
            'countries': [
                'NG'
            ]
        },
        'NIO': {
            'value': 'NIO',
            'name': 'NIO',
            'countries': [
                'NI'
            ]
        },
        'NPR': {
            'value': 'NPR',
            'name': 'NPR',
            'countries': [
                'NP'
            ]
        },
        'OMR': {
            'value': 'OMR',
            'name': 'OMR',
            'countries': [
                'OM'
            ]
        },
        'PAB': {
            'value': 'PAB',
            'name': 'PAB',
            'countries': [
                'PA'
            ]
        },
        'PEN': {
            'value': 'PEN',
            'name': 'PEN',
            'countries': [
                'PE'
            ]
        },
        'PGK': {
            'value': 'PGK',
            'name': 'PGK',
            'countries': [
                'PG'
            ]
        },
        'PHP': {
            'value': 'PHP',
            'name': 'PHP',
            'countries': [
                'PH'
            ]
        },
        'PKR': {
            'value': 'PKR',
            'name': 'PKR',
            'countries': [
                'PK'
            ]
        },
        'PLN': {
            'value': 'PLN',
            'name': 'PLN',
            'countries': [
                'PL'
            ]
        },
        'PYG': {
            'value': 'PYG',
            'name': 'PYG',
            'countries': [
                'PY'
            ]
        },
        'QAR': {
            'value': 'QAR',
            'name': 'QAR',
            'countries': [
                'QA'
            ]
        },
        'RON': {
            'value': 'RON',
            'name': 'RON',
            'countries': [
                'RO'
            ]
        },
        'RSD': {
            'value': 'RSD',
            'name': 'RSD',
            'countries': [
                'RS'
            ]
        },
        'RUB': {
            'value': 'RUB',
            'name': 'RUB',
            'countries': [
                'RU'
            ]
        },
        'RWF': {
            'value': 'RWF',
            'name': 'RWF',
            'countries': [
                'RW'
            ]
        },
        'SAR': {
            'value': 'SAR',
            'name': 'SAR',
            'countries': [
                'SA'
            ]
        },
        'SBD': {
            'value': 'SBD',
            'name': 'SBD',
            'countries': [
                'SB'
            ]
        },
        'SCR': {
            'value': 'SCR',
            'name': 'SCR',
            'countries': [
                'SC'
            ]
        },
        'SDG': {
            'value': 'SDG',
            'name': 'SDG',
            'countries': [
                'SD'
            ]
        },
        'SEK': {
            'value': 'SEK',
            'name': 'SEK',
            'countries': [
                'SE'
            ]
        },
        'SGD': {
            'value': 'SGD',
            'name': 'SGD',
            'countries': [
                'SG'
            ]
        },
        'SHP': {
            'value': 'SHP',
            'name': 'SHP',
            'countries': [
                'SH'
            ]
        },
        'SLL': {
            'value': 'SLL',
            'name': 'SLL',
            'countries': [
                'SL'
            ]
        },
        'SOS': {
            'value': 'SOS',
            'name': 'SOS',
            'countries': [
                'SO'
            ]
        },
        'SRD': {
            'value': 'SRD',
            'name': 'SRD',
            'countries': [
                'SR'
            ]
        },
        'STD': {
            'value': 'STD',
            'name': 'STD',
            'countries': [
                'ST'
            ]
        },
        'SYP': {
            'value': 'SYP',
            'name': 'SYP',
            'countries': [
                'SY'
            ]
        },
        'SZL': {
            'value': 'SZL',
            'name': 'SZL',
            'countries': [
                'SZ'
            ]
        },
        'THB': {
            'value': 'THB',
            'name': 'THB',
            'countries': [
                'TH'
            ]
        },
        'TJS': {
            'value': 'TJS',
            'name': 'TJS',
            'countries': [
                'TJ'
            ]
        },
        'TMT': {
            'value': 'TMT',
            'name': 'TMT',
            'countries': [
                'TM'
            ]
        },
        'TND': {
            'value': 'TND',
            'name': 'TND',
            'countries': [
                'TN'
            ]
        },
        'TOP': {
            'value': 'TOP',
            'name': 'TOP',
            'countries': [
                'TO'
            ]
        },
        'TRY': {
            'value': 'TRY',
            'name': 'TRY',
            'countries': [
                'TR'
            ]
        },
        'TTD': {
            'value': 'TTD',
            'name': 'TTD',
            'countries': [
                'TT'
            ]
        },
        'TVD': {
            'value': 'TVD',
            'name': 'TVD',
            'countries': [
                'TV'
            ]
        },
        'TWD': {
            'value': 'TWD',
            'name': 'TWD',
            'countries': [
                'TW'
            ]
        },
        'TZS': {
            'value': 'TZS',
            'name': 'TZS',
            'countries': [
                'TZ'
            ]
        },
        'UAH': {
            'value': 'UAH',
            'name': 'UAH',
            'countries': [
                'UA'
            ]
        },
        'UGX': {
            'value': 'UGX',
            'name': 'UGX',
            'countries': [
                'UG'
            ]
        },
        'UYU': {
            'value': 'UYU',
            'name': 'UYU',
            'countries': [
                'UY'
            ]
        },
        'UZS': {
            'value': 'UZS',
            'name': 'UZS',
            'countries': [
                'UZ'
            ]
        },
        'VEF': {
            'value': 'VEF',
            'name': 'VEF',
            'countries': [
                'VE'
            ]
        },
        'VND': {
            'value': 'VND',
            'name': 'VND',
            'countries': [
                'VN'
            ]
        },
        'YER': {
            'value': 'YER',
            'name': 'YER',
            'countries': [
                'YE'
            ]
        },
        'ZAR': {
            'value': 'ZAR',
            'name': 'ZAR',
            'countries': [
                'ZA'
            ]
        },
        'ZMK': {
            'value': 'ZMK',
            'name': 'ZMK',
            'countries': [
                'ZM'
            ]
        },
        'ZWD': {
            'value': 'ZWD',
            'name': 'ZWD',
            'countries': [
                'ZW'
            ]
        }
    };
}]);
