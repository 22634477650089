'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .controller('EnterpriseCtrl', ['$scope', '$stateParams', 'mxAuthAcsSessionClient', '$m42AcsBackend', 'M42Validation', '$busy', 'M42AzureBlobPersistenceEngine', '$q', '$m42Clone', '$timeout', '$location', '$state',
        function ($scope, $stateParams, mxAuthAcsSessionClient, $m42AcsBackend, M42Validation, $busy, M42AzureBlobPersistenceEngine, $q, $m42Clone, $timeout, $location, $state) {

            $scope.errorMessage = null;
            $scope.errorClass = null;

            $scope.enterprise = {};
            $scope.logoPreview = null;
            $scope.isValid = true;

            $scope.baseUrl = 'enterprise';
            $scope.tabs = [
                {name: 'Enterprise.TabGeneral', urlSuffix: 'general', contentClass: 'enterpriseGeneral', visible: true},
                {name: 'Enterprise.TabUsers', urlSuffix: 'users', contentClass: 'enterpriseUsers', visible: true},
                {name: 'Enterprise.TabGroups', urlSuffix: 'groups', contentClass: 'enterpriseGroups', visible: true},
                {name: 'Enterprise.TabServiceConnections', urlSuffix: 'serviceconnections', contentClass: 'enterpriseServiceConnections', visible: true}
            ];

            $scope.navigateToTab = function (tab) {
                $state.go('main.enterprise', {'tab': tab.urlSuffix});
            };

            $scope.currentTab = $scope.tabs.find(function (element) {
                return element.urlSuffix === ($stateParams.tab || 'general');
            });

            // Check for valid tab.
            if (!$scope.currentTab || !$scope.currentTab.visible) {
                $state.go('main.enterprise');
            }


            // this function is called when ever a directive want's to show an error
            $scope.showAlert = function (errorCode, errorMessage) {
                if (errorCode === 0) {
                    $scope.errorMessage = errorMessage;
                    $scope.errorClass = 'alert-success';
                    $timeout(function () {
                        $scope.errorMessage = null;
                        $scope.errorClass = null;
                    }, 1500);
                } else {
                    $scope.errorMessage = errorMessage + ' (' + errorCode + ')';
                    $scope.errorClass = 'alert-danger';
                }
            };

            // calculates the upload uri
            $scope.logoUploadIntoBlob = function (fileObject) {

                var defer = $q.defer();

                // get the avatar uri target
                $m42AcsBackend.enterprise.avatarLocationW({id: $scope.enterprise.Id}).$promise.then(function (target) {

                    // upload the file obbject
                    M42AzureBlobPersistenceEngine.upload(fileObject, target.SasUrl).then(function () {
                        defer.resolve();
                    }).catch(function (reason) {
                        $scope.showAlert(500, 'Failed to upload image');
                        defer.reject(reason);
                    });
                });

                // return the promise
                return defer.promise;
            };

            // check is company specific data is valid to show or hide warning
            $scope.isCompanyValid = function () {
                return !$scope.isValid;
            };

            // load the enterprise profile when the controller is initialized
            $busy.during($m42AcsBackend.sessionEnterpriseProfile.get({}, function (enterpriseProfile) {

                // clone the loaded enterprise account profile into our scope (decoupled from angularjs)
                $scope.enterprise = $m42Clone.clone(enterpriseProfile);
                $scope.isValid = $scope.enterprise.IsValid;
                $scope.logoPreview = $scope.enterprise.LogoSasUrl;
            }));

        }])

    .directive('enterpriseTabContent', ['$compile', function ($compile) {
        return {
            restrict: 'A',
            scope: {
                contentClass: '=',
                enterprise: '=',
                error: '&'
            },
            link: function (scope, element) {
                scope.errorWrapper = function (code, message) {
                    scope.error({code: code, message: message});
                };

                scope.$watch('contentClass', function () {
                    angular.element(element).replaceWith($compile('<div class="' + scope.contentClass + '" data-enterprise="enterprise" data-error="errorWrapper(code, message)"></div>')(scope));
                });
            }
        };
    }]);
