'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:mxavatar
 * @description
 * # mxavatar
 */
angular.module('m42AccessControlServiceFrontendApp').directive('mxAvatar', function () {
    return {
        // template: '<div><img ng-show="avatarUri"ng-src="{{avatarUri}}" style="height: 100%; width: 100%"><div class="spinner" ng-show="!avatarUri" style="height: 100%; width: 100%"></div></div>',
        template: '<div style="position: relative; height: 100%; width: 100%"><img ng-show="avatarUri" ng-src="{{avatarUri}}" style="height: 100%; width: 100%"><div ng-show="!avatarUri" class="spinner"></div></div>',
        restrict: 'C',
        controller: 'mxAvatarCtrl',
        scope: {
            account: '='
        },
        link: function postLink(scope, element, attrs, controller) {

            // start loading the avatar
            controller.loadAvatar(element);
        }
    };
}).controller('mxAvatarCtrl', ['$scope', '$http', '$m42AcsBackend', function($scope, $http, $m42AcsBackend) {
    var self = this;
    var spinner = null;

    self.loadAvatar = function(element) {

        // configure our spinner
        var opts = {
            lines: 13, // The number of lines to draw
            length: 3, // The length of each line
            width: 3, // The line thickness
            radius: 8, // The radius of the inner circle
            corners: 1, // Corner roundness (0..1)
            rotate: 0, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: '#000', // #rgb or #rrggbb or array of colors
            speed: 1, // Rounds per second
            trail: 60, // Afterglow percentage
            shadow: false, // Whether to render a shadow
            hwaccel: false, // Whether to use hardware acceleration
            className: 'spinner', // The CSS class to assign to the spinner
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            top: '50%', // Top position relative to parent
            left: '50%' // Left position relative to parent
        };

        // apply the spinner (###TODO### We should generate a spinner directive for this instead doing this here)
        var target = $(element).find('.spinner')[0];
        spinner = new Spinner(opts).spin(target);

        // Now we just ask the backend for the avatar uri
        $m42AcsBackend.account.avatarLocationR({id: $scope.account.MailAddress}).$promise.then(function (result) {
            // and set them
            $scope.avatarUri = result.SasUrl;

            // stop the spinner
            spinner.stop();

        }).catch(function () {
            // do nothing just catch the exception
        });
    };

}]);
