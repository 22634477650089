'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:mxHeader
 * @description
 * # mxHeader
 */
angular.module('m42AccessControlServiceFrontendApp')
    .directive('mxHeader', function () {
        return {
            templateUrl: 'partials/directives/mxheader.html',
            controller: 'mxHeaderCtrl',
            restrict: 'C'
        };
    })
    .controller('mxHeaderCtrl', ['$scope', '$location', 'mxAuthAcsSessionClient', '$rootScope', 'M42BrowserCapabilities', '$state',
        function ($scope, $location, mxAuthAcsSessionClient, $rootScope, M42BrowserCapabilities, $state) {

            // Updates the menu entries dynamically based on the current rights
            // the user has
            $scope.menuEntries = [
                {
                    name: 'Header.SignOut',
                    targetState: '',
                    click: function ($event) {
                        $event.preventDefault();
                        mxAuthAcsSessionClient.destroySession();
                    },
                    visible: function () {
                        return !!mxAuthAcsSessionClient.getActiveSession();
                    }
                },
                {
                    name: 'Header.MyAccount',
                    targetState: 'main.account',
                    visible: function () {
                        return !!mxAuthAcsSessionClient.getActiveSession();
                    }
                },
                {
                    name: 'Header.Enterprise',
                    targetState: 'main.enterprise',
                    visible: function () {
                        var session = mxAuthAcsSessionClient.getActiveSession();
                        return !M42BrowserCapabilities.IsSmartphone && session && session.isEnterpriseManager();
                    }
                }
            ];

            $scope.navigateToMenuItem = function (item, event) {
                if (item.targetState) {
                    (function () {
                        var state = $state.get(item.targetState);
                        while (state.abstract) {
                            state = $state.get().find(function (s) {
                                return s.name.indexOf(state.name + '.') === 0 && s.url === '';
                            });
                        }
                        $state.go(state);
                    })();
                } else if (item.click) {
                    item.click(event);
                }
            };

            $scope.getLogoUri = function () {
                $state.go('main.account');
            };


        }]);
