'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:mxFormCombobox
 * @description
 * # mxFormCombobox
 */
angular.module('m42AccessControlServiceFrontendApp')
  .directive('mxFormCombobox', function () {
    return {
      template: '<div class="mx-form-combobox" ng-click="focusInput()">' +
        '  <input type="text" class="form-control" title="{{selectedItem}}" ng-model="selectedItem" ng-focus="onFocus()" ng-blur="onBlur()" ng-keydown="onKeyDown($event)">' +
        '  <button type="button" class="btn btn-default" tabindex="-1" ng-click="toggleDropdown()"><span class="caret"></span></button>' +
        '  <ul class="dropdown-menu" role="listbox" ng-show="isDropdownOpen">' +
        '    <li ng-repeat="item in filteredItems" ng-class="{ active: $index === activeIdxInDropdown }">' +
        '      <a href="#" ng-click="select($event, item)">{{item}}</a>' +
        '    </li>' +
        '  </ul>' +
        '</div>',
      controller: 'mxFormComboboxCtrl',
      restrict: 'A',
      scope: {
        items: '=',
        selectedItem: '='
      },
      link: function (scope, element) {
        // When anything is clicked, apply focus to input field
        var input = angular.element(element.context.getElementsByTagName('input')[0]);
        scope.focusInput = function() {
          input.focus();
        };
      }
    };
  })
  .controller('mxFormComboboxCtrl', ['$scope', '$timeout', function($scope, $timeout) {

    var hasFocus = false;
    var dropdownClosedByItemSelection = false;

    $scope.isDropdownOpen = false;
    $scope.activeIdxInDropdown = 0;
    $scope.filteredItems = [];

    $scope.$watch('selectedItem', function() {
      if (dropdownClosedByItemSelection) {
        dropdownClosedByItemSelection = false;
      } else {
        $scope.toggleDropdown(hasFocus);
      }

      // Search
      var filteredItems = [],
         query = $scope.selectedItem.toLowerCase();
      for (var i = 0; i < $scope.items.length; i++) {
        if ($scope.items[i].indexOf(query) !== -1) {
          filteredItems.push($scope.items[i]);
        }
      }

      $scope.filteredItems = filteredItems;
    });

    $scope.onFocus = function() {
      hasFocus = true;
    };

    $scope.onBlur = function() {
      hasFocus = false;

      // On click of an item in the dropdown, the input loses focus. If we would immediately hide the
      // dropdown, the click event on the item would never happen.
      $timeout(function() {
        if (!hasFocus) {
          $scope.isDropdownOpen = false;
        }
      }, 100);
    };

    $scope.onKeyDown = function($event) {
      var HOT_KEYS = [9, 13, 27, 38, 40];

      // An "interesting" key was pressed
      if (HOT_KEYS.indexOf($event.which) === -1) {
        return;
      }

      // Dropdown is closed and return/tab is pressed
      if (!$scope.isDropdownOpen && ($event.which === 13 || $event.which === 9)) {
        return;
      }

      $event.preventDefault();

      if ($event.which === 40) { // Arrow down
        $scope.activeIdxInDropdown = ($scope.activeIdxInDropdown + 1) % $scope.items.length;
      } else if ($event.which === 38) { // Arrow up
        $scope.activeIdxInDropdown = ($scope.activeIdxInDropdown > 0 ? $scope.activeIdxInDropdown : $scope.items.length) - 1;
      } else if ($event.which === 13 || $event.which === 9) { // Return, Tab
        $scope.select(null, $scope.filteredItems[$scope.activeIdxInDropdown]);
      } else if ($event.which === 27) { // Escape
        $scope.isDropdownOpen = false;
      }
    };

    $scope.select = function($event, item) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      dropdownClosedByItemSelection = true;

      $scope.selectedItem = item;
      $scope.isDropdownOpen = false;
    };

    $scope.toggleDropdown = function(visible) {
      if (typeof visible !== 'undefined') {
        $scope.isDropdownOpen = visible;
      } else {
        $scope.isDropdownOpen = !$scope.isDropdownOpen;
      }

      $scope.activeIdxInDropdown = 0;
    };

  }]);
