'use strict';

String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

// adds the startsWith
if (typeof String.prototype.startsWith !== 'function') {

    // see below for better implementation!
    String.prototype.startsWith = function (str) {
        return this.indexOf(str) === 0;
    };
}

function getOrigin(location) {

    if (location.origin && location.origin !== undefined) {
        return location.origin;
    } else {

        // get the correct protocol (default is http)
        var protocol = location.protocol === undefined ? 'http' : location.protocol;

        // generate the default port from the protocol
        var port = location.port === undefined ? null : location.port;

        // generate the uri
        if (port === null) {
            return protocol + '//' + location.hostname;
        } else {
            return protocol + '//' + location.hostname + ':' + port;
        }
    }
}

function getApplicationRoot(location) {

    // href - hash
    var hashIndex = location.href.indexOf('#'),
        appRoot = hashIndex === -1 ? location.href : location.href.substring(0, hashIndex);

    // remove the search if exists
    if (location.search !== undefined && location.search !== null) {
        appRoot = appRoot.replace(location.search, '');
    }

    // remove file if exists
    if (appRoot.endsWith('index.html')) {
        appRoot = appRoot.replace('index.html', '');
    }


    // done
    return appRoot;
}


if (!Array.prototype.find) {
    Array.prototype.find = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.find called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
}

if (!Array.prototype.findIndex) {
    Array.prototype.findIndex = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.find called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return i;
            }
        }
        return -1;
    };
}
