'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:mxformhelper
 * @description
 * # mxformhelper
 */
angular.module('m42AccessControlServiceFrontendApp')
/**
 * This directive is responsible to adjust the different form-Controls of
 * twitter bootstrap in the right way.
 */
    .directive('formControl', function () {
        return {
            restrict: 'C',
            /**
             *
             * @param scope
             * @param element
             * @param attrs
             */
            link: function postLink(scope, element) {
                if (element[0].nodeName !== 'SELECT') {
                    return;
                }

                element.change(function() {
                    if (element.val() === '') {
                        element.addClass('novalue');
                    } else {
                        element.removeClass('novalue');
                    }
                });
            }
        };
    });
