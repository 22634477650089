'use strict';

/*
 * This service implements the session behaviour of an application. A session is defined by having
 * a valid JWT token in the session storage of a browser. This JWT token could expire, when this
 * happens the session manager will force an action from the user. There are two options of expired
 * sessions:
 *
 * 1) The JWT token in cache is just expired during a page reload
 * 2) The user works with the app and the token expires
 *
 * The system implements the following solution
 *
 * 1) It's just redirects to the ACS
 *
 * 2) It calls a handler "onExpired" and let the application decided. The default handler is to
 *    redirect to the ACS
 *
 * The following handler are available:
 *
 * onUpdate: Called when the session was changed. This should be used in directive which are relying on
 *           the session state
 *
 * onExpired: Called when the token is expired. For this the system implements a background worker to
 *            check this from time to time.
 */
function M42AcsSdkAuthSessionClient(providerConfiguration, $q, mxAuthClient, jwtHelper, $window, $rootScope) {
    var self = this;

    var makeSessionAvailableDefaults = {};

    var activeSession = null;
    var activeTimeout = null;

    var establishCallbacks = [];
    var expirationCallbacks = [];

    var tokenStorageItemKey = 'mx.auth.session.token';

    function notifyEstablishCallbacks(currentSession) {
        establishCallbacks.forEach(function (cb) {
            cb(currentSession);
        });
    }

    function notifyExpirationCallbacks(currentSession, eventType) {
        expirationCallbacks.forEach(function (cb) {
            cb(currentSession, eventType);
        });
    }

    function createSessionFromToken(token) {
        // Store the token into the session storage of the browser
        activeSession = new SessionModel(token, jwtHelper);

        // store token if required
        if (providerConfiguration.persistent) {
            try {
                localStorage.setItem(tokenStorageItemKey, token);
            } catch (error) {
                console.warn('Could not store session. You will need to login again after reloading the page.', error);
            }
        }

        // establish the expiration timeout
        var expirationTimeout = (activeSession.claims.exp * 1000) - Date.now();
        activeTimeout = $window.setTimeout(function () {

            // cache the session
            var oldPrevActiveSession = activeSession;

            // reset the session
            activeSession = null;

            // remove the stored token
            localStorage.removeItem(tokenStorageItemKey);

            // call the listener
            notifyExpirationCallbacks(oldPrevActiveSession, self.eventSessionExpired);

            $rootScope.$evalAsync();

        }, expirationTimeout);
    }

    /*
     * This class implements the session object which allows some standard operations on the given
     * JWT token, e.g. checking if specific claims exists or the user is in a specific role
     */
    function SessionModel(sessionToken, jwtHelper) {
        var self = this;

        var isToken = typeof sessionToken === 'string';
        self.raw = sessionToken;

        /*
         * The verify operation returns true or false to indicate if the session
         * is expired or is just using a bad token.
         */
        self.verify = function () {
            return isToken && !jwtHelper.isTokenExpired(self.raw);
        };

        /*
         * This getter gives access to the claims encoded
         * in the token. The claims are returned as object
         */
        self.__defineGetter__("claims", function () {
            var claims = isToken && jwtHelper.decodeToken(self.raw) || {};
            if (typeof claims['role'] === 'undefined') {
                claims['role'] = [];
            } else if (Object.prototype.toString.call(claims['role']) !== '[object Array]') {
                claims['role'] = [claims['role']];
            }

            return claims;
        });

        /*
         * This getter returns all claim keys
         */
        self.__defineGetter__("claim_keys", function () {
            return Object.keys(self.claims);
        });
    }

    /*
     * Defines the possible events we can send to the expiration handlers
     */
    self.eventSessionExpired = 0;
    self.eventLogout = 1;

    /*
     * This method acts as a gatekeeper between the normal controller and the authorization process.
     * Every controller should call this function during load and should enclose all other logic in
     * them.
     *
     * The options parameter allows to add additional url parameters to the STS. It's possible to
     * override the standard redirect_uri parameter but no other default OAuth 2 parameters with this
     * options field.
     *
     * You can also specify an optional `token` property of the options parameter. If this contains
     * a valid raw JWT it will be used to initialize the session without any redirects.
     */
    self.makeSessionAvailable = function (options) {
        options = angular.extend({}, makeSessionAvailableDefaults, options);

        // generate the promise
        var defer = $q.defer();

        // Step 1: Check if we are returning from a pending authorisation workflow
        var requestCurrentlyFinishingPendingAuth = mxAuthClient.areReturningFromExternalAuthorization();
        if (requestCurrentlyFinishingPendingAuth) {

            // If so just finish the workflow and capture the token
            var token = mxAuthClient.finishPendingAuthorizeWorkflow();

            // Create a session and store it in activeSession variable.
			if (token) {
				createSessionFromToken(token);
			}
        }

        // Step 2: Check if there is a valid raw token passed into the method
        if (options.token) {
            createSessionFromToken(options.token);
            delete options.token;
        }

        // Step 3: Try to load from the persistent token from storage
        if (providerConfiguration.persistent && !activeSession) {
            var token = localStorage.getItem(tokenStorageItemKey);
            if (token) { createSessionFromToken(token); }
        }

        // Step 4: Start a new authorisation workflow, when the token is missing or invalid
        var tokenValid = activeSession !== null && activeSession !== undefined && activeSession.verify();
        if (!tokenValid) {
            // We end up with an authorisation error when this is the second phase
            // of finishing a auth request, if not we start the workflow
            if (requestCurrentlyFinishingPendingAuth) {
                defer.reject(new Error("Failed to finish authorisation"));
            } else {
                mxAuthClient.startAuthorizeWorkflow(options);
            }
        } else {
            // Notify callbacks.
            notifyEstablishCallbacks(activeSession);

            // We have a valid token now so just finish the whole process
            // with a session object
            defer.resolve(activeSession);
        }

        // return a promise
        return defer.promise;
    };

    /*
     * The destroy API ensures that the token becomes invalid and triggers also a session expired
     * notification so that the application can react on this.
     *
     * @param redirectUri - (Optional) When specified this will be used as a redirect url after signing
     *                      out. When not specified it will default to the current url.
     */
    self.destroySession = function (redirectUri) {

        // cancel the timeout
        $window.clearTimeout(activeTimeout);
        activeTimeout = null;

        // cache the session
        var oldPrevActiveSession = activeSession;

        // reset the session
        activeSession = null;

        // remove the stored token
        localStorage.removeItem(tokenStorageItemKey);

        // notify that the session expired
        notifyExpirationCallbacks(oldPrevActiveSession, self.eventLogout);

        // When we have a signout endpoint we just redirect away
        mxAuthClient.startLogoutWorkflow(redirectUri);
    };

    /*
     * Allows to set a callback to become notified when a new session has been established.
     */
    self.onEstablished = function (cb) {
        establishCallbacks.push(cb);
    };

    /*
     * Allows to set a callback to become notified when the session is expired.
     */
    self.onExpired = function (cb) {
        expirationCallbacks.push(cb);
    };

    /*
     * Returns the activeSession to prevent unneeded "makeSessionAvailable()"-calls when the Session is already available.
     */
    self.getActiveSession = function () {
        return activeSession;
    };

    /*
     * This function allows a delayed configuration of the whole authentication framework. It should be
     * used in the run function when the config function via provider is somehow not an option. This situation
     * happens in the Pandora shell.
     */
    self.configure = function (authconfiguration) {
        angular.extend(providerConfiguration, authconfiguration);
    };
}

angular.module('mx.auth').provider('mxAuthSessionClient', function () {
    var self = this;

    var providerConfigurationDefaults = { persistent: false };

    self.providerConfiguration = {};

    // give access to teh provider
    self.$get = ['$q', 'mxAuthClient', 'jwtHelper', '$window', '$rootScope', function ($q, mxAuthClient, jwtHelper, $window, $rootScope) {
        return new M42AcsSdkAuthSessionClient(self.providerConfiguration, $q, mxAuthClient, jwtHelper, $window, $rootScope);
    }];

    // setup the authorisation framework
    self.setup = function (authconfiguration) {
        authconfiguration = angular.extend({}, providerConfigurationDefaults, authconfiguration);
        self.providerConfiguration = authconfiguration;
    };
});
