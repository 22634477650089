'use strict';

function Matrix42Environment() {
    var self = this;

    // This service delivers from the browser address bar compatible to the different browser
    // behaviour of all know browser
    self.getOrigin = function() {

        // check if we have a real origin
        if (location.origin && location.origin !== undefined) {
            return location.origin;
        } else {

            // get the correct protocol (default is http)
            var protocol = location.protocol === undefined ? 'http' : location.protocol;

            // generate the default port from the protocol
            var port = location.port === undefined ? null : location.port;

            // generate the uri
            if (port === null) {
                return protocol + '//' + location.hostname;
            } else {
                return protocol + '//' + location.hostname + ':' + port;
            }
        }
    };

    self.getApplicationRoot = function() {

        // get all in front of hash bang
        var splittedRoot = location.href.split('#');

        // take the 0 index
        var approot = splittedRoot[0];

        // remove file if exists
        if (approot.endsWith('index.html'))
            approot = approot.replace('index.html', '');

        // done
        return approot;
    };

    self.isRunningOnLocalhost = function() {
        return ((self.getOrigin().indexOf("localhost") > -1) || (self.getOrigin().indexOf("127.0.0.1") > -1));
    }
}

/**
 * @ngdoc service
 * @name m42ApiBrowser2App.Matrix42.Environment
 * @description
 * # Matrix42.Environment
 * Service in the m42ApiBrowser2App.
 */
angular.module('Matrix42.Utils').provider('Matrix42.Utils.Environment', function environmentProvider() {
    var self = this;

    // returns the service
    self.$get = [function environmentFactory() {

        // let's assume that the UnicornLauncher constructor was also changed to
        // accept and use the useTinfoilShielding argument
        return new Matrix42Environment();
    }];

    // allows access to the utility functions from provider as well
    self.getOrigin = function() {
        var environment = new Matrix42Environment();
        return environment.getOrigin();
    };

    self.getApplicationRoot = function() {
        var environment = new Matrix42Environment();
        return environment.getApplicationRoot();
    };

    self.isRunningOnLocalhost = function() {
        var environment = new Matrix42Environment();
        return environment.isRunningOnLocalhost();
    }
});