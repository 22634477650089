'use strict';

/*
 * The auth client can be used to trigger a sign in process
 */
function M42AcsSdkAuthClient(providerConfiguration, $window, mxAuthLocation, mxAuthLocationTracker, $timeout) {
	var self = this,
		finishPendingAuthorizationWorkflowDefaults = {},
		startAuthorizeWorkflowDefaults = {};

	var redirectUriStorageItemKey = 'mx.auth.redirect.uri';

	/*
	 * This function checks the URL for a "#" just before the access token
	 * and sets the search parameter with it.
	 */
	function transformHashToSearchParameter() {
		if (mxAuthLocation.hash()) {
			mxAuthLocation.skipReload()
                .search(mxAuthLocation.hash())
                .hash('')
                .replace();

            // We use the replace state api because angular doesn't, otherwhise the hash with following rest stays in the URL
            // Remove fragment as much as it can go without adding an entry in browser history:
            if (window.location.href.indexOf('#') > -1 && typeof window.history.replaceState === 'function') {
                history.replaceState({}, '', window.location.href.substr(0, window.location.href.indexOf('#')));
            }
		}
	}

	/*
	 * This method kicks off the authorization workflow which ends up with the situation that
	 * the system is leaving the page.
	 *
	 * The options parameter allows to add additionla url parameters to the STS. It's possible to
	 * override the standard redirect_uri parameter but no other default OAuth 2 parameters with this
	 * options field.
	 */
	self.startAuthorizeWorkflow = function (options) {

		options = angular.extend({
			redirect_uri: mxAuthLocationTracker.getTargetLocation()
		}, startAuthorizeWorkflowDefaults, options);

		// When the redirect uri is fixed to a specific value because the used STS does not support deep
		// linking we cache the original target and use the fixed redirect uri.
		if (providerConfiguration.redirect_uri) {
			localStorage.setItem(redirectUriStorageItemKey, options["redirect_uri"]);
			options["redirect_uri"] = providerConfiguration.redirect_uri;
		}

		// generate the url
		var authLocation = providerConfiguration.endpoints.authorize +
			'?client_id=' + providerConfiguration.clientid +
			'&scope=' + providerConfiguration.scope +
			'&response_type=token';

		Object.keys(options)
			// Make sure to not override one of the default OAuth 2 parameters.
			.filter(function (parameterKey) {
				return ['client_id', 'scope', 'response_type'].indexOf(parameterKey.toLowerCase()) === -1;
			})
			// Add optional paramters.
			.forEach(function (parameterKey) {

				/*
				 Check if the redirect URI is set manually  in the provider Configuraton
				 If so, add this redirect uri to the Auth-URL instead of the normal one
				 */
				authLocation += '&' + parameterKey + '=' + encodeURIComponent(options[parameterKey]);
			});

		// leave this page
		$window.location = authLocation;
	};

	/*
	 * This page is finishing the authorization workflow which ends up with
	 * an extracted token form the URI
	 *
	 */
	self.finishPendingAuthorizeWorkflow = function (options) {
		options = angular.extend({}, finishPendingAuthorizationWorkflowDefaults, options);

		/*
		Check if we come from back from a successful authorization (with token)
		and have an additional Url-Path stored in the local storage
		If so, insert the additional path between the (if configured) redirect Uri and access token
		go to this new location
		 */
		var cachedRedirectUri = localStorage.getItem(redirectUriStorageItemKey);
		if (cachedRedirectUri) {
			var currentURL = window.location.href.toString();
			var redirectUriEndIndex = providerConfiguration.redirect_uri.length;
			var params = currentURL.slice(redirectUriEndIndex);

			// For the case when '/' in the end of url does matter.
			// It's need to redirect to exactly the same `currentURL` with or without `/` in the end.
			if (params.indexOf('/?')===0) {
				params = params.replace('/?', '?')
			};

			// for the case if `cachedRedirectUri` has its own GET params
			if (cachedRedirectUri.indexOf('?') !== -1) {
				// Check if `params` are GET-params or not (like: "/").
				// If not then set to "" not to break GET-params of `cachedRedirectUri`.
				params = params.indexOf('?') === 0 ? params.replace('?', '&') : '';
			}

			var newUrl = [cachedRedirectUri, params].join('');
			localStorage.removeItem(redirectUriStorageItemKey);

			// redirect away
			window.location = newUrl;

			// return nothing but we are on our way to visit the new url
			$timeout(function() {}, 60 * 60);
		}

		if (!self.areReturningFromExternalAuthorization()) {
			throw(new Error('M42ACSSdkNg: No authorization request is pending, aborting'))
		}

		// get the access token
		var accessToken = mxAuthLocation.$$search.access_token;

		// remove all the parameters from uri without reload
		mxAuthLocation.skipReload()
			.search('access_token', null)
			.search('token_type', null)
			.search('expires_in', null)
			.replace();

		// return the token
		return accessToken;
	};

	/*
	 * This function returns true when we are currently returning from
	 * an external authorization. This is indicated when ever we
	 * have an access_token in the URI
	 */
	self.areReturningFromExternalAuthorization = function () {

		transformHashToSearchParameter();


		//TODO kommen wir mit token ? dann ist redirect uri

		return (mxAuthLocation.$$search.access_token !== undefined && mxAuthLocation.$$search.access_token !== null);
	};

	/*
	 * This function starts the logout workflow whcih means the following. If the configuration contains
	 * a destroy endpoint the browser will be redirected to them. For this the system generates a
	 * query parameter "redirect_uri" based on the current location in the app.
	 *
	 * @param redirectUri - (Optional) When specified this will be used as a redirect url after signing
	 *                      out. When not specified it will default to the current url.
	 */
	self.startLogoutWorkflow = function (redirectUri) {
		// check if we need to do anything
		if (!providerConfiguration.endpoints.signout) {
			return;
		}

		// build the redirect_uri parameter
		if (!redirectUri) {
			redirectUri = mxAuthLocationTracker.getTargetLocation();
		}

		// leave the page

		$window.location = providerConfiguration.endpoints.signout 
            + (providerConfiguration.endpoints.signout.indexOf('?') === -1 ? '?' : '&') 
			+ 'redirect_uri=' + encodeURIComponent(redirectUri);
	};

	/*
	 * This function allows a delayed configuration of the whole authentication framework. It should be
	 * used in the run function when the config function via provider is somehow not an option. This situation
	 * happens in the Pandora shell.
	 */
	self.configure = function (configuration) {
		angular.extend(providerConfiguration, configuration);
	};
}

angular.module('mx.auth').provider('mxAuthClient', function () {
	var self = this,
		providerConfigurationDefaults = {endpoints: {authorize: null, signout: null}, redirect_uri: null};

	self.providerConfiguration = {};

	// Instantiate the service.
	self.$get = ['$window', 'mxAuthLocation', 'mxAuthLocationTracker', '$timeout', function ($window, mxAuthLocation, mxAuthLocationTracker, $timeout) {
		return new M42AcsSdkAuthClient(self.providerConfiguration, $window, mxAuthLocation, mxAuthLocationTracker, $timeout);
	}];

	// Setup the authorization client.
	self.setup = function (onfiguration) {
		self.providerConfiguration = angular.extend({}, providerConfigurationDefaults, onfiguration);
	};
});

// Make sure initial logic is executed.
angular.module('mx.auth').run(['mxAuthLocation', 'mxAuthLocationTracker', angular.noop]);
