'use strict';

/**
 * @ngdoc function
 * @name m42AccessControlServiceFrontendApp.controller:ErrorCtrl
 * @description
 * # ErrorCtrl
 * Controller of the m42AccessControlServiceFrontendApp
 */
angular.module('m42AccessControlServiceFrontendApp').controller('ErrorCtrl', ['$scope', 'M42ErrorService', '$stateParams', function ($scope, M42ErrorService, $stateParams) {
    var id = $stateParams.id;
    var category = $stateParams.category;

    if (id) {
        $scope.errorId = id;
    }

    if (category) {
        $scope.error = M42ErrorService.action.getByIdAndCategory({id: id, category: category}, function () {
        });
    } else {
        $scope.error = M42ErrorService.action.get({id: id}, function () {
        });
    }
}]);
