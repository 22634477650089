'use strict';

angular.module('m42AccessControlServiceFrontendApp')
  .controller('EnterpriseInviteUserCtrl', [ 'enterprise', 'enterpriseAccounts', '$scope', '$modalInstance', 'M42Validation', '$m42AcsBackend', '$m42AcsHost', '$busy', 'M42Translation', function(enterprise, enterpriseAccounts, $scope, $modalInstance, M42Validation, $m42AcsBackend, $m42AcsHost, $busy, M42Translation) {
    $scope.inviteByEmail = null;
    $scope.errorCode = -1;


    $scope.formValid = function () {
      var isValid = !M42Validation.isEmpty($scope.inviteByEmail);

      if (isValid) {
        for(var i = 0; i < enterpriseAccounts.length; i++) {
          if (enterpriseAccounts[i].MailAddress === $scope.inviteByEmail) {
            isValid = false;
            break;
          }
        }
      }

      return isValid;
    };

    /*
     * This method performs the invitation process and redirects the user back to the former page when the
     * process was successuflly. Otherwise it shows our error message.
     */
    $scope.ok = function() {

      // reset the busy message
      $busy.setMessage('');

      // start the invitation prcoess
      $busy.during($m42AcsBackend.enterprise.invite({id: enterprise.Id},
        { UserName: $scope.inviteByEmail,
          RedirectToSignUpUri: $m42AcsHost + '/my/#/register',
          RedirectToDefaultUri: $m42AcsHost + '/my/#/',
          Culture: M42Translation.getCurrentLanguage()
        }).$promise.then(function() {

          // no error, good
          $scope.errorCode = 200;
          $scope.errorMessage = '';

          // finished the process
          $modalInstance.close();

        }).catch(function(response) {
          $scope.errorCode = response.code;
          $scope.errorMessage = response.message;
        })
      )
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel")
    };

  }]);
