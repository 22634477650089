'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:mxerror
 * @description
 * # This directive is able to render standard error information. The directive has no isolated scope so just set the
 * scope variable errorCode and errorMessage in the parent scope and this directive will render it.
 */
angular.module('m42AccessControlServiceFrontendApp').directive('mxError', function () {
    return {
        templateUrl: 'partials/directives/mxerror.html',
        restrict: 'C',
        controller: 'mxErrorCtrl',
        scope: {
          errorCode: '=',
          errorMessage: '='
        }
    };
 }).controller('mxErrorCtrl', ['$scope', 'M42Validation', function($scope, M42Validation) {
    /*
     * This method is able to check if a give error code is a success code
     * or not.
     */
    $scope.isSuccessCode = function(errorCodeValue) {

        // check the http success codes
        if (errorCodeValue >= 200 && errorCodeValue < 300) {
            return true;
        }

        // check the default success code
        if (errorCodeValue === -1) {
            return true;
        }

        // otherwise all bade
        return false;
    };

    // just checks if the value is empty or not
    $scope.isEmpty = function(val) {
        return M42Validation.isEmpty(val);
    };
 }]);
