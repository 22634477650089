"use strict";

 angular.module('config', [])

.constant('$globalConfiguration', {
    name: 'distribution',
    apiEndpoint: '',
    apiOAuthAuthorizePath: '/issue/oauth2/authorize',
    apiOAuthSignOutPath: '/issue/oauth2/signout',
    apiOAuthClientID: 'accountsmatrix42com',
    apiOAuthScope: 'urn:matrix42com',
    deploymentUUID: 'bf4fa11d-3d5a-4445-a238-910c2043419a',
    redirectSavedUrls: ['lang']
})

;