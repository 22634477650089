'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:imageDragDrop
 * @description
 * # imageDragDrop
 */
angular.module('m42AccessControlServiceFrontendApp').directive('imageDragDrop', function () {
    return {
        template: '<div class="avatar-dnd-area" style="background-image: none;"><div style="border-width: 0px; text-align: center; overflow: hidden; width: 100%; height: 100%; position: relative;"><div style="position: absolute; height: 100%; width: 100%;"><img style="height: 100%; width: 100%;" class="autoCropAndResize" ng-src="{{ image }}" /></div><input type="file" size="1" ng-class="{{dragFieldCss}}" style="-moz-opacity: 0; filter: alpha(opacity=0); opacity: 0; height: 100%; width: 100%; z-index: 1000;"></div></div>',
        controller: 'imageDragDropCtrl',
        restrict: 'C',
        scope: {
            image: '=',
            uploadImage: '&'
        },
        link: function postLink(scope, element, attrs, controller) {
            controller.init(element);
        }
    };
}).controller('imageDragDropCtrl', ['$scope', '$q', '$busy', function($scope, $q, $busy) {
    var self = this;

    var domElement = null;
    var dropFileContainer = null;

    var compatibility = {
        isFileReaderSupported: false,
        isDnDSupported: false
    };

    self.init = function(element) {

        // cache the dom element
        domElement = element;

        // get the dropfile container
        dropFileContainer = domElement.find('input[type=file]')[0];

        // check how compatible we are
        compatibility.isFileReaderSupported = typeof (window.FileReader) !== 'undefined';
        compatibility.isDnDSupported = 'draggable' in document.createElement('span');

        // change the ui in behalf of the compatibility
        if (!compatibility.isFileReaderSupported) {
            dropFileContainer.innerHTML = 'No file upload supported';
        } else {
            dropFileContainer.innerHTML = 'Drag your file here';
        }

        // set the default style
        setStyleDefault();

        // register the callbacks
        if (compatibility.isDnDSupported) {
            dropFileContainer.addEventListener('change', self.handleDrop, false);
            dropFileContainer.addEventListener('dragover', self.handleDragOver, false);
            dropFileContainer.addEventListener('dragleave', self.handleDragLeave, false);
        }
    };

    $scope.dragFieldCss = 'default';

    // Sets our default UI style
    var setStyleDefault = function () {
        $scope.dragFieldCss = 'default';
    };

    // Sets our drag over style
    var setStyleDragOver = function () {
        $scope.dragFieldCss = 'dragOver';
    };

    self.handleDragOver = function() {
        setStyleDragOver();
    };

    self.handleDragLeave = function() {
        setStyleDefault();
    };

    self.handleDrop = function(event) {

        // set the busy indicator
        $busy.setMessage('');
        $busy.beBusy();

        // load the file into browser
        loadFileIntoBrowser(event).then(function(url) {

            // assign to image
            $scope.image = url;

            // get the file object
            var fileObject = getFileObject(event);

            // upload the image
            $scope.uploadImage({fileObject: fileObject}).then(function(){
                // we are done
                $busy.beFree();
            }).catch(function() {
                // we are also done
                $busy.beFree();
            });
        });
    };

    var loadFileIntoBrowser = function(event) {
        var defer = $q.defer();

        // It depends on the browser where the file is cached
        var cachedFile = getFileObject(event);

        // check if we got a preview file
        if (!cachedFile) {
            defer.reject();
            return;
        }

        // just read the file with the file reader
        var oFReader = new FileReader();
        oFReader.readAsDataURL(cachedFile);

        // when we are done assign the file to our
        // image in the scope
        oFReader.onload = function (oFREvent) {
            defer.resolve(oFREvent.target.result);
        };

        return defer.promise;
    };

    var getFileObject = function(event) {
        var cachedFile = null;

        if (typeof (event.dataTransfer) !== 'undefined') {
            cachedFile = event.dataTransfer.files[0];
        } else if (typeof (dropFileContainer.files) !== 'undefined') {
            cachedFile = dropFileContainer.files[0];
        }

        return cachedFile;
    };
}]);
