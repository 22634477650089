'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .controller('EnterpriseServiceConnectionDeleteCtrl', ['enterprise', 'clientId', '$scope', '$modalInstance',
        function (enterprise, clientId, $scope, $modalInstance) {

            $scope.enterprise = enterprise;
            $scope.clientId = clientId;

            $scope.ok = function () {
                $modalInstance.close();
            };

            $scope.cancel = function () {
                $modalInstance.dismiss();
            };

        }]);
