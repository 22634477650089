'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .controller('SplashCtrl', ['$scope', '$location', 'mxAuthAcsSessionClient', '$rootScope', 'M42BrowserCapabilities', '$state', '$stateParams',
        function ($scope, $location, mxAuthAcsSessionClient, $rootScope, M42BrowserCapabilities, $state, $stateParams) {

            var restPasswordToken = $location.$$search.resettoken;
            var restPasswordAccount = $location.$$search.resetaccount;

            if (restPasswordToken !== undefined) {
                delete $location.$$search.resettoken;
                delete $location.$$search.resetaccount;
                $location.path('/resetpassword/' + restPasswordAccount + '/' + restPasswordToken);
            } else {
                $location.path('/account');
            }
        }]);

