'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .directive('myaccountExport', function () {
        return {
            templateUrl: 'partials/directives/myaccount_export.html',
            controller: 'myaccountExportCtrl',
            restrict: 'E',
            scope: {}
        };
    })
    .controller('myaccountExportCtrl', ['$scope', '$m42AcsBackend', 'mxAuthAcsSessionClient', '$m42CsvFileDownload',
        function ($scope, $m42AcsBackend, mxAuthAcsSessionClient, $m42CsvFileDownload) {

            var session = mxAuthAcsSessionClient.getActiveSession();
            $scope.isExportAllowed = session && session.hasRole('Matrix42.Accounts.DataManager');

            $scope.exportDate = null;
            $scope.exportDatePickerOpened = false;

            function getExportDateAsISO() {
                if ($scope.exportDate) {
                    return $scope.exportDate.toISOString();
                } else {
                    return null;
                }
            }

            $scope.openExportDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.exportDatePickerOpened = !$scope.exportDatePickerOpened;
            };

            $scope.exportAccounts = function () {
                $m42AcsBackend.export.accounts({createdAfter: getExportDateAsISO()}).$promise.then(function (data) {
                    $m42CsvFileDownload.downloadCsv(data.data, 'accounts.csv');
                });
            };

            $scope.exportEnterprises = function () {
                $m42AcsBackend.export.enterprises({createdAfter: getExportDateAsISO()}).$promise.then(function (data) {
                    $m42CsvFileDownload.downloadCsv(data.data, 'enterprises.csv');
                });
            };

        }
    ]);
