'use strict';

/**
 * @ngdoc service
 * @name m42AccessControlServiceFrontendApp.m42BrowserCapabilities
 * @description
 * # m42BrowserCapabilities
 * Service in the m42AccessControlServiceFrontendApp.
 */
angular.module('m42AccessControlServiceFrontendApp').service('M42BrowserCapabilities', [ function m42BrowserCapabilities() {
    var self = this;

    self.SupportsFileReader     = false;
    self.SupportsDragAndDrop    = false;
    self.IsMobile               = false;
    self.IsSmartphone           = false;
    self.IsTablet               = false;

    self.updateCapabilities = function() {
        self.SupportsFileReader = (typeof (window.FileReader) !== undefined);
        self.SupportsDragAndDrop = ('draggable' in document.createElement('span'));

        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            self.IsMobile = true;
        }

        if( /iPhone|iPod/i.test(navigator.userAgent) ) {
            self.IsSmartphone = true;
        }

        if (self.IsMobile) {
            self.IsTablet = !self.IsSmartphone;
        }

    };

    // update our values
    self.updateCapabilities();
}]);
