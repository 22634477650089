'use strict';
angular.module('m42AccessControlServiceFrontendApp')

    .provider('$m42UrlParam', ['$stateProvider', function ($stateProvider) {
        var self = this;

        self.setup = function (paramNames) {
            var existingDecorator = $stateProvider.decorator('url');
            $stateProvider.decorator('url', function (state, parent) {
                var urlMatcher = existingDecorator(state, parent);

                for (var i = 0; i < paramNames.length; i++) {
                    var paramName = paramNames[i];
                    if (urlMatcher && !urlMatcher.parameters(paramName) && typeof state.self.url !== 'undefined') {
                        var urlSpliter = "?";
                        if (state.self.url.indexOf('?') > -1) {
                            urlSpliter = '&';
                        }
                        state.self.url += urlSpliter + paramName;
                        urlMatcher = existingDecorator(state, parent);
                    }
                }
                return urlMatcher;
            });
        };

        self.$get = function () {
        };
    }])
;
